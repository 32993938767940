import { Math as ThreeMatch, MeshBasicMaterial, Vector3, TextureLoader, RepeatWrapping } from "three";
import { DATA_OBJECT_SCENE } from "../../../const";
import { sercheAllObjectByProperty } from "../../../utils";

class Object3D {
  constructor() {
    this.obj = null;
    this.material = null;
  }

  setTexture = (texture) => {
    let map = new TextureLoader().load(texture);
    map.wrapS = RepeatWrapping;
    map.wrapT = RepeatWrapping;
    map.repeat.set(1, 1);
    var material = new MeshBasicMaterial({
      map: map,
      transparent: true
      // color:"#000"
    });
    material.needsUpdate = true;
    this.obj.material = material
  }

  clearTransformControl = (scene) => {
    let allTransformControl = sercheAllObjectByProperty("name", DATA_OBJECT_SCENE.transformControler.name, scene)
    allTransformControl.forEach((el) => {
      el.detach()
      scene.remove(el)
    })
  }

  removeObject = (parent, scene) => {
    if (this.obj) {
      if (!scene && this.obj.parent) scene = this.obj.parent
      this.clearTransformControl(scene)
      let { material, geometry } = this.obj;
      if (material && geometry) {
        material.dispose();
        geometry.dispose();
      }
      if (parent) {
        parent.remove(this.obj);
      } else {
        if (this.obj.parent)
          this.obj.parent.remove(this.obj);
      }
    }
  };

  getObject = () => {
    if (!this.obj) return false;
    return this.obj;
  };

  addToScene = (scene) => {
    // console.log(this.obj)
    scene.add(this.obj);
  };

  setColor = (cssColor = "#ffffff") => {
    if (!this.material) return null;
    this.material.color.set(cssColor);
  };

  setPosition = (position) => {
    if (!this.obj || !this.obj.position || !position) return;
    this.obj.position.set(...position);
  };

  setRotation = (rotation) => {
    if (!this.obj || !this.obj.rotation || !rotation) return;
    const degreesRotations = [
      ThreeMatch.degToRad(rotation[0]),
      ThreeMatch.degToRad(rotation[1]),
      ThreeMatch.degToRad(rotation[2]),
    ];
    this.obj.rotation.set(...rotation);
  };

  setQuaternion = (quaternion) => {
    if (!this.obj || !this.obj.quaternion || !quaternion) return;

    this.obj.children[0].quaternion.setFromAxisAngle(new Vector3(quaternion[0], quaternion[1], quaternion[2]).normalize(), quaternion[3]);

  };

  setScale = (scale) => {
    if (!this.obj || !this.obj.scale || !scale) return;
    this.obj.scale.set(...scale);
  };
}

export default Object3D;
