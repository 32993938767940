import React from "react";
import SubmitForm from "../SubmitForm";
import isEqual from "react-fast-compare";
import InputValueData from "../../Views/Layout/InputValueData";
import {Col, Form} from "react-bootstrap";
import RadioButton from "../../Views/Layout/RadioButton";
import CheckBox from "../../Views/Layout/CheckBox";
import ConfirmComponent from "../ConfirmComponent";
import connect from "storeon/react/connect";


class OptionPFMLDropDown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      axleLength: 5.00000e-006,
      Xmin: 5.00000e-006,
      Xmax: 5.00000e-006,
      Ymin: 5.00000e-006,
      Ymax: 5.00000e-006,
      magneticField: true,
      electricField: false,
      thermalField: false,
    }
  }

  render() {
    const theme = this.props.ProjectTheme === 'light' ? 'light' : 'dark'
    const {
      magneticField,
      electricField,
      thermalField,
      Xmin,
      Xmax,
      Ymin,
      Ymax,
      axleLength,
    } = this.state
    const {close} = this.props
    return (
      <div className={`pfml-drop_down_wrap_option-wrap  ${theme}`}>
        <div>
          <span className={"pfml-drop_down_wrap_option-header_text"}>
            Параметры устройства
        </span>
          <div
            className={"pfml-drop_down_wrap_option-closed"}
            onClick={close}>
          </div>
        </div>
        <div className={"pfml-drop_down_wrap_option-type_task"}>
          <div className={"pfml-drop_down_wrap_option-title-type_task"}>
            Тип задачи
          </div>
          <div
            className={"pfml-drop_down_wrap_option-type_task_control_value"}
          >
            <Form.Group
              as={Col}
              className={"pfml-drop_down_wrap_option-form_group_input_value"}
            >
              <Form.Label
                className={"pfml-drop_down_wrap_option-label_control_input_value"}
              >
                <RadioButton
                  checked={magneticField}
                  onClick={(e) => {
                    this.setState({
                      magneticField: true,
                      electricField: false,
                      thermalField: false
                    })
                  }}
                />
                1 - магнитное поле
              </Form.Label>
              <Form.Label
                className={"pfml-drop_down_wrap_option-label_control_input_value"}
              >
                <RadioButton
                  checked={electricField}
                  onClick={(e) => {
                    this.setState({
                      magneticField: false,
                      electricField: true,
                      thermalField: false
                    })
                  }}
                />
                2 - электрическое поле
              </Form.Label>
              <Form.Label
                className={"pfml-drop_down_wrap_option-label_control_input_value"}
              >
                <RadioButton
                  checked={thermalField}
                  onClick={(e) => {
                    this.setState({
                      magneticField: false,
                      electricField: false,
                      thermalField: true
                    })
                  }}
                />
                3 - тепловое поле
              </Form.Label>
            </Form.Group>
          </div>
        </div>
        <div className={"pfml-drop_down_wrap_option-control_input"}>
          <InputValueData
            firstDescription='Длина устройства по оси Z (m)'
            onChange={(e) => {
              this.setState({
                axleLength: e.target.value
              })
            }}
            value={axleLength}
          />
        </div>
        <div className={"pfml-drop_down_wrap_option-wrap-type_task"}>
          <div className={"pfml-drop_down_wrap_option-title-type_task"}>
            Координаты окна
          </div>
          <div className={"pfml-drop_down_wrap_option-type_task_control"}>
            <InputValueData
              firstDescription='Xmin (m)'
              onChange={(e) => {
                this.setState({
                  Xmin: e.target.value
                })
              }}
              value={Xmin}
            />
            <InputValueData
              firstDescription='Xmax (m)'
              onChange={(e) => {
                this.setState({
                  Xmax: e.target.value
                })
              }}
              value={Xmax}
            />
            <InputValueData
              firstDescription='Ymin (m)'
              onChange={(e) => {
                this.setState({
                  Ymin: e.target.value
                })
              }}
              value={Ymin}
            />
            <InputValueData
              firstDescription='Ymax (m)'
              onChange={(e) => {
                this.setState({
                  Ymax: e.target.value
                })
              }}
              value={Ymax}
            />
          </div>
          <div className={"pfml-drop_down_wrap_option-wrap_confirm"}>
            <ConfirmComponent callbackCancellation={close} />
          </div>
        </div>
      </div>
    )
  }
}

export default connect('ProjectTheme', OptionPFMLDropDown);
