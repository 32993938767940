export const SCENE_NAME = 'GEngine';

/*
  данные об объектах сцены (названия типы и т.д)
 */
export const DATA_OBJECT_SCENE = {
  transformControler: {
    name: 'Tranform Controlelr'
  },
  dragControls: {
    name: 'Drag Controlelr'
  },
  MAIN_CAMERA: {
    name: "MAIN CAMERA"
  },
  GVRNaviControls: {
      menu:{
        name: 'MenuRing'
      },
    sphereControls: {
        name: 'SphereControls'
    },
    ringControlGo: {
        name: 'ringControlGo'
    },
    buttonController: {
      name: 'buttonController'
    }
  }
}

export const DEFAULT_MATERIAL = {
  name: '',
  type: '',
  opacity: 0,
  transparent: false,
  blendSrc: 0,
  blendDst: 0,
  blendEquation: 0,
  blendSrcAlpha: null,
  blendDstAlpha: null,
  blendEquationAlpha: null,
  depthFunc: 0,
  depthTest: false,
  depthWrite: false,
  stencilWriteMask: 0,
  stencilFunc: 0,
  stencilRef: 0,
  stencilFuncMask: 0,
  stencilFail: 0,
  stencilZFail: 0,
  stencilZPass: 0,
  stencilWrite: false,
  clippingPlanes: null,
  clipIntersection: false,
  clipShadows: false,
  shadowSide: null,
  colorWrite: false,
  precision: null,
  polygonOffset: false,
  polygonOffsetFactor: 0,
  polygonOffsetUnits: 0,
  dithering: false,
  alphaTest: 0,
  premultipliedAlpha: false,
  visible: false,
  toneMapped: false,
  userData: {},
  version: 0,
  color: { r: 0, g: 0, b: 0 },
  map: null,
  lightMap: null,
  lightMapIntensity: 0,
  aoMap: null,
  aoMapIntensity: 1,
  emissive: { r: 0, g: 0, b: 0 },
  emissiveIntensity: 0,
  emissiveMap: null,
  specularMap: null,
  alphaMap: null,
  envMap: null,
  combine: 0,
  reflectivity: 0,
  refractionRatio: 0,
  wireframe: false,
  wireframeLinewidth: 0,
  wireframeLinecap: 'round',
  wireframeLinejoin: 'round',
  // skinning: false,
  // morphTargets: false,
  // morphNormals: false,
}