import React from 'react'
import connect from 'storeon/react/connect'



class DefaultDataSidebar extends React.Component {
  constructor(props) {
    super()
    this.state = {
      allDots: 0,
      primitive: 0,
      env: 0,
      nodes: 0,
      elements: 0
    }
  }


  componentDidMount() {

  }

  render() {
    const {
      allDots,
      primitive,
      env,
      nodes,
      elements
    } = this.state
    return (
      <React.Fragment>
        <span className={"pfml-sidebar-device_title"}>ИССЛЕДОВАТЕЛЬСКАЯ СРЕДА</span>
        <div className={"pfml-sidebar-researche_env"}>
          <ul>
            <li>N точек: {allDots}</li>
            <li>N примитивов: {primitive}</li>
            <li>N подобластей: {env}</li>
            <li>N узлов: {nodes}</li>
            <li>N элементов: {elements}</li>
          </ul>
        </div>

      </React.Fragment>
    )
  }
}

export default connect("DataCountAllPrimitiveScenePFML",DefaultDataSidebar)