import React, { createRef } from "react";
import TableGenerate from "../../common/TableGenerate";
import ButtonMain from "../../Views/Layout/ButtonMain";
import ContextMenuProjectDetailsMenu from "../../common/ContextMenuProjectDetailsMenu";
import LoaderSpiner from "../../common/LoaderSpiner";
import Api from '../../api'
import { PATH } from '../../const'




const ApiResearch = Api.ResearchApi
class TableProjectDetailsPFML extends React.Component {
  constructor(props) {
    super(props);
    this.targetRef = React.createRef();
    this.state = {
      TableHeadData: [["Имя", "", "Дата создания", "Размер", "Вид"]],
      TableBodyData: null,
      boolShowContextMenu: false,
      target: null,
      dataProject: null,
      changeTitle: false,
    };
  }

  fadeOutContextMenu = (e) => {
    this.setState({
      showContextMenu: false,
    });
  };


  getClassNameForIconFile = (element) => {
    if (element.type === "calculations") return "calculations";
    if (element.type === "pole") return "pole";
    if (element.type === "folder") return "folder";
  };

  changeTitleResearch = (e) => {
    e.persist()
    let target = e.target
    if (target) {
      let params = {
        title: target.value.length ? target.value : target.defaultValue
      }
      ApiResearch.patchResearch(target.id, params).then((res) => {
     
        if (!target.value.length)
          target.value = target.defaultValue
      })
    }
  }

  blurChangeTitleInput = (e) => {
    e.persist()
    this.setState({
      changeTitle: false
    }, () => {
      this.changeTitleResearch(e)
    })
  }

  setChangeTitle = (id) => {
    const { refsTextArea } = this.state
    let textArea = null
    refsTextArea.map((el, i) => {
      if (el.id === id) {
        textArea = el
      }
    })
    this.setState({
      changeTitle: true,
      showContextMenu: false
    }, () => textArea.focus())
  }

  doubleClickEvent = (id) => {
    window.location.href = `${PATH.PhysicalFieldModelingLibrary}${id}`
  }

  serializedProjectDataToTable = (data) => {
    if (!data) return;
    let { boolShowContextMenu, changeTitle } = this.state;
    let arr = [
      [
        {
          tdParams: { colSpan: "5", className: "add_folder" },
          content: (
            <div className={`file_and_folders-name create_folder`}>
              <div className={"file_and_folders-name-text_create_folder"}>Создать папку</div>
            </div>
          ),
        },
      ],
    ];
    let refsTextArea = []
    data.map((el, i) => {
      let name = {
        tdParams: { className: "tb" },
        content: (
          <div
            className={`file_and_folders-name calculations`}
            onDoubleClick={(e) => {
              this.doubleClickEvent(el.hash_id)
            }}
          >
            <textarea
              id={el.hash_id}
              readOnly={!changeTitle}
              ref={(ref) => refsTextArea.push(ref)}
              defaultValue={el.title}
              className={`file_and_folders-name-textarea  ${changeTitle ? "active" : ""}`}
              onBlur={this.blurChangeTitleInput}
            />
          </div>
        ),
      };
      let context = {
        tdParams: {},
        content: (
          <div id={el.id} ref={this.targetRef}>
            <ButtonMain
              className={"file_and_folders-context_menu"}
              //onBlur={fadeOutContextMenu}
              onClick={(e) => {
                boolShowContextMenu = !boolShowContextMenu;
                this.setState({
                  target: e.target,
                  dataProjectElement: el,
                  showContextMenu: boolShowContextMenu,
                });
              }}
            ></ButtonMain>
          </div>
        ),
      };
      let date = {
        tdParams: {},
        content: <div className={"folder_list-date"}>{el.date}</div>,
      };
      let size = {
        tdParams: {},
        content: <div className={"folder_list-size"}>{el.size}</div>,
      };
      let typeView = {
        tdParams: {},
        content: <div className={"folder_list-type"}>{el.typeView}</div>,
      };
      arr.push([name, context, date, size, typeView]);
    });

    this.setState({
      refsTextArea: refsTextArea
    });
    return arr
  };

  createDataModal = (projectData) => {
    this.setState({
      TableBodyData: this.serializedProjectDataToTable(projectData)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.projectData !== this.props.projectData) {
      this.createDataModal(this.props.projectData)
    }
    if (prevState.changeTitle !== this.state.changeTitle) {
      this.createDataModal(this.props.projectData)
    }
  }

  componentDidMount() {
    let { projectData } = this.props;
    if (projectData) {
      return this.createDataModal(projectData)
    }
  }
  render() {
    const {
      TableHeadData,
      TableBodyData,
      dataProjectElement,
      target,
      showContextMenu,
    } = this.state;
    const { projectData } = this.props
    return (
      <>
        <ContextMenuProjectDetailsMenu
          dataProject={dataProjectElement}
          updateListResearch={this.props.updateListResearch}
          target={{ current: target }}
          fadeOutContextMenu={this.fadeOutContextMenu}
          showContextMenu={showContextMenu}
          setChangeTitle={this.setChangeTitle}
        />
        {projectData ? <TableGenerate
          variant={"project_details"}
          TableHeadData={TableHeadData}
          TableBodyData={TableBodyData}
        /> : <LoaderSpiner />}
      </>
    );
  }
}
export default TableProjectDetailsPFML;
