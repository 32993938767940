import React from 'react'
import { Route, Switch } from "react-router-dom";
import {
	MainPage,
	HomePage,
	FaqPage,
	DetailsNewsPage,
	ForgotPasswordPage,
	RegistrationPage,
	AuthorizationPage,
	Error500Page,
	Error404Page
} from '../../pages'
import { PATH } from '../../const'


class OuterPageRoutes extends React.Component {
	constructor(props) {
		super()
	}

	render() {
		return (
			<Switch>
				<Route path={PATH.MainPage} exact component={MainPage} />
				<Route path={`${PATH.Home}`} exact component={HomePage} />
				<Route path={PATH.Faq} exact component={FaqPage} />
				<Route
					path={`${PATH.NewsDetailsInHomePage}:slag`}
					exact
					component={DetailsNewsPage}
				/>
				<Route path={PATH.Registration} exact component={RegistrationPage} />
				<Route path={PATH.Authorization} exact component={AuthorizationPage} />
				<Route
					path={PATH.ForgotPassword}
					exact
					component={ForgotPasswordPage}
				/>
				<Route path={PATH.ERROR500} component={Error500Page} />
				<Route path={"*"} component={Error404Page} />
			</Switch>
		)
	}
}

export default OuterPageRoutes