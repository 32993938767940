import React, { Component } from "react";
import { Container, Row, Col, Nav, Button } from "react-bootstrap";
import QuestionHelpers from "../../../common/QuestionHelpers";
import HelloHeadLine from '../HelloHeadLine'
import ModalPersonalPage from '../../../common/ModalPersonalPage'
import InputForm from '../InputForm'
import ButtonMain from '../ButtonMain'
import { NavLink } from "react-router-dom";
import { PATH } from "../../../const";
import connect from 'storeon/react/connect'
import Api from '../../../api'

const ApiProject = Api.ProjectApi
const ApiUser = Api.UserApi
class PersonalPageLayout extends Component {
  constructor(props) {
    super()
    this.state = {
      updatecomponent: false,
      contentModal: null,
      isShowModal: false,
      dropSidebar: false,
      formData: {
        name: null
      }
    }
  }

  dropSidebarInPersonalPage = (toggle) => {
    this.setState({ dropSidebar: !toggle })
  }

  submitFormAddProject = (e) => {
    e.preventDefault()
    e.persist()
    const { name } = this.state.formData
    ApiProject.createProject({ title: name }).then((res) => {
      this.setState({
        updatecomponent: true
      })
      this.closeModal()
    })
  }

  stopUpdate = () => {
    this.setState({
      updatecomponent: false
    })
  }

  changeProjectName = (value) => {
    const { formData } = this.state
    this.setState({
      formData: {
        ...formData,
        name: value
      }
    })
  }

  createContentModalAddProject = () => {
    return (
      <div className={"add_project-wrap"}>
        <h3 className={"add_project-wrap-title"}>Новый проект</h3>
        <form onSubmit={this.submitFormAddProject} className={"add_project-wrap-form"}>
          <InputForm name={"name"} onChange={(ev) => this.changeProjectName(ev.target.value)} placeholder={"Название проекта"} />
          <ButtonMain type={"submit"} className={"add_project-wrap-form-btn"}>Создать</ButtonMain>
        </form>
      </div>
    )
  }

  closeModal = () => {
    if (this.props.closed) {
      this.props.closed()
    }
    this.setState({
      isShowModal: false,
      contentModal: null
    })
  }

  addProjectOpenModal = () => {
    if (this.state.dropSidebar) {
      this.setState({
        dropSidebar: false,
        isShowModal: true,
        contentModal: this.createContentModalAddProject()
      })
    } else {
      this.setState({
        isShowModal: true,
        contentModal: this.createContentModalAddProject()
      })
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isShowModal !== this.state.isShowModal) {
      if (this.props.isShowModal !== prevProps.isShowModal)
        this.setState({
          isShowModal: this.props.isShowModal,
          contentModal: this.props.content
        })
    }
  }

  componentDidMount() {
    ApiUser.getUserData().then((res) => {
      this.props.dispatch("UserData", {
        "email": res.email,
        "username": res.username,
        "first_name": res.first_name,
        "last_name": res.last_name,
        "patronymic": res.patronymic,
        "phone": res.phone
      })
    }).catch((err) => {

    })

  }


  render() {
    const { contentModal, isShowModal, updatecomponent } = this.state
    let theme = this.props.ProjectTheme === 'light' ? 'light' : 'dark'
    console.log('UserData', this.props.UserData)
    return (
      <React.Fragment>
        <ModalPersonalPage className={"custom_size-for-modal_perosnal_page"} show={isShowModal} content={contentModal} close={this.closeModal} />
        <div className={`personal_page`} >
          <div
            onClick={() => this.dropSidebarInPersonalPage(this.state.dropSidebar)}
            className={`personal_page_drop-sidebar ${this.state.dropSidebar ? '' : 'active'}`}>
          </div>
          <div
            onClick={() => this.dropSidebarInPersonalPage(this.state.dropSidebar)}
            className={`wrapper-sidebar-drop ${this.state.dropSidebar ? 'active' : ''}`}>
          </div>
          <div className={`personal_page-sidebar ${theme} ${this.state.dropSidebar ? 'active' : ''}`}>
            <div className={`personal_page-navigations`}>
              <Nav className={`flex-column personal_page-navigations-nav`}>
                <NavLink
                  activeClassName=' page-navigations-active'
                  className={
                    ` personal_page-navigations-nav-profile empty_avatar-personal_page ${'light-nav'}`
                  }
                  to={PATH.PersonalPagePersonalArea}
                >
                  Yusa@garpix.com
              </NavLink>
                <NavLink
                  activeClassName=' page-navigations-active'
                  className={`personal_page-navigations-nav-all_projects`}
                  to={PATH.PersonalPageAllProject}
                >
                  Все проекты
              </NavLink>
                <div className={`personal_page-navigations-nav-my_projects`}>
                  <NavLink
                    activeClassName=' page-navigations-active'
                    to={PATH.PersonalPageMyProject}
                  >
                    Мои проекты
                  </NavLink>
                  <div onClick={this.addProjectOpenModal} className={`personal_page-navigations-nav-add_project`}>+</div>
                </div>

                <NavLink
                  activeClassName='page-navigations-active'
                  className={`personal_page-navigations-nav-favorite`}
                  to={PATH.PersonalPageFavoriteProject}
                >
                  Избранное
              </NavLink>
                <NavLink
                  activeClassName=' page-navigations-active'
                  className={`personal_page-navigations-nav-search`}
                  to={PATH.PersonalPageSearch}
                >
                  Поиск
              </NavLink>
                <NavLink
                  activeClassName='page-navigations-active'
                  className={`personal_page-navigations-nav-trash`}
                  to={PATH.PersonalPageBagTrash}
                >
                  Корзина
              </NavLink>
                <NavLink
                  activeClassName='page-navigations-active'
                  className={`personal_page-navigations-nav-settings`}
                  to={PATH.PersonalPageSettingPage}
                >
                  Настройки
              </NavLink>
              </Nav>
            </div>
            <div className={`personal_page-buttons`}>
              <Button
                variant="outline-primary"
                className={`personal_page-buttons-create_project`}
                onClick={this.addProjectOpenModal}
              >
                Создать проект
            </Button>
            </div>
          </div>


          <div className={`personal_page-content ${theme}`}>
            {this.props.children ?
              React.Children.map(this.props.children, (child) => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(child, {
                    updatecomponent: updatecomponent.toString(),
                    stopupdate: this.stopUpdate
                  })
                } else {
                  return null
                }
              })
              : <HelloHeadLine title='Добро пожаловать в Garpix Digital Twin,YUSA.' />}
            <QuestionHelpers />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect('ProjectTheme', "UserData", PersonalPageLayout) 
