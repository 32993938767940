import React from "react";
import { AbstractObject } from "../../../gengine";
import DotCreator from '../DotCreator'
import { sercheAllObjectByProperty } from "../../../utils";
import serializers from "../../../utils/SerializedObject";
import { DATA_OBJECT_SCENE } from "../../../const";
import isEqual from "react-fast-compare";
import {
  CatmullRomCurve3,
  Geometry,
  MeshBasicMaterial,
  Vector3,
  Line
} from 'three'


class CurveCreator extends AbstractObject {
  constructor(props) {
    super()
    this.coeficentParentScale = 45
    this.defaultOffset = 0.05
    this.state = {
      fullLineRender: [],
      positionDotMoveMouse: false,
      renderDotsAll: []
    }
  }

  geometryCurveCollector = (renderArc = []) => {
    // let geometrys = []
    // if (renderArc.length) {
    //   renderArc.forEach((el) => {
    //     // 
    //   })
    // } else {
    //   return false
    // }
  }

  renderLine = (el, id) => {
    console.log("LINE CREATE")
    const { vertices = [new Vector3(-1, 1, -1), new Vector3(1, 1, 1)] } = el
    return <Line {...this.props} vertices={vertices} key={id} name={DATA_OBJECT_SCENE.ARC_FRAGMETS.name} />
  }

  clearOldArcFragments = (scene) => {
    let allFragments = sercheAllObjectByProperty("name", DATA_OBJECT_SCENE.ARC_FRAGMETS.name, scene)
    allFragments.forEach(element => {
      element.geometry.dispose()
      element.material.dispose()
      element.parent.remove(element)
    });
  }

  renderArcFragmets = (allVertices = []) => {
    const { scene } = this.props
    if (!Array.isArray(allVertices)) return false
    let vertices = allVertices.map(el => new Vector3(el.x, el.y, el.z))
    let numPoints = vertices.length;
    let spline = new CatmullRomCurve3(vertices);
    let geometry = new Geometry();
    let splinePoints = spline.getPoints(numPoints);
    geometry.lineDistancesNeedUpdate = true;
    for (let i = 0; i < splinePoints.length; i++) {
      geometry.vertices.push(splinePoints[i]);
    }
    let material = new MeshBasicMaterial({
      color: "red"
    })
    let mesh = new Line(geometry, material)
    mesh.name = DATA_OBJECT_SCENE.ARC_FRAGMETS.name
    scene.add(mesh)
  }

  setDataVerticesLine = (props) => {
    const { renderArc = [], renderDots } = props
    let newRenderDotsAll = serializers.arcToDotData(renderArc)
    if (!isEqual(newRenderDotsAll, renderDots)) {
      this.setState({
        renderDotsAll: newRenderDotsAll
      }, () => {
        this.clearOldArcFragments(this.props.scene)
        renderDots.forEach((el, i) => {
          if (el.type == "arc") {
            this.renderArcFragmets(el.verticesForArc.map((el) => new Vector3(el.x, el.y, el.z)))
          }
        })
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.setDataVerticesLine(this.props)
      return true
    }
    return false
  }


  componentDidMount() {
    this.setDataVerticesLine(this.props)
  }

  render() {
    //! это второстепернный компонент он нужен для временного рендера
    const { renderDotsAll } = this.state
    const {
      addEventListenerDotWrapperClick,
      addEventListenerDotWrapperMove,
      addEventListenerDotWrapperOut
    } = this.props
    return (
      <React.Fragment>
        <DotCreator
          {...this.props}
          renderDots={renderDotsAll}
          addEventListenerDotWrapperClick={addEventListenerDotWrapperClick}
          addEventListenerDotWrapperMove={addEventListenerDotWrapperMove}
          addEventListenerDotWrapperOut={addEventListenerDotWrapperOut}
        />
      </React.Fragment>
    )
  }
}

export default CurveCreator;