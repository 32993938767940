import React from 'react'
import ParamsDeviceValue from '../../Views/Layout/ParamsDeviceValue';
import { Tabs as RBTabs, Tab } from 'react-bootstrap'
import RadioButton from '../../Views/Layout/RadioButton';
import TogglerSwitcher from '../../Views/Layout/TogglerSwitcher'
import TableCheckBox from '../../Views/Layout/TableCheckBox'
import InputValueData from '../../Views/Layout/InputValueData'
import TableGenerate from '../../common/TableGenerate'
import Chart from '../../Views/Layout/Chart'
import ButtonMain from '../../Views/Layout/ButtonMain'
import { v4 } from 'uuid'
import isEqual from 'react-fast-compare'
import Api from '../../api'
import SubmitForm from '../../common/SubmitForm'


const ApiResearch = Api.ResearchApi
export default class DCMachineComponentForSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabKey: 'settings',
      activeTypeTok: false,
      simplifiedType: true,
      fieldsType: false,
      windingsTableCoils: [],
      windingsTableInductance: [],
      functionPTableForCharts: [],
      TableCheckBoxLabel: [
        { name: "ОДП", checked: false, id: v4() },
        { name: "ШОФ", checked: false, id: v4() },
        { name: "ЯО", checked: false, id: v4() },
        { name: "СОВ", checked: false, id: v4() },
        { name: "КО", checked: false, id: v4() },
      ],
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Ном. мощность (Вт)', value: 9.9999e+42 },
        { id: v4(), nameOfValue: 'Ном. напряжение (В):', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Ном. скорость (об/мин.)', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Число пар полюсов', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Число пар парал.ветвей', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Момент инерции якоря', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Момент холост.хода (H*m) ', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Предельная остаточная', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Max. поток (Вб)', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Max. намагничивающаяся', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Размаг.действие попер.РЯ', value: 9.9999e+42, }
      ],
      tableHeadData: [
        [
          { name: '№к', attr: { className: "custom_width", width: "40px" } },
          { name: 'Ноб', attr: {} },
          { name: 'Витки', attr: {} }
        ]
      ],
      tableHeadDataTwo: [[{ name: '№к', attr: { className: "custom_width", width: "40px" } }, { name: 'Сопротивление', attr: {} }, { name: 'Индуктивность', attr: {} }],],
      tableBodyData: [],
      tableBodyDataTwo: [],
      variant: "vls-table_data-oscilloscope-table",
      dataChartFunctionTab: [30, -30, 0, 30, 0],
      tableHeadDataThreeTab: [[{ name: 'F = A', attr: {} }, { name: 'Фр (Вб)', attr: {} }]],
      tableBodyDataTwoThreeTab: [],
      changeIn: false
    }
  }

  submitData = () => {
    let { hash_id, type } = this.props.element
    if (!hash_id) hash_id = this.props.element.id
    const {
      paramsDeviceDataNamefields,
      activeTypeTok,
      simplifiedType,
      fieldsType,
      TableCheckBoxLabel,
      dataChartFunctionTab,
      functionPTableForCharts,
      windingsTableCoils,
      windingsTableInductance
    } = this.state
    let params = {
      device_id: hash_id,
      params: {
        type: type,
        meta: {
          defaultSettings: {
            nomPowerWt: paramsDeviceDataNamefields[0].value,
            nomVoltageV: paramsDeviceDataNamefields[1].value,
            nomSpeedRPM: paramsDeviceDataNamefields[2].value,
            countPlus: paramsDeviceDataNamefields[3].value,
            numberOfPairsOfParallelBranches: paramsDeviceDataNamefields[4].value,
            momentOfInertiaOfAnchor: paramsDeviceDataNamefields[5].value,
            idleTorque: paramsDeviceDataNamefields[6].value,
            limitingResidual: paramsDeviceDataNamefields[7].value,
            maxStream: paramsDeviceDataNamefields[8].value,
            maxMagnetizing: paramsDeviceDataNamefields[9].value,
            degaussingActionAcrossRI: paramsDeviceDataNamefields[10].value,
          },
          DC: activeTypeTok,
          simplifiedType: simplifiedType,
          fieldsType: fieldsType,
          windingsCheckBoxTable: TableCheckBoxLabel,
          dataChartFunctionTab: dataChartFunctionTab,
          functionPTableForCharts: functionPTableForCharts,
          windingsTableCoils: windingsTableCoils,
          windingsTableInductance: windingsTableInductance,
        }
      }
    }
    SubmitForm(ApiResearch.putResearchUpdateDeviceData, params, this.successSubmit)
  }

  successSubmit = (response) => {
    console.log(response)
  }

  updateDeviceData = (data) => {
    this.setState(data, this.submitData)
  }

  defaultDeviceDataChange = (e) => {
    const { paramsDeviceDataNamefields } = this.state
    let resultsUpdate = []
    for (let i = 0; i < paramsDeviceDataNamefields.length; i++) {
      const element = paramsDeviceDataNamefields[i];
      if (element.id === e.target.id) {
        resultsUpdate.push({
          ...element,
          value: e.target.value
        })
      } else {
        resultsUpdate.push(element)
      }
    }
    let newData = {
      paramsDeviceDataNamefields: resultsUpdate
    }
    this.updateDeviceData(newData)
  }

  changeWindingsTableCoils = (data) => {
    const { windingsTableCoils, windingsTableInductance } = this.state
    const getData = (defaultData) => {
      return defaultData.map((el, i) => {
        if (data.iter === i) {
          el[data.key] = Number(data.target.value)
          return el
        } else {
          return el
        }
      })
    }
    let result = {}
    if (data.type === 'windingsTableInductance') {
      result = {
        windingsTableInductance: getData(windingsTableInductance),
        changeIn: true
      }
    } else if (data.type === 'windingsTableCoils') {
      result = {
        windingsTableCoils: getData(windingsTableCoils),
        changeIn: true
      }
    }
    this.updateDeviceData(result)
  }

  changeTableDataForCharts = (data) => {
    const { functionPTableForCharts } = this.state
    this.updateDeviceData({
      changeIn: true,
      functionPTableForCharts: functionPTableForCharts.map((el, i) => {
        if (data.iter === i) {
          el[data.key] = Number(data.target.value)
          return el
        } else {
          return el
        }
      })
    })
  }

  generateTableDataForCharts = (data = []) => {
    let arr = []
    for (let i = 0; i < data.length; i++) {
      let element = data[i]
      let valueDivision = {
        tdParams: { className: "td_padding_null" },
        content: (
          <div key={i}>
            <InputValueData
              wrapperClassName={'vls-table_data-oscilloscope-input_wrapper'}
              className={"data vls-table_data-oscilloscope-input"}
              type={'number'}
              value={element.fEqualsA}
              onChange={(e) => {
                let data = {
                  target: e.target,
                  iter: i,
                  key: "fEqualsA"
                }
                this.changeTableDataForCharts(data)
              }}
            />
          </div>
        ),
      };
      let startCount = {
        tdParams: { className: "td_padding_null" },
        content: <div key={i} className={``}>
          <InputValueData
            wrapperClassName={'vls-table_data-oscilloscope-input_wrapper'}
            className={"data vls-table_data-oscilloscope-input"}
            type={'number'}
            value={element.fr}
            onChange={(e) => {
              let data = {
                target: e.target,
                iter: i,
                key: "fr"
              }
              this.changeTableDataForCharts(data)
            }}
          />
        </div>,
      };
      arr.push([valueDivision, startCount])
    }
    return arr
  }

  generateTableBody = (data = [], type) => {
    let arr = []
    for (let i = 0; i < data.length; i++) {
      let element = data[i]
      let name = {
        tdParams: { className: "custom_width", width: "40px" },
        content: (
          <React.Fragment key={i}>
            {element.n}
          </React.Fragment>
        ),
      };
      let valueDivision = {
        tdParams: { className: "td_padding_null" },
        content: (
          <div key={i}>
            <InputValueData
              wrapperClassName={'vls-table_data-oscilloscope-input_wrapper'}
              className={"data vls-table_data-oscilloscope-input"}
              type={'number'}
              value={element.nob}
              onChange={(e) => {
                let data = {
                  key: "nob",
                  target: e.target,
                  iter: i,
                  type: type
                }
                this.changeWindingsTableCoils(data)
              }}
            />
          </div>
        ),
      };
      let startCount = {
        tdParams: { className: "td_padding_null" },
        content: <div key={i} className={``}>
          <InputValueData
            wrapperClassName={'vls-table_data-oscilloscope-input_wrapper'}
            className={"data vls-table_data-oscilloscope-input"}
            type={'number'}
            value={element.coils}
            onChange={(e) => {
              let data = {
                key: "coils",
                target: e.target,
                iter: i,
                type: type
              }
              this.changeWindingsTableCoils(data)
            }}
          />
        </div>,
      };
      arr.push([name, valueDivision, startCount])
    }
    return arr
  }

  clickJustCircutOrFieldType = (e) => {
    const { simplifiedType, fieldsType } = this.state
    let data = {
      simplifiedType: !simplifiedType,
      fieldsType: !fieldsType
    }
    this.updateDeviceData(data)
  }

  changeTypeTok = () => {
    const { activeTypeTok } = this.state
    this.updateDeviceData({
      activeTypeTok: !activeTypeTok
    })
  }

  selectTab = (e) => {
    this.setState({
      activeTabKey: e
    })
  }

  handlerNumberPhases = (e) => {

  }

  setDataDevice = (props) => {
    console.log(props)
    const { element } = props
    const {
      defaultSettings,
      DC,
      simplifiedType,
      fieldsType,
      windingsCheckBoxTable,
      dataChartFunctionTab,
      windingsTableCoils,
      windingsTableInductance,
      functionPTableForCharts
    } = element.meta
    this.setState({
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Ном. мощность (Вт)', value: defaultSettings.nomPowerWt },
        { id: v4(), nameOfValue: 'Ном. напряжение (В):', value: defaultSettings.nomVoltageV },
        { id: v4(), nameOfValue: 'Ном. скорость (об/мин.)', value: defaultSettings.nomSpeedRPM },
        { id: v4(), nameOfValue: 'Число пар полюсов', value: defaultSettings.countPlus },
        { id: v4(), nameOfValue: 'Число пар парал.ветвей', value: defaultSettings.numberOfPairsOfParallelBranches },
        { id: v4(), nameOfValue: 'Момент инерции якоря', value: defaultSettings.momentOfInertiaOfAnchor },
        { id: v4(), nameOfValue: 'Момент холост.хода (H*m) ', value: defaultSettings.idleTorque },
        { id: v4(), nameOfValue: 'Предельная остаточная', value: defaultSettings.limitingResidual },
        { id: v4(), nameOfValue: 'Max. поток (Вб)', value: defaultSettings.maxStream },
        { id: v4(), nameOfValue: 'Max. намагничивающаяся', value: defaultSettings.maxMagnetizing },
        { id: v4(), nameOfValue: 'Размаг.действие попер.РЯ', value: defaultSettings.degaussingActionAcrossRI }
      ],
      activeTypeTok: DC,
      simplifiedType: simplifiedType,
      fieldsType: fieldsType,
      TableCheckBoxLabel: windingsCheckBoxTable,
      dataChartFunctionTab: dataChartFunctionTab,
      tableBodyData: this.generateTableBody(windingsTableCoils, 'windingsTableCoils'),
      tableBodyDataTwo: this.generateTableBody(windingsTableInductance, 'windingsTableInductance'),
      tableBodyDataTwoThreeTab: this.generateTableDataForCharts(functionPTableForCharts),
      windingsTableCoils: windingsTableCoils,
      windingsTableInductance: windingsTableInductance,
      functionPTableForCharts: functionPTableForCharts,
      changeIn: false
    })
  }

  changeTableCheckBoxLabel = (e) => {
    const { TableCheckBoxLabel } = this.state
    let result = TableCheckBoxLabel.map((el, i) => {
      if (el.id === e.target.id) {
        el.checked = e.target.checked
        return el
      } else {
        return el
      }
    })
    this.updateDeviceData({
      TableCheckBoxLabel: result
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.element, prevProps.element)) {
      this.setDataDevice(this.props)
    }
    if (prevState.windingsTableCoils !== this.state.windingsTableCoils && this.state.changeIn) {
      this.setState({
        tableBodyData: this.generateTableBody(this.state.windingsTableCoils, 'windingsTableCoils'),
      })
    }
    if (prevState.windingsTableInductance !== this.state.windingsTableInductance && this.state.changeIn) {
      this.setState({
        tableBodyDataTwo: this.generateTableBody(this.state.windingsTableInductance, 'windingsTableInductance'),
      })
    }
    if (prevState.functionPTableForCharts !== this.state.functionPTableForCharts && this.state.changeIn) {
      this.setState({
        tableBodyDataTwoThreeTab: this.generateTableDataForCharts(this.state.functionPTableForCharts),
      })
    }
  }


  componentDidMount() {
    this.setDataDevice(this.props)
  }

  render() {
    const {
      activeTabKey,
      paramsDeviceDataNamefields,
      activeTypeTok,
      TableCheckBoxLabel,
      tableHeadData,
      tableBodyData,
      tableHeadDataTwo,
      tableBodyDataTwo,
      variant,
      dataChartFunctionTab,
      tableHeadDataThreeTab,
      tableBodyDataTwoThreeTab,
      simplifiedType,
      fieldsType
    } = this.state
    return (
      <React.Fragment>
        <span className={"vls-sidebar-device_title"}>МАШИНА П0СТОЯННОГО ТОКА</span>
        <RBTabs
          id="vls-sidebar_tabs_wraper"
          defaultActiveKey={'base'}
          activeKey={activeTabKey}
          onSelect={this.selectTab}
        >
          <Tab tabClassName={"vls-sidebar_tabs_wraper-tab"} eventKey="settings" title="Параметры">
            <div className={"vls-dc_machine-params_settings"}>
              <TogglerSwitcher
                active={activeTypeTok}
                onChange={this.changeTypeTok}
                firstTogglerValue={"Постоянный ток"}
                secondTogglerValue={"Асинхронная маш."}
              />
              <ParamsDeviceValue
                onChange={this.defaultDeviceDataChange}
                classNameForInput={"vls-sidebar_tabs_wraper-tab-input"}
                data={paramsDeviceDataNamefields}
              />
            </div>
            <div className={"vls-dc_machine-model_wrapper"}>
              <div className={"vls-dc_machine-model_wrapper-block"}>
                <RadioButton onClick={this.clickJustCircutOrFieldType} checked={simplifiedType} label={"Упрощенная"} />
                <RadioButton onClick={this.clickJustCircutOrFieldType} checked={fieldsType} label={"Полевая"} />
              </div>
            </div>
            <div className='content-border-line'></div>
            <span className={"vls-dc_machine-windings-title"}>Обмотки</span>
            <div className={"vls-dc_machine-windings"}>
              <TableCheckBox onChange={this.changeTableCheckBoxLabel} data={TableCheckBoxLabel} />
            </div>
            <div className={"vls-dc_machine-windings border_none"}>
              <TableGenerate
                variant={variant}
                TableHeadData={tableHeadData}
                TableBodyData={tableBodyData}
              />
            </div>
            {/* .table-vls-table_data-oscilloscope-table th, .table-vls-table_data-oscilloscope-table td */}
            <div className={"vls-dc_machine-windings border_none"}>
              <TableGenerate
                variant={variant}
                TableHeadData={tableHeadDataTwo}
                TableBodyData={tableBodyDataTwo}
              />
            </div>
          </Tab>

          <Tab tabClassName={"vls-sidebar_tabs_wraper-tab"} eventKey="functions" title="Ф = f (Ф)">
            <Chart width={290} height={290} dataChart={dataChartFunctionTab} />
            <div className='content-border-line'></div>
          </Tab>
          <Tab tabClassName={"vls-sidebar_tabs_wraper-tab"} eventKey="functionsP" title="Фр = f (Ф)">
            <Chart width={290} height={230} dataChart={dataChartFunctionTab} />
            <ButtonMain className={'button__option-menu vls-btn-rebuild-inductance'}>Перестроить</ButtonMain>
            <div className={"vls-dc_machine-windings border_none"}>
              <TableGenerate
                variant={variant}
                TableHeadData={tableHeadDataThreeTab}
                TableBodyData={tableBodyDataTwoThreeTab}
              />
            </div>

          </Tab>
        </RBTabs>
      </React.Fragment>
    )
  }
}