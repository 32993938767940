import { stateSerializedVLC } from "./stateSerializedVLC";
import { wiresAndConnectorSerialized } from "./wiresAndConnectorSerialized";
import { deviceConnectorSerialized } from "./deviceConnectorSerialized";
import { collisionsHandlerWiresSerialized } from "./collisionsHandlerWiresSerialized";
import { addWires } from "./addWires";
import { setColorWireData } from "./setColorWireData";
import { savedMinifyObjects } from "./savedMinifyObjects";
import { vlsDTB } from "./vlsDTB";
import { getNewComponentData } from "./getNewComponentData";
import { couplingSleeveSerialazer } from './couplingSleeveSerialazer'
import { couplingSleeveStateCreator } from './couplingSleeveStateCreator'
import { generateSaveData } from './generateSaveData'
import { parseDotForStraight } from './parseDotForStraight'
import { arcToDotData } from './arcToDotData'
import { addPointToRenderDots } from './addPointToRenderDots'
import { getDotFromPrimitivesGraf } from './getDotFromPrimitivesGraf'

export default {
  getDotFromPrimitivesGraf,
  addPointToRenderDots,
  arcToDotData,
  parseDotForStraight,
  getNewComponentData,
  vlsDTB,
  savedMinifyObjects,
  addWires,
  setColorWireData,
  collisionsHandlerWiresSerialized,
  stateSerializedVLC,
  wiresAndConnectorSerialized,
  deviceConnectorSerialized,
  couplingSleeveSerialazer,
  couplingSleeveStateCreator,
  generateSaveData
};
