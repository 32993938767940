import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";
import RadioButton from "../../Views/Layout/RadioButton";
import CheckBox from "../../Views/Layout/CheckBox";
import ConfirmComponent from "../../common/ConfirmComponent";
import InputValueData from '../../Views/Layout/InputValueData'
import connect from 'storeon/react/connect'
import isEqual from 'react-fast-compare'
import Api from '../../api'
import SubmitForm from '../SubmitForm'

const ApiResearch = Api.ResearchApi
class OptionsVLSDropDown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeIncrement: 5.00000e-006,
      endTime: 5.00000e-006,
      lr: 5.00000e-006,
      straightWireDrawingMode: false,
      euler: false,
      eulerAddHalfStep: false,
      rungeKuttaOrder: true
    }
  }


  submitData = () => {
    let { id } = this.props.match.params
    if (!id) id = this.props.element.id
    const {
      timeIncrement,
      endTime,
      lr,
      straightWireDrawingMode,
      euler,
      eulerAddHalfStep,
      rungeKuttaOrder,
    } = this.state
    let params = {
      hash_id: id,
      params: {
        globalSettings: {
          timeIncrement: timeIncrement,
          endTime: endTime,
          lr: lr,
          straightWireDrawingMode: straightWireDrawingMode,
          euler: euler,
          eulerAddHalfStep: eulerAddHalfStep,
          rungeKuttaOrder: rungeKuttaOrder
        }
      }
    }
    SubmitForm(ApiResearch.putResearch, params, this.successSubmit)
  }

  successSubmit = (response) => {
    console.log(response)
  }

  updateDeviceData = (data) => {
    this.setState(data, this.submitData)
  }

  setData = (data) => {
    //todo: доделай когда бек будет готов
    // this.setState({
    //   paramsDeviceDataNamefields: [
    //     { id: v4(), nameOfValue: 'Масштаб по времени', value: defaultSettings.scaleInTime },
    //     { id: v4(), nameOfValue: 'Ёмкость (Ф)', value: defaultSettings.capacity },
    //     { id: v4(), nameOfValue: 'Max. напряжение (В)', value: defaultSettings.maxVoltageB },
    //   ]
    // })
  }


  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.element, prevProps.element)) {
      this.setData(this.props)
    }
  }


  componentDidMount() {
    this.setData(this.props)
  }

  render() {
    const theme = this.props.ProjectTheme === 'light' ? 'light' : 'dark'
    const {
      euler,
      eulerAddHalfStep,
      rungeKuttaOrder,
      timeIncrement,
      endTime,
      lr,
      straightWireDrawingMode,
    } = this.state
    const { close } = this.props
    return (
      <div className={`vls-drop_down_wrap_option-wrap  ${theme}`}>
        <div>
          <span className={"vls-drop_down_wrap_option-header_text"}>
            Опции режима имитации
        </span>
          <div
            onClick={close}
            className={"vls-drop_down_wrap_option-closed"}>

          </div>
        </div>
        <div className={"vls-drop_down_wrap_option-wrap_control_input "}>
          <InputValueData
            firstDescription='Приращение по времени(с) dt='
            onChange={(e) => {
              this.setState({
                timeIncrement: e.target.value
              })
            }}
            value={timeIncrement}
          />
          <InputValueData
            firstDescription='конечное время(с) tкон='
            onChange={(e) => {
              this.setState({
                endTime: e.target.value
              })
            }}
            value={endTime}
          />
        </div>
        <div className={"vls-drop_down_wrap_option-wrap_scheme_rigidity"}>
          <div className={"vls-drop_down_wrap_option-title_scheme_rigidity"}>
            Чувствительность к жесткости схемы
        </div>
          <div className={"vls-drop_down_wrap_option-wrap_rigidity_control"}>
            <InputValueData
              firstDescription='L/R'
              secondDescription='* dt'
              onChange={(e) => {
                this.setState({
                  lr: e.target.value
                })
              }}
              value={lr}
            />
          </div>
          <div className={"vls-drop_down_wrap_option-wrap_scheme_rigidity"}>
            <div className={"vls-drop_down_wrap_option-title_scheme_rigidity"}>
              Измеряемая величина
        </div>
            <div
              className={"vls-drop_down_wrap_option-wrap_rigidity_control_value"}
            >
              <Form.Group
                as={Col}
                className={"vls-drop_down_wrap_option-form_group_input_value"}
              >
                <Form.Label
                  className={"vls-drop_down_wrap_option-label_control_input_value"}
                >
                  <RadioButton
                    checked={euler}
                    onClick={(e) => {
                      this.setState({
                        euler: true,
                        eulerAddHalfStep: false,
                        rungeKuttaOrder: false
                      })
                    }}
                  />
              Эйлера
            </Form.Label>
                <Form.Label
                  className={"vls-drop_down_wrap_option-label_control_input_value"}
                >
                  <RadioButton
                    checked={eulerAddHalfStep}
                    onClick={(e) => {
                      this.setState({
                        euler: false,
                        eulerAddHalfStep: true,
                        rungeKuttaOrder: false
                      })
                    }}
                  />
              Эйлера с добавочным полушагом
            </Form.Label>
                <Form.Label
                  className={"vls-drop_down_wrap_option-label_control_input_value"}
                >
                  <RadioButton
                    checked={rungeKuttaOrder}
                    onClick={(e) => {
                      this.setState({
                        euler: false,
                        eulerAddHalfStep: false,
                        rungeKuttaOrder: true
                      })
                    }}
                  />
              Рунге-Кутта 4-го порядка
            </Form.Label>
              </Form.Group>
            </div>
          </div>
          <div className={"vls-drop_down_wrap_option-control_mode"}>
            <CheckBox
              checked={straightWireDrawingMode}
              onChange={(e) => {
                this.setState({
                  straightWireDrawingMode: e.target.checked
                })
              }}
              label={"Режим прямолинейной отрисовки проводов"}
            />
          </div>
          <div className={"vls-drop_down_wrap_option-wrap_confirm"}>
            <ConfirmComponent callbackCancellation={close} callbackOk={this.submitData} />
          </div>
        </div>
      </div>
    );
  }
};

export default connect('ProjectTheme', OptionsVLSDropDown);
