import React from "react";
import Api from "../../api";
import { ReactComponent as PlusVector } from "../../assets/images/PlusVector.svg";
import { ReactComponent as MinusForFaq } from "../../assets/images/minusForFaq.svg";
const ApiContent = Api.ContentApi;
class FaqHomePageComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      faqRenderList: [],
      isVisibleResponseTextId: false,
    };
  }

  componentDidMount() {
    ApiContent.getQuestrionsForFaq({}).then((res) => {
      this.setState({
        faqRenderList: res,
      });
    });
  }

  render() {
    const { faqRenderList, isVisibleResponseTextId } = this.state;

    return (
      <div className={"faq_page-wrap"}>
        <h2 className={"faq_page-wrap-title"}>FAQ</h2>
        <span className={"faq_page-wrap-text_under_title"}>
          Перед вами лист с самыми популярными вопросами и ответами. Если вы не
          найдете ответ на свой вопрос, то вы можете отправить нам запрос и мы
          ответим на ваш <span>email</span>
        </span>
        <div className={"faq_page-wrap-content_wrap"}>
          {faqRenderList.map((el, i) => {
            return (
              <div key={i} className={"faq_page-wrap-wrap_questrion_block"}>
                <div
                  onClick={(e) => {
                    this.setState({
                      isVisibleResponseTextId:
                        isVisibleResponseTextId === i ? false : i,
                    });
                  }}
                >
                  <span
                    className={`faq_page-wrap-wrap_questrion_block-questrion ${
                      isVisibleResponseTextId === i ? "active" : ""
                    }`}
                  >
                    {el.question}
                    {isVisibleResponseTextId === i ? (
                      <MinusForFaq
                        className={"faq_page-wrap-wrap_questrion_block-minus"}
                      />
                    ) : (
                      <PlusVector
                        className={"faq_page-wrap-wrap_questrion_block-plus"}
                      />
                    )}
                  </span>

                  <div
                    className={`faq_page-wrap-wrap_questrion_block-response ${
                      isVisibleResponseTextId === i ? "active" : ""
                    }`}
                  >
                    {el.answer}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default FaqHomePageComponent;
