import React from "react";
import connect from 'storeon/react/connect'

const ControllerOffAndOn = (props) => {
  let theme = props.ProjectTheme === 'dark' ? 'panel' : 'light-panel'
  return (
    <div className={`tool_panel-controler`}>
      <div className={`tool_panel-controler-line`}></div>
      <div
        className={`tool_panel-controler-on_and_off_btn ${
          props.isPlay ? "on" : "off"
        }`}
        onClick={props.PlayOrStop}
      ></div>
      <div
        className={`tool_panel-controler-pause_btn ${
          props.isPaused ? "pause" : "play"
        }`}
        onClick={props.PausedOrPlay}
      ></div>
    </div>
  );
};

export default connect('ProjectTheme', ControllerOffAndOn);
