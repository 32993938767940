
import BaseApiForSocket from './BaseApiForSocket'


class SocketApiVLS extends BaseApiForSocket {
  constructor(data = {
    onOpen: (e) => console.log(e),
    onConnect: (e) => console.log(e),
    onDisconnect: (e) => console.log(e),
    onError: (e) => console.log(e),
  }) {
    super()
    this.onOpen = data.onOpen
    this.onConnect = data.onConnect
    this.onDisconnect = data.onDisconnect
    this.onError = data.onError
  }
  startSceneVLS = (
    hash_id = '',
    start = (e) => { console.log(e) },
    stop = (e) => { console.log(e) },
    play = (e) => { console.log(e) },
    pause = (e) => { console.log(e) },
  ) => {
    this.init(true, '/research/', hash_id);
    this.on('open', this.onOpen);
    this.on('connect', this.onConnect);
    this.on('start', start);
    this.on('stop', stop);
    this.on('play', play);
    this.on('pause', pause);
    this.on('disconnect', this.onDisconnect);
    this.on('error', this.onError);
  }

  emitData = (event = 'connect', data, key) => {
    this.emit(event, data, key);
  }

}

export default SocketApiVLS