/**
 * сериализует кривую в структуру точек
 * @param paramsArc
 */
export const addPointToRenderDots = (paramsArc) => {
  const {
    radiusAStartPoint,
    radiusAEndPoint,
    renderDots,
    verticesForArc,
    renderArc
  } = paramsArc
  let result = {
    type: "arc",
    radiusAStartPoint: radiusAStartPoint,
    radiusAEndPoint: radiusAEndPoint,
    startPoint: renderArc[1],
    centerPoint: renderArc[0],
    endPoint: renderArc[2],
    verticesForArc: verticesForArc,
    //! начальная и последняя вершины это старт поин и енд поинт
    linkConnetedDot: []
  }

  // coeficentR: 5
  // countId: 0
  // distance: null
  // edge: Array(1)
  // 0: { direction: 13.2748926727173, color: "red", idEndLink: "5dcb16de-93c4-4958-ba66-837403b8881c", aproximationVertices: Array(2) }
  // length: 1
  // __proto__: Array(0)
  // linkToStraight: Array(1)
  // 0: "5dcb16de-93c4-4958-ba66-837403b8881c"
  // length: 1
  // __proto__: Array(0)
  // position: Vector3 { x: -13.340527304483386, y: 0.05, z: -10.754863254460261 }
  // uuidDot: "942c319b-fffe-412c-8de7-364bc2d5f20f"
  // x: -13.340527304483386
  // y: -10.754863254460261
  renderDots.push(result)
  console.log('@renderDots', renderDots)
  return renderDots
}