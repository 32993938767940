import { CatmullRomCurve3, Geometry, Vector3, BoxGeometry, SphereGeometry } from "three";
import { DATA_OBJECT_SCENE } from "../../../const";

/**
  @param data, scene, enableShadows
  @param scene
  @param enableShadows
  @param isUpdateLine
  @param isConnectorUpdate
  @return вернет объект с геометрией для линий и данные для рендера конекторов
 */
export const wiresAndConnectorSerialized = (
  data,
  scene,
  enableShadows = false,
  isUpdateLine,
  isConnectorUpdate
) => {
  let geometryArr = [];
  let dataConnector = [];
  let dataMesh = [];
  //переберет вершины провода для того чтоб отрисовать конекторы на концах
  const addDataConnector = (el, iteratorWire) => {
    el.vertices.map((elDataVertices, iteratorVertices) => {
      let color = el.color;
      let iterArrToAllVertices = iteratorWire;
      let iterArrToContextVertices = iteratorVertices;
      let allowClick = false;
      let rightClick = false;
      let mesh = elDataVertices.type === "Mesh" ? elDataVertices : null;
      const geometry = new SphereGeometry(0.2, 5, 5);
      //проверяет на то 1 это элемент или последний или уже подключенный
      // если все проверки прошли то разрешит навешивать события
      // и даст спец цветd
      if (
        data[iteratorWire].vertices.length - 1 === iteratorVertices ||
        (iteratorVertices === 0) //&& elDataVertices.type !== "Mesh"
      ) {
        allowClick = true;
        rightClick = true;
        color = "blue";
      } else {
        color = "#828282";
      }
      if (!isUpdateLine || isConnectorUpdate) {
        let data = {
          allowClick,
          rightClick,
        };
        dataConnector.push({
          id: elDataVertices.id,
          startPositions: elDataVertices,
          geometry,
          color,
          iterArrToAllVertices,
          iterArrToContextVertices,
          data,
          mesh,
          scene,
          enableShadows,
        });
      }
    });
  };
  data.map((el, i) => {
    let vertices = el.vertices.map(
      (el) => {
        if (Array.isArray(el.position)) {
          return new Vector3(el.position[0], el.position[1], el.position[2])
        }
        if (
          el.position instanceof Vector3 ||
          el.position instanceof Object
        ) {
          return new Vector3(el.position.x, el.position.y, el.position.z)
        }

      }
    );
    let numPoints = 250;
    let spline = new CatmullRomCurve3(vertices);
    let geometry = new Geometry();
    let splinePoints = spline.getPoints(numPoints);
    geometry.lineDistancesNeedUpdate = true;
    for (let i = 0; i < splinePoints.length; i++) {
      geometry.vertices.push(splinePoints[i]);
    }
    if (isUpdateLine) {
      // todo: сделай адекватное обновление ато линия тянется за любым изменяющимся конектором когда их начинаеш путать!
      let selectedLine = scene.getObjectByName(DATA_OBJECT_SCENE.line.name);
      if (selectedLine) {
        geometry.dispose();
        scene.remove(selectedLine);
      }
    }
    addDataConnector(el, i);
    geometryArr.push({
      scene,
      enableShadows,
      geometry,
      color: el.color,
      position: [0, 0, 0],
      uuid: el.id
    });
  });
  return {
    geometryArr,
    dataConnector,
    dataMesh,
  };
};
