import React from 'react'
import ParamsDeviceValue from '../../Views/Layout/ParamsDeviceValue';
import { Tabs as RBTabs, Tab } from 'react-bootstrap'
import isEqual from 'react-fast-compare';
import v4 from 'uuid/v4';
import Api from '../../api'
import SubmitForm from '../../common/SubmitForm'


const ApiResearch = Api.ResearchApi

export default class ResistorComponentForSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabKey: 'base',
      paramsDeviceDataNamefields: [
        { id: v4(),nameOfValue: 'Имя', value: 9.9999e+42 },
        { id: v4(),nameOfValue: 'Сопротивление (Ом)', value: 9.9999e+42, },
        { id: v4(),nameOfValue: 'Max. ток (А)', value: 9.9999e+42, },
      ],
    }
  }

  submitData = () => {
   let { hash_id, type } = this.props.element
    if (!hash_id) hash_id = this.props.element.id
    const {
      paramsDeviceDataNamefields
    } = this.state
    let params = {
      device_id: hash_id,
      params: {
        type: type,
        meta: {
          defaultSettings: {
            name: paramsDeviceDataNamefields[0].value,
            resistanceOhm: paramsDeviceDataNamefields[1].value,
            maxVoltageA: paramsDeviceDataNamefields[2].value,
          }
        }
      }
    }
    SubmitForm(ApiResearch.putResearchUpdateDeviceData, params, this.successSubmit)
  }

  successSubmit = (response) => {
    console.log(response)
  }
  updateDeviceData = (data) => {
    this.setState(data, this.submitData)
  }

  defaultDeviceDataChange = (e) => {
    const { paramsDeviceDataNamefields } = this.state
    let resultsUpdate = []
    for (let i = 0; i < paramsDeviceDataNamefields.length; i++) {
      const element = paramsDeviceDataNamefields[i];
      if (element.id === e.target.id) {
        resultsUpdate.push({
          ...element,
          value: e.target.value
        })
      } else {
        resultsUpdate.push(element)
      }
    }
    let newData = {
      paramsDeviceDataNamefields: resultsUpdate
    }
    this.updateDeviceData(newData)
  }
  setDataDevice = (props) => {
    const { element } = props
    const { defaultSettings, autoformat, autoComlite } = element.meta
    this.setState({
      paramsDeviceDataNamefields: [
        { id: v4(),nameOfValue: 'Имя', value: defaultSettings.name },
        { id: v4(),nameOfValue: 'Сопротивление (Ом)', value: defaultSettings.resistanceOhm },
        { id: v4(),nameOfValue: 'Max. ток (А)', value: defaultSettings.maxVoltageA },
      ]
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.element, prevProps.element)) {
      this.setDataDevice(this.props)
    }
  }


  componentDidMount() {
    this.setDataDevice(this.props)
  }

  render() {
    const {
      activeTabKey,
      paramsDeviceDataNamefields,
      valueNumberPhases
    } = this.state
    return (
      <React.Fragment>
        <span className={"vls-sidebar-device_title"}>РЕЗИСТОР</span>
        <RBTabs
          id="vls-sidebar_tabs_wraper"
          defaultActiveKey={'base'}
          activeKey={activeTabKey}
        // onSelect={this.selectTab}
        >
          <Tab tabClassName={"vls-sidebar_tabs_wraper-tab"} eventKey="base" title="Параметры">
            <ParamsDeviceValue onChange={this.defaultDeviceDataChange} classNameForInput={"vls-sidebar_tabs_wraper-tab-input"} data={paramsDeviceDataNamefields} />
          </Tab>
        </RBTabs>
      </React.Fragment>
    )
  }
}