import { BoxBufferGeometry,  BoxGeometry,  DoubleSide, Mesh, MeshBasicMaterial } from "three";
import { Object3D } from "../Object3D";
import { addEventCustomListener } from "../utils";

class GenericGroupObject extends Object3D {
  constructor({
    scene,
    enableShadows,
    scale = [1, 1, 1, 1, 1, 1],
    color = "#000000",
    name = "GroupBox",
    position = [0, 0, 0],
    rotation = [0, 0, 0],
    callback,
    customAttribute,
    parent = false,
    uuid,
    visible = false,
    opacity = 1
  } = {}) {
    super();
    const geometry = new BoxGeometry(...scale);
    this.material = new MeshBasicMaterial({
      color: color,
      side: DoubleSide,
      opacity: visible ? opacity : 0,
      transparent: true,
    });
    this.obj = new Mesh(geometry, this.material);
    addEventCustomListener(this.obj, callback);
    this.setPosition(position);
    this.setRotation(rotation);
    this.obj._customAttribute = customAttribute
    this.obj.name = name;
    this.obj.castShadow = enableShadows;
    this.obj.receiveShadow = enableShadows;
    this.obj.uuid = uuid ? uuid : this.obj.uuid;
    if (parent) {
      parent.add(this.obj);
    } else {
      this.addToScene(scene);
    }
  }
}

export default GenericGroupObject;
