import React from "react";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import { NavLink } from "react-router-dom";
import Container from "../Container";
import SceneExemle from "../../assets/images/sceneExemple.png";
import CreateTypeSVG from "../../assets/images/createTypeSwaper.svg";
import DiagnoseTypeSVG from "../../assets/images/diagnoseTypeSwaper.svg";
import OptimizTypeSVG from "../../assets/images/optimizTypeSwaper.svg";
import ProjectTypeSVG from "../../assets/images/projectTypeSwaper.svg";
import RresearchTypeSVG from "../../assets/images/researchTypeSwaper.svg";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={
        "landing-about_service_section-wrapper-service_allows-slider-arrow right"
      }
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={
        "landing-about_service_section-wrapper-service_allows-slider-arrow left"
      }
      onClick={onClick}
    />
  );
}

const AboutServiceSection = (props) => {

  let state = {
    photos: [
      {
        textCount: "01",
        imageType: CreateTypeSVG,
        title: "Cоздавать",
        textContent:
          "цифровые двойники электрооборудования и энергосистемы.",
      },
      {
        textCount: "02",
        imageType: DiagnoseTypeSVG,
        title: "Исследовать",
        textContent:
          "цифровые двойники, имитируя в реальном времени различные режимы работы: статические, динамические, аварийные.",
      },
      {
        textCount: "03",
        imageType: OptimizTypeSVG,
        title: "Оптимизировать",
        textContent:
          "электросети за счет выбора оптимального электрооборудования для них.",
      },
      {
        textCount: "04",
        imageType: ProjectTypeSVG,
        title: "Проектировать",
        textContent:
          "электрооборудование, обеспечивающее оптимальные параметры энергосистем.",
      },
      {
        textCount: "05",
        imageType: RresearchTypeSVG,
        title: "Диагностировать",
        textContent:
          "электрооборудование путем сравнения  характеристик с идеализированными цифровыми двойниками.",
      },
    ],
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    centerPadding: 30,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1
        }
      }
    ]
  };
  const { photos } = state;
  return (
    <section className={"landing-about_service_section"}>
      <Container>
        <div className="landing-about_service_section-wrapper" id={"link-service_info"}>
          <div className={"landing-about_service_section-wrapper-service_info"}>
            <h3
              className={
                "landing-about_service_section-wrapper-service_info-title"
              }              
            >
              О сервисе
            </h3>
            
            <div
              className={
                "landing-about_service_section-wrapper-service_info-subtitle"
              }
            >
              <div className="landing-about_service_section-wrapper-service_info-subtitle_text">
                GARPIX DIGITAL TWIN – система, позволяющая методами имитационного
                моделирования (в частности, с помощью цифровых двойников и
                физических цепей) проектировать электрооборудование, а также
                осуществлять его мониторинг и диагностику.
              </div>
              
            </div>
            <div
              className={
                "landing-about_service_section-wrapper-service_info-image"
              }
              >
              <img
                className={
                  "landing-about_service_section-wrapper-service_info-image-img"
                }
                src={SceneExemle}
                alt={"demo"}
              />
            </div>
            <NavLink
              className={
                "landing-about_service_section-wrapper-service_info-link_to_demo"
              }
              to={"#"}
            >
              Попробовать DEMO
            </NavLink>
            
          </div>
          <div className={"landing-about_service_section-wrapper-service_allows"}>
            <h3
              className={
                "landing-about_service_section-wrapper-service_allows-title"
              }
            >
              Сервис позволяет
            </h3>
            {photos.length ? (
              <div
                className={
                  "landing-about_service_section-wrapper-service_allows-slider"
                }
              >
                <Slider {...settings}>
                  {photos.map((el, i) => {
                    return (
                      <div
                        key={i}
                        className={
                          "landing-about_service_section-wrapper-service_allows-slider-wrapper"
                        }
                      >
                        <img
                          className={
                            "landing-about_service_section-wrapper-service_allows-slider-img"
                          }
                          src={el.imageType}
                          alt={el.title}
                        />
                        <div
                          className={
                            "landing-about_service_section-wrapper-service_allows-slider-number"
                          }
                        >
                          {el.textCount}
                        </div>
                        <div
                          className={
                            "landing-about_service_section-wrapper-service_allows-slider-title"
                          }
                        >
                          {el.title}
                        </div>
                        <div
                          className={
                            "landing-about_service_section-wrapper-service_allows-slider-content"
                          }
                        >
                          {el.textContent}
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            ) : null}
          </div>
        </div>
      </Container>
    </section>
  );
};
export default AboutServiceSection;
