import uuid from "uuid";
import { Vector3 } from "three";

/**
 * Получит инраторы для нужного провода и нужной вершины провода
 * @param [*] oldWire  = тарый масив с проводами
 * @param "" uuidTarget = uuid
 */
const getIteratorVerticesAndWire = (oldWire = [], uuidTarget = "") => {
  for (let i = 0; i < oldWire.length; i++) {
    for (let j = 0; j < oldWire[i].vertices.length; j++) {
      if (oldWire[i].vertices[j].id === uuidTarget) {
        return [i, j];
      }
    }
  }
  return [false, false];
};
/**
 * Добьавляет новый элемент к масиву с проводами
 * @param uuidTarget = "str"
 * @param wiresData = all wires
 * @return {*}
 */
export const addWires = ({ uuidTarget, wiresData }) => {
  let oldWire = wiresData.map((el) => {
    let newConnections = el.connections;
    let newVertices = new Array(...el.vertices);
    return {
      id: el.id,
      type: el.type,
      polarity: el.polarity,
      color: el.color,
      connections: newConnections,
      vertices: newVertices,
      verticesLength: el.verticesLength,
    };
  });
  //todo:гип гип ебаать (Сделано так из-за фичи js с глубоким копированием)

  const randId = () => uuid.v4();
  if (oldWire.length) {
    let [wireIter, vertIter] = getIteratorVerticesAndWire(oldWire, uuidTarget);
    // if (!wireIter || !vertIter) return oldWire;// тут что то ломается надо протестить и починить
    if (vertIter === 0) {
      let veticesContext = oldWire[wireIter].vertices[vertIter].position;
      oldWire[wireIter].vertices.unshift({
        id: randId(),
        position: new Vector3(
          veticesContext.x + 1,
          veticesContext.y,
          veticesContext.z + 1
        ),
        type: "line",
      });
    } else {
      if (oldWire[wireIter]) {
        let veticesContext = oldWire[wireIter].vertices[vertIter].position;
        oldWire[wireIter].vertices.push({
          id: randId(),
          position: new Vector3(
            veticesContext.x + 1,
            veticesContext.y,
            veticesContext.z + 1
          ),
          type: "line",
        });
      }
    }
    if (oldWire[wireIter])
      oldWire[wireIter].verticesLength = oldWire[wireIter].vertices.length;
  }

  return oldWire;
};
