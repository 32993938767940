import isEqual from "react-fast-compare";
import React from "react";
import {
  BoxGeometry,
  MeshLambertMaterial,
  Mesh,
  Vector2,
} from "three";
import { DATA_OBJECT_SCENE } from "../../../const";
import { sercheAllObjectByProperty, debounce } from "../../../utils";

class CheckPositionMoseToScene extends React.Component {
  constructor(props) {
    super();
    this.state = {
      position: null,
      distance: null,
    };
  }


  initMeshForCheckPositionMouse = (props) => {
    let { scene, usles = false } = props;
    const getClicked3DPoint = (evt) => {
      props.getPositionClick({
        position: evt.intersects[0].point,
        distance: null,
      });
    };
    const getMove3DPoint = (evt) => {
      debounce(props.getPositionMove({
        position: evt.intersects[0].point
      }), 1000 / 60, false)
    };
    let geometry = new BoxGeometry(1, 1)
    let material = new MeshLambertMaterial({
      color: '#000',
      transparent: true,
      opacity: 0
    })

    let myObjects = new Mesh(geometry, material)
    myObjects.scale.set(333333333, 0.1, 333333333)
    myObjects.position.set(0, usles ? -5 : 0, 0)
    myObjects.name = DATA_OBJECT_SCENE.clicked_object.name;
    myObjects.on('click', getClicked3DPoint)
    myObjects.on('mousemove', getMove3DPoint)
    scene.add(myObjects);
  }

  clearMemory = () => {
    const { scene } = this.props
    let allObjectRemoved = sercheAllObjectByProperty("name", DATA_OBJECT_SCENE.clicked_object.name, scene)
    allObjectRemoved.forEach((el) => {
      el.geometry.dispose()
      el.material.dispose()
      el.parent.remove(el)
    })
  }

  componentDidUnMount() {
    //удали событие клика
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.usles !== this.props.usles) {
      this.clearMemory()
      this.initMeshForCheckPositionMouse(this.props)
    }
  }


  componentDidMount() {
    this.initMeshForCheckPositionMouse(this.props)
  }

  render() {
    return null;
  }
}

export default CheckPositionMoseToScene;