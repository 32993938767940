import React from 'react'
import { NavLink } from 'react-router-dom'
import Container from '../Container'
import ExempleUseForLanding from '../../assets/images/exempleUseForLanding.svg'


const WhomIsItSuitable = (props) => {
  const whomIsItSuitableData = [
    {
      image: ExempleUseForLanding,
      title: "Предприятия: производство и эксплуатация",
      link: "#",
      variants: [
        {
          title: "Моделировать различные режимы работы проектируемого и эксплуатируемого оборудования."
        },
        {
          title: "Диагностировать различные варианты полных и частичных отказов."
        },
        {
          title: "Учитывать воздействия окружающей среды и степень износа деталей."
        }
      ]
    },
    {
      image: ExempleUseForLanding,
      title: "Образовательные учреждения: университеты, колледжи, школы",
      link: "#",
      variants: [
        {
          title: "Проводить комплексные лабораторные работы."
        },
        {
          title: "Наглядно демонстрировать школьникам и студентам основы физики."
        },
        {
          title: "Создавать цифровые двойники простыми программными средствами."
        }
      ]
    }
  ]
  return (
    <section className={"landing-whom_is_it_suitable"}>
      <Container>
        <div className="landing-whom_is_it_suitable-wrapper" id={"link-whom_is_it_suitable"}>
          <h3 className={"landing-whom_is_it_suitable-wrapper-title"}>Для кого подходит</h3>
          <div className={"landing-whom_is_it_suitable-wrapper-wrapper_exemple_use"}>
            {
              whomIsItSuitableData.map((el, i) => {
                return (
                  <div key={i} className={"landing-whom_is_it_suitable-wrapper-item"}>
                    <img
                      className={"landing-whom_is_it_suitable-wrapper-item-img"}
                      src={el.image}
                      alt={el.title}
                    />
                    <div className="landing-whom_is_it_suitable-wrapper-item-content">
                      <div>
                        <h4 className={"landing-whom_is_it_suitable-wrapper-item-title"}>{el.title}</h4>
                        {
                        el.variants.map((elVariant, iVariant) => {
                        return (
                        <div
                        key={iVariant}
                        className={"landing-whom_is_it_suitable-wrapper-item-variant"}
                        >
                        <span>{elVariant.title}</span>
                        </div>
                        )
                        })
                        }
                      </div>
                      <NavLink
                        className={"landing-whom_is_it_suitable-wrapper-item-link"}
                        to={el.link}
                      >Подробнее</NavLink>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

      </Container>
    </section>
  )
}

export default WhomIsItSuitable