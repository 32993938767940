import React from "react";
import { AbstractGLTF } from "../../../abstract/Objects/GLTF";
import AbstractObject from "../../abstract/AbstractObject";

class GLTF extends AbstractObject {
  constructor(props) {
    super();
    this.mixer = null;
    this.currentAnimation = null;
    this.clips = [];
    this.state = {
      objectScene: null,
    };
  }

  getClipByName = (clipName) => {
    return this.clips.filter((el) => {
      return el.name === clipName;
    })[0];
  };

  setAnimation = (animation) => {
    if (!animation) return;
    const { clipName } = animation;
    if (!clipName) return;
    const clip = this.getClipByName(clipName);
    if (clip) {
      this.mixer.stopAllAction();
      this.mixer.clipAction(clip).play();
    }
  };

  componentWillUnmount() {
    const { objectScene } = this.state;
    // objectScene.removeObject(objectScene.parent);
  }

  componentDidMount() {
    const {
      scene,
      addRenderCall,
      enableShadows,
      renderer,
      url = null, // required!!!
      position = [0, 0, 0],
      rotation = [0, 0, 0],
      quaternion = false,
      scale = [1, 1, 1],
      pivot = false,
      onLoadComplete = null,
      animation = null,
      visible = true,
      name,
      callback,
      parent,
      startLoadGLTF,
      progressLoadGLTF,
      errorLoadGLTF,
    } = this.props;
    this.initComponent();
    let paramsToGLTF = {
      scene,
      renderer,
      addRenderCall,
      enableShadows,
      url,
      position,
      rotation,
      quaternion,
      scale,
      onLoadComplete,
      animation,
      visible,
      pivot,
      name,
      setAnimation: this.setAnimation,
      readyComponent: this.readyComponent,
      callback,
      parent,
      startLoadGLTF,
      progressLoadGLTF,
      errorLoadGLTF,
    };
    let objectScene = new AbstractGLTF(paramsToGLTF);
    this.setState({
      objectScene: objectScene,
    });
  }

  shouldComponentUpdate(nextProps) {
    const { animation, selectedMaterial = null } = this.props;
    // console.log('nextProps', nextProps);
    if (nextProps.animation && nextProps.animation.clipName) {
      if (!animation || animation.clipName !== nextProps.animation.clipName) {
        this.setAnimation({
          clipName: nextProps.animation.clipName,
        });
      }
    }
    this.onPropsUpdate(this.props, nextProps);
    return true;
  }
}

export default GLTF;
