export default {
  created_at: null,
  processLoadDevice: false,
  devices: {
    wiresData: [],
    componentData: [],
    couplingSleeve: [],
  },
  isUpdateDeviceComponent: false,
  socketErrorCounter: 0,
  loader: false,
  typeMenu: 'classic',
  id: null,
  link: null,
  map: null,
  size: null,
  title: null,
  type_name: null,
  extension: null,
  projectName: "Виртуальный лабораторный стенд /",
  target: null,
  content: null,
  showContextMenu: false,
  addClassNameDropDownWrap: "",
  showConfirmModal: false,
  showSuccessInfoModal: false,
  isSaveScene: false,
  dataSceneSaving: null,
  dropMenuVls: false,
  contentSidebarMenu: ['Файл', 'Параметры', 'Вид', 'Построение', 'Трангуляция', 'Расчет', 'Обработка', 'Помощь'],
  paramsSuccessInfoModal: {
    text: null,
    icon: null,
    altForIcon: "",
  },
  paramsConfirmWarningOrSuccessModal: {
    text: null,
    icon: null,
    altForIcon: "",
    callbackOk: (e) => console.log("method is not found"),
  },
  typeSuccessInfoModal: {
    default: "default",
    error: "error"
  },
  typeConfirmWarningModal: {
    save: "save",
    saveAndClose: "saveAndClose",
    addPole: "addPole",
  },
}