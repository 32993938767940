import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Container from "../Container";
import Button from "../../Views/Layout/Button";
import { PATH } from "../../const";

const LandingHeader = (props) => {
  let clientWidth = document.documentElement.clientWidth;
  let [minMenu, setMinMenu] = useState(false);
  let [openMenu, setOpenMenu] = useState(false);
  const clickMinMenu = (e) => {
    setOpenMenu(!openMenu);
  };
  if (clientWidth <= 768) {
    if (!minMenu) setMinMenu(true);
  } else {
    if (minMenu) setMinMenu(false);
  }
  window.addEventListener("resize", (e) => {
    if (e.target.innerWidth <= 768) {
      if (!minMenu) setMinMenu(true);
    } else {
      if (minMenu) setMinMenu(false);
      if (openMenu) setOpenMenu(false);
    }
  })
  return (
    <header className={"landing-header"}>
      <Container>
        <div className="landing-header-wrapper">
          <div className={"landing-header-wrapper-logo"}></div>
          {!minMenu ? (
            <React.Fragment>
              <a href="tel:88002345056" className={"landing-header-wrapper-phone"}>
                8 800 234-50-56
              </a>
              <NavLink
                to={PATH.Authorization}
                className={"landing-header-wrapper-btn landing-header-wrapper-btn-sign_in"}
              >
                Вход
              </NavLink>
              <NavLink
                to={PATH.Registration}
                className={"landing-header-wrapper-btn landing-header-wrapper-btn-registrations"}
              >
                Регистрация
              </NavLink>
            </React.Fragment>
          ) : (
            <div>
              <div
                onClick={clickMinMenu}
                className={"landing-header-wrapper-menu"}
              >
              </div>
            </div>
          )}
          
        </div>
        
      </Container>
      {openMenu ? (
        <React.Fragment>
          <div className={"min_menu-wrapper"}>
            {/* <div
              onClick={clickMinMenu}
              className={"min_menu-wrapper-close_btn"}
            ></div> */}
            <React.Fragment>
              <a href="tel:88002345056" className={"landing-header-wrapper-phone"}>
                8 800 234-50-56
              </a>
              <div className={"landing-header-wrapper-btns"}>
                <NavLink
                  to={PATH.Authorization}
                  className={"landing-header-wrapper-btn landing-header-wrapper-btn-sign_in"}
                >
                  Вход
                </NavLink>
                <NavLink
                  to={PATH.Registration}
                  className={"landing-header-wrapper-btn landing-header-wrapper-btn-registrations"}
                >
                  Регистрация
                </NavLink>
              </div>
            </React.Fragment>
          </div>
          {/* <div onClick={clickMinMenu} className={"min_menu-closed"}></div> */}
        </React.Fragment>
      ) : null}
    </header>
  );
};

export default LandingHeader;
