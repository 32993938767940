import React, { useState, useRef } from "react";
import ToolTipCreator from '../ToolTipCreator'
import Api from '../../api'
import { PATH } from '../../const'
import ContentForContextMenuProjects from '../ContentForContextMenuProjects'
import ContentForContextMenuTrash from '../ContentForContextMenuTrash'

const ApiProject = Api.ProjectApi
const ProjectFolderRenderGrid = (props) => {
  const [target, setTarget] = useState(null)
  const [changeName, setChangeName] = useState(false)
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contentForModal, setContentForModal] = useState(<></>)
  const targetRef = useRef(null);
  const containerRef = useRef(null);
  const refTextareaChangeName = useRef(null)
  const dataProject = props.projects;


  const restoreElement = (id) => {
    fadeOutContextMenu()
    ApiProject.pathProject(id, { is_trash: false }).then(res => {
      //todo: add notification
      if (props.updateGetProjects) {
        props.updateGetProjects()
      }
      props.isRerenderProjects()
    })
  }
  const fullDeliteElement = (id) => {
    fadeOutContextMenu()
    ApiProject.deleteProject(id).then(res => {
      //todo: add notification
      if (props.updateGetProjects) {
        props.updateGetProjects()
      }
      props.isRerenderProjects()
    })
  }
  const deleteElement = (id) => {
    fadeOutContextMenu()
    ApiProject.pathProject(id, { is_trash: true }).then(res => {
      //todo: add notification
      if (props.updateGetProjects) {
        props.updateGetProjects()
      }
      props.isRerenderProjects()
    })
  }
  const addFavorite = (id) => {
    fadeOutContextMenu()
    ApiProject.pathProject(id, { favorites: true }).then(res => {
      //todo: add notification
      if (props.updateGetProjects) {
        props.updateGetProjects()
      }
    })
  }

  const contentForContextMenu = () => {
    const { id } = props.projects
    return (
      props.typePage !== 'trash' ? (
        <ContentForContextMenuProjects
          id={id}
          addFavorite={addFavorite}
          changeNameHandler={changeNameHandler}
          fadeOutContextMenu={fadeOutContextMenu}
          deliteElement={deleteElement}
        />
      ) : <ContentForContextMenuTrash
          id={id}
          restoreElement={restoreElement}
          fullDeliteElement={fullDeliteElement}
        />
    )
  }
  const doubleClickEvent = (e) => {
    const { id } = props.projects
    window.location.href = `${PATH.DetailsProjectFormPersonalPage}${id}`
  }
  const blurChangeNameInput = (e) => {
    setChangeName(false)
    props.changeNameProject(e)
  }
  const changeNameHandler = (e) => {
    setChangeName(true)
    refTextareaChangeName.current.focus();
  }
  const fadeOutContextMenu = (e) => {
    setTarget(null);
    setContentForModal(<></>)
    setShowContextMenu(false);
  };
  return (
    <div
      ref={containerRef}
      className={`folder-wrapper ${dataProject.isEmpty ? "filled" : "empty"}`}
      onDoubleClick={doubleClickEvent}
    >
      <button
        ref={targetRef}
        className={`folder-context_menu`}
        onClick={(e) => {
          setTarget(e.target);
          setContentForModal(() => contentForContextMenu())
          setShowContextMenu(!showContextMenu);
        }}
      ></button>
      <ToolTipCreator
        show={showContextMenu}
        typeClosed={'click'}
        fadeOutContextMenu={fadeOutContextMenu}
        target={{ current: target }}
        contentForModal={contentForModal}
      />
      {dataProject.favorites ? <div className={`folder-star`}></div> : null}
      <div className={`folder-name`}>
        <textarea
          id={dataProject.id}
          readOnly={!changeName}
          ref={refTextareaChangeName}
          onChange={props.changeNameProject}
          defaultValue={dataProject.name}
          className={`folder-name-textarea  ${changeName ? "active" : ""}`}
          onBlur={blurChangeNameInput}
        />
      </div>
      <div className={`folder-date`}>{dataProject.date}</div>
    </div >
  );
};
export default ProjectFolderRenderGrid;
