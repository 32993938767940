import React from 'react'
import ProjectToPersonalPage from '../ProjectToPersonalPage'
import { LIMIT_GET_PROJECTS } from '../../const';
import Api from '../../api'

const ApiProject = Api.ProjectApi
class FavoriteProject extends React.Component{
  constructor(props){
    super(props)
  }

  getProjects = () =>{
    return ApiProject.getProjects({limite: LIMIT_GET_PROJECTS ,is_favorite: 'True', is_trash: false})
  }

  render(){
    let { updatecomponent, stopupdate } = this.props
    updatecomponent = updatecomponent === "true"
    return(
      <>
        <ProjectToPersonalPage
          typePage={'favorite'}
          title={'ИЗБРАННОЕ'}
          api={this.getProjects}
          updateComponent={updatecomponent}
          stopUpdate={stopupdate}
          limite={LIMIT_GET_PROJECTS}
        />
      </>
    )
  }
}



export default FavoriteProject