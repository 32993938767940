import React from 'react'
import ParamsDeviceValue from '../../Views/Layout/ParamsDeviceValue';
import DeviceSetting from '../../Views/Layout/DeviceSetting'
import { Tabs as RBTabs, Tab } from 'react-bootstrap'
import uuid from "uuid";
import isEqual from 'react-fast-compare';
import SubmitForm from '../../common/SubmitForm'
import Api from '../../api'
import v4 from 'uuid/v4';


const ApiResearch = Api.ResearchApi
class MeasuringDeviceComponentForSidebar extends React.Component {
  constructor(props) {
    super()
    this.state = {
      activeTabKey: "base",
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Имя', value: 9.999999999999999e+42 },
        { id: v4(), nameOfValue: 'Max. значение', value: 9.999999999999999e+42, },
      ],
      paramsDeviceCustomMeta: {
        titleClassName: "vls-sidebar-title_device_meta_data",
        valueRadioButton: [
          {
            titleClassName: "vls-sidebar-title_device_setings",
            title: 'Масштаб',
            options: [
              { id: uuid.v4(), name: 'мили', active: false },
              { id: uuid.v4(), name: '1:1', active: false },
              { id: uuid.v4(), name: 'кило', active: true }
            ]
          },
          {
            titleClassName: "vls-sidebar-title_device_setings",
            title: 'Род Тока',
            options: [
              { id: uuid.v4(), name: 'Постоянный(=)', active: false },
              { id: uuid.v4(), name: 'Переменный(≈)', active: true }
            ]
          }
        ],
        tabs: [{ name: 'Амперметр', active: false }, { name: 'Вольтметр', active: false }, { name: 'Ваттметр', active: true }],
      }
    }
  }

  submitData = () => {
    let { hash_id, type } = this.props.element
    const { paramsDeviceCustomMeta, paramsDeviceDataNamefields } = this.state
    if (!hash_id) hash_id = this.props.element.id
    let params = {
      device_id: hash_id,
      params: {
        type: type,
        meta: {
          defaultSettings: {
            name: paramsDeviceDataNamefields[0].value,
            maxValue: paramsDeviceDataNamefields[1].value
          },
          deviceSettings: {
            scale: paramsDeviceCustomMeta.valueRadioButton[0].options,
            kindOfCurrent: paramsDeviceCustomMeta.valueRadioButton[1].options
          }
        }
      }
    }
    SubmitForm(ApiResearch.putResearchUpdateDeviceData, params, this.successSubmit)
  }

  successSubmit = (response) => {
    console.log(response)
  }

  getParamsForSubmit = (key, searcheKey, data) => {
    let result = null
    data.forEach((el) => {
      if (el[key] === key) result = el.value
    })
    return result
  }

  defaultDeviceDataChange = (e) => {
    const { paramsDeviceDataNamefields } = this.state
    let resultsUpdate = []
    for (let i = 0; i < paramsDeviceDataNamefields.length; i++) {
      const element = paramsDeviceDataNamefields[i];
      if (element.id === e.target.id) {
        resultsUpdate.push({
          ...element,
          value: e.target.value
        })
      } else {
        resultsUpdate.push(element)
      }
    }
    let newData = {
      paramsDeviceDataNamefields: resultsUpdate
    }
    this.updateDeviceData(newData)
  }


  updateDeviceData = (data) => {
    this.setState(data, this.submitData)
  }

  selectTab = (e) => {
    this.setState({
      activeTabKey: e,
    })
  }

  tabsHandlerForSettingsDevice = (e) => {
    // const { paramsDeviceCustomMeta } = this.state
    // const setTabsData = () => {
    //   return paramsDeviceCustomMeta.tabs.map((el, i) => {
    //     if (el.name === e.target.outerText) {
    //       return {
    //         ...el,
    //         active: true
    //       }
    //     } else {
    //       return {
    //         ...el,
    //         active: false
    //       }
    //     }
    //   })
    // }
    // this.setState({
    //   paramsDeviceCustomMeta: {
    //     ...paramsDeviceCustomMeta,
    //     tabs: setTabsData()
    //   }
    // })
  }

  handlerChooseRadioButton = (e) => {
    const { paramsDeviceCustomMeta } = this.state
    const setvalueRadioButtonData = () => {
      return paramsDeviceCustomMeta.valueRadioButton.map((el, i) => {
        let changeCkeck = false
        let result = el.options.map((elOption, i) => {
          if (elOption.id === e.target.id) {
            changeCkeck = true
            return {
              ...elOption,
              active: true,
              isActive: true
            }
          } else {
            return {
              ...elOption,
              active: false,
              isActive: false
            }
          }
        })
        return {
          ...el,
          options: changeCkeck ? result : el.options
        }

      })
    }

    let newData = {
      paramsDeviceCustomMeta: {
        ...paramsDeviceCustomMeta,
        valueRadioButton: setvalueRadioButtonData()
      }
    }
    this.updateDeviceData(newData)
  }

  setDataDevice = (props) => {
    const { element } = props
    const { defaultSettings, deviceSettings } = element.meta
    this.setState({
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Имя', value: defaultSettings.name },
        { id: v4(), nameOfValue: 'Max. значение', value: defaultSettings.maxValue, },
      ],
      paramsDeviceCustomMeta: {
        titleClassName: "vls-sidebar-title_device_meta_data",
        valueRadioButton: [
          {
            titleClassName: "vls-sidebar-title_device_setings",
            title: 'Масштаб',
            options: deviceSettings.scale
          },
          {
            titleClassName: "vls-sidebar-title_device_setings",
            title: 'Род Тока',
            options: deviceSettings.kindOfCurrent
          }
        ],
        tabs: [
          {
            name: 'Амперметр',
            active: element.type === "ampermeter"
          }, {
            name: 'Вольтметр',
            active: element.type === "voltmeter"
          }, {
            name: 'Ваттметр',
            active: element.type === "watmeter"
          }
        ],
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.element, prevProps.element)) {
      this.setDataDevice(this.props)
    }
    if (!isEqual(prevState, this.state)) {
      return true
    }
  }


  componentDidMount() {
    this.setDataDevice(this.props)
  }

  render() {
    const {
      activeTabKey,
      paramsDeviceDataNamefields,
      paramsDeviceCustomMeta
    } = this.state
    return (
      <React.Fragment>
        <span className={"vls-sidebar-device_title"}>ИЗМЕРИТЕЛЬНЫЙ ПРИБОР</span>
        <RBTabs
          id="vls-sidebar_tabs_wraper"
          defaultActiveKey={'base'}
          activeKey={activeTabKey}
          onSelect={this.selectTab}
        >
          <Tab tabClassName={"vls-sidebar_tabs_wraper-tab"} eventKey="base" title="Параметры">
            <ParamsDeviceValue onChange={this.defaultDeviceDataChange} classNameForInput={"vls-sidebar_tabs_wraper-tab-input"} data={paramsDeviceDataNamefields} />
            <DeviceSetting handlerChooseRadioButton={this.handlerChooseRadioButton} tabsHandler={this.tabsHandlerForSettingsDevice} data={paramsDeviceCustomMeta} />
          </Tab>
        </RBTabs>
      </React.Fragment>
    )
  }
}
export default MeasuringDeviceComponentForSidebar