
export const PATH = {
  MainPage: "/",
  Home: "/home/",
  Faq: "/FAQ/",
  NewsDetailsInHomePage: "/news-details/",
  VirtualLibraryStand: "/personal-page/virtual-library-stand/",
  ForgotPassword: "/forgot-password/",
  Registration: "/registration/",
  Authorization: "/authorization/",
  DetailsProjectFormPersonalPage: "/personal-page/project-details/",
  PersonalPageAllProject: "/personal-page/all-projects/",
  PersonalPageMyProject: "/personal-page/my-projects/",
  PersonalPageSearch: "/personal-page/search/",
  PersonalPagePersonalArea: "/personal-page/personal-area/setting-data",
  PersonalPageFavoriteProject: "/personal-page/favorite/",
  PersonalPageBagTrash: "/personal-page/bag-trash/",
  PersonalPagePersonalAreaSettingProfile: "/personal-page/personal-area/setting-data/",
  PersonalPageSettingPage: "/personal-page/setting/",
  PersonalPagePersonalAreaMyRate: "/personal-page/personal-area/my-rate/",
  PhysicalFieldModelingLibrary: "/personal-page/physical-field-modeling-library/",
  ERROR500: "/500/",

};
export const COLOR_FOR_CHARTS = {
  colorForChartsLight: '#E8E8E8',
  colorForChartsDark: '#25282B',
  colorForChartsGridLine: '#A1ACBD',
  colorForChartsBackgroundLight: '#535353',
  colorForChartsBackgroundDark: '#FDFDFF',
  colorForChartsDataLine: '#4A9EEA',
};
export const LIMIT_GET_PROJECTS = 80;

export const BASE_URL = process.env.REACT_APP_BASE_URL_FOR_API;
export const BASE_URI_FOR_SOCKET = process.env.REACT_APP_BASE_URI_FOR_SOCKET
console.log(process.env)


export const PAGE_SIZE = 9;

export const DATA_OBJECT_SCENE = {
  ARC_FRAGMETS: {
    name: "arc_fragmets"
  },
  PRIMITIVE_LINE_CHECKED: {
    name: "primitive_line_checked_pfml"
  },
  PRIMITIVE_DOT_APROXIMATION: {
    name: "primitive_dot_aproximation_pfml"
  },
  PRIMITIVE_LINE: {
    name: "primitive_line_pfml"
  },
  line_direction_for_create_arc: {
    name: "line_direction_for_create_arc"
  },
  temporary_line: {
    name: "temporary_line"
  },
  clicked_object: {
    name: "clicked_object"
  },
  straightGroup: {
    name: "straight_group"
  },
  dotGroup: {
    name: "dot_group"
  },
  body_device: {
    name: "body_device"
  },
  ShaderSelectedObject: {
    name: "ShaderSelectedObject",
    type: "ShaderSelectedObject"
  },
  couplingSleeveDAD: {
    name: "couplingSleeveDAD",
    type: "couplingSleeve"
  },
  couplingSleeveMUM: {
    name: "couplingSleeveMUM",
    type: "couplingSleeve"
  },
  deviceConnector: {
    name: "DeviceConnector",
  },
  transformControler: {
    name: "transformControler",
  },
  dragControls: {
    name: "DragControls",
  },
  line: {
    name: "line",
  },
  connector: {
    name: "connector",
    type: "wire",
  },
  deviceConnector: {
    name: "device input",
    type: "box",
  },
  outerShaft: {
    name: "outer_shaft"
  },
  objectConnector: {
    name: "outer_terminal",
  },
  GroupDotsPFML: {
    name: "GroupDotsPFML",
  },
};

export const allTypeDevice = [
  "voltmeter",
  "ampermeter",
  "watmeter",
  "oscilograf",
  "reostat",
  "rezistor",
  "capacitor",
  "inductor",
  "oneComponentVendingMachine",
  "twoComponentVendingMachine",
  "threeComponentVendingMachine",
  "circuitBreaker",
  "terminal",
  "elementEarth",
  "constantCurrentMachine",
  "asyncMachine",
  "couplingSleeve",
  "couplingSleeve",
  "onePhaseTransformer",
  "twoPhaseTransformer",
  "threePhaseTransformer",
  "thermistor",
  "mechanicalLoad"
]