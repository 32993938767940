import React from "react";
import LoaderSpiner from '../../common/LoaderSpiner'
import ControlsViewGridProjects from "../../common/ControlsViewGridProjects";
import connect from 'storeon/react/connect'
import Api from '../../api'
import ProjectFolderRender from "../../common/ProjectFolderRender";



const ApiProject = Api.ProjectApi
class ProjectToPersonalPage extends React.Component {
  constructor(props) {
    super(props);
    this.viewMod = localStorage.getItem("folderDisplayMode")
    this.state = {
      resultResponse: false,
      typeViewContent: this.viewMod ? this.viewMod : "grid",
      offsetProjects: 0,
      isRerenderProjects: false,
      changeProjectBaseInfo: {
        id: null,
        isOnBlur: false,
      },
    };
  }

  happendRerenderProjects = (isHappenedRender) => {
    this.setState({ isRerenderProjects: !isHappenedRender })
  }

  concatProjectForLazyLoad = (prevResultResponse, params = {}) => {
    ApiProject.getProjects(params).then(res => {
      this.setState({
        resultResponse: prevResultResponse.concat(res.results)

      })
    })
  }
  checkForLazyLoad = (event) => {
    if (this.state.resultResponse.length < this.state.offsetProjects) {
      return false
    } else {
      if (Math.floor(event.scrollHeight - event.scrollTop) <= event.clientHeight) {
        if (this.props.typePage === 'search') {
          this.concatProjectForLazyLoad(
            this.state.resultResponse,
            {
              limit: this.props.limit,
              offset: this.state.offsetProjects,
              search: this.props.searchWords,
              is_trash: false,
            }
          )
        } else {
          this.concatProjectForLazyLoad(
            this.state.resultResponse,
            {
              limit: this.props.limit,
              offset: this.state.offsetProjects,
              is_trash: false,
            }
          )
        }
        this.setState({ offsetProjects: this.state.offsetProjects + this.props.limit })
      }
    }
  }

  changeNameProject = (e) => {
    e.persist()
    const { id, isOnBlur } = this.state.changeProjectBaseInfo
    const target = e.target
    if (target)
      if (!isOnBlur) {
        this.setState({
          changeProjectBaseInfo: {
            ...this.state.changeProjectBaseInfo,
            isOnBlur: true,
            id: target.id
          }
        }, () => {
          target.addEventListener('blur', (e) => this.onBlurTextAreaNameProject(e))
        })
      }
  }

  onBlurTextAreaNameProject = (e) => {
    let target = e.target
    let params = {
      title: target.value
    }
    ApiProject.pathProject(target.id, params).then((res) => {
      this.setState({
        changeProjectBaseInfo: {
          ...this.state.changeProjectBaseInfo,
          isOnBlur: false,
          id: null
        }
      })
    })
    target.removeEventListener('blur', this.onBlurTextAreaNameProject)
  }

  getProjectData = (params = {}) => {
    const { api } = this.props
    this.setState({
      resultResponse: false
    }, () => {
      api(params).then(res => {

        this.setState({
          resultResponse: res.results
        })
        if (this.props.typePage === 'search') {
          this.props.getCountProject(res.count)
        }
      })
    })
  }

  setViewContent = (newView) => {
    localStorage.setItem("folderDisplayMode", newView)
    this.setState({
      typeViewContent: newView,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updateComponent !== prevProps.updateComponent) {
      this.props.stopUpdate()
    }
    if (this.state.isRerenderProjects) {
      this.setState({
        offsetProjects: this.props.limit,
        isRerenderProjects: false,
      })
    }
    if (this.props.typePage === 'search' && this.props.searchWords !== prevProps.searchWords) {
      this.setState({ offsetProjects: this.props.limit }, () => {
        this.getProjectData()
      })
    }
    return true
  }
  componentDidMount() {
    this.setState({
      offsetProjects: this.props.limit
    }, () => {
      this.getProjectData()
    })
  }
  render() {
    const { title = '', updateComponent, typePage = 'AllProject' } = this.props
    const { resultResponse, typeViewContent, isRerenderProjects } = this.state;
    if (updateComponent) {
      this.getProjectData()
    }
    if (!resultResponse) {
      return <LoaderSpiner />
    }
    console.log('typeViewContent', typeViewContent)
    return (
      <React.Fragment>
        <div className={`personal_page-content-wrapper`}>
          <p className={`personal_page-content-heading`}>{title}</p>
          {typePage !== 'search' ? (
            <ControlsViewGridProjects
              typeViewContent={typeViewContent}
              setFilters={this.getProjectData}
              isRerenderProjects={() => this.happendRerenderProjects(isRerenderProjects)}
              setViewContent={this.setViewContent}
            />
          ) : false
          }
          <ProjectFolderRender
            customScroll={(e) => {
              e.stopPropagation()
              e.preventDefault()
              this.checkForLazyLoad(e.target)
            }}
            typePage={typePage}
            isRerenderProjects={() => this.happendRerenderProjects(isRerenderProjects)}
            onBlurTextAreaNameProject={this.onBlurTextAreaNameProject}
            changeNameProject={this.changeNameProject}
            updateGetProjects={this.getProjectData}
            resultResponse={resultResponse}
            typeViewContent={typeViewContent}
          />
        </div>
      </React.Fragment>
    )
  }

}

export default connect('ProjectTheme', ProjectToPersonalPage);
