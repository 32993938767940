import React from "react";
// import { Icon, Menu } from "antd";
import {
  Form,
  FormControl,
  Button,
  NavLink as NavLinkBootstrap,
  Nav,
  Dropdown,
  Navbar,
} from "react-bootstrap";
import { VirtualLibraryStandPage } from "../../pages";
import { PATH } from "../../const";
import { NavLink } from "react-router-dom";
import connect from 'storeon/react/connect'
// import Logo from '../../assets/images/logo.png'
class Header extends React.Component {
  state = {
    current: "mail",
    theme: "dark",
  };

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };

  outIn = (e) => {
    localStorage.removeItem("auth_token");
    window.location.href = PATH.Home;
  };
  
  render() {
    let theme = this.props.ProjectTheme === 'light' ? 'light-header' : '';
    return (
      <Navbar
        className={`header-person_page ${theme}`}
        expand="lg"
      >
        <Navbar.Brand href="#home" className={`header-navbar_brand`}>
          <div className={`d-inline-block align-top header-navbar_brand-logo`}></div>{" "}
          GARPIX DIGITAL TWIN
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        {/* <Navbar.Collapse id="basic-navbar-nav"> */}
          <div inline="true">
            <Button
              onClick={this.outIn}
              variant="default"
              className={`header-navigations-outin`}
            ></Button>
          </div>
        {/* </Navbar.Collapse> */}
      </Navbar>
    );
  }
}


export default connect('ProjectTheme', Header);
