import React from "react";
import ResearchEnvironment from "../ResearchEnvironment";
import DotDataSidebar from '../DotDataSidebar'
import DefaultDataSidebar from '../DefaultDataSidebar'
import StraightSidebar from '../StraightSidebar'
import SimularitySidebar from '../SimularitySidebar'
import PrimitivesSidebar from '../PrimitivesSidebar'
import isEqual from 'react-fast-compare'
import connect from 'storeon/react/connect'

class SidebarPFML extends React.Component {
  constructor(props) {
    super();
    this.state = {
      contentRender: [],
      tableMechanicalCharacteristics: [
        { n: 1, one: '1', two: '1', tree: '1', four: '1', five: '1', six: '1', seven: '1', eight: '1', nine: '1', ten: '1', eleven: '1' },
        { n: 2, one: '1', two: '1', tree: '1', four: '1', five: '1', six: '1', seven: '1', eight: '1', nine: '1', ten: '1', eleven: '1' },
        { n: 3, one: '1', two: '1', tree: '1', four: '1', five: '1', six: '1', seven: '1', eight: '1', nine: '1', ten: '1', eleven: '1' },
        { n: 4, one: '1', two: '1', tree: '1', four: '1', five: '1', six: '1', seven: '1', eight: '1', nine: '1', ten: '1', eleven: '1' },
        { n: 5, one: '1', two: '1', tree: '1', four: '1', five: '1', six: '1', seven: '1', eight: '1', nine: '1', ten: '1', eleven: '1' },
        { n: 6, one: '1', two: '1', tree: '1', four: '1', five: '1', six: '1', seven: '1', eight: '1', nine: '1', ten: '1', eleven: '1' },
        { n: 7, one: '1', two: '1', tree: '1', four: '1', five: '1', six: '1', seven: '1', eight: '1', nine: '1', ten: '1', eleven: '1' },
        { n: 8, one: '1', two: '1', tree: '1', four: '1', five: '1', six: '1', seven: '1', eight: '1', nine: '1', ten: '1', eleven: '1' },
        { n: 9, one: '1', two: '1', tree: '1', four: '1', five: '1', six: '1', seven: '1', eight: '1', nine: '1', ten: '1', eleven: '1' },
        { n: 10, one: '1', two: '1', tree: '1', four: '1', five: '1', six: '1', seven: '1', eight: '1', nine: '1', ten: '1', eleven: '1' },
        { n: 'МЭМ', one: '1', two: '1', tree: '1', four: '1', five: '1', six: '1', seven: '1', eight: '1', nine: '1', ten: '1', eleven: '1' },
      ]
    }
  }

  setContentRender = (data) => {
    this.setState({
      contentRender: [data]
    })
  }


  checkTool = (data) => {
    if (!data) return this.setContentRender(<DefaultDataSidebar />)
    console.log('data', data)
    switch (data.type) {
      case "dot":
        this.setContentRender(<DotDataSidebar element={data.element} />)
        break
      case "arc":
        this.setContentRender(<PrimitivesSidebar />)
        break
      case "straight":
        this.setContentRender(<StraightSidebar />)
        break
      case "simularity":
        this.setContentRender(<SimularitySidebar element={data.element} />)
      default:
        return
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (!isEqual(this.props.SelectedToolPFML, prevProps.SelectedToolPFML)) {
      this.checkTool(this.props.SelectedToolPFML)
      return true
    }
    return false
  }

  componentDidMount() {
    this.checkTool()
  }

  render() {
    const { contentRender } = this.state
    let theme = this.props.ProjectTheme === 'dark' ? '' : 'light'
    return (
      <div className={`sidebar ${theme}`}>
        {
          contentRender.map((el, i) => (
            <React.Fragment key={i}>
              {el}
            </React.Fragment>
          ))
        }
      </div>
    )
  }
}

export default connect("ProjectTheme", "SelectedToolPFML", SidebarPFML);
