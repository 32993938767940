import { Clock, Scene, WebGLRenderer } from 'three'
import StereoEffect from './StereoEffect'
import { QuadViewPorts } from './QuadViewPorts'
import { SCENE_NAME } from '../../constants'
import Stats from 'stats.js'

class Canvas {
  constructor({
    // requestId=null,
    canvasWidth = null,
    canvasHeight = null,
    renderCalls = [],
    canvasDomElement = null,
    clock = null,
    enableVR,
    enableQuadCamera,
    quadData,
    fullscreen = false,
    enableShadows = false,
    fps
  } = {}) {
    // this.fps = fps
    // this.stats = new Stats()
    // this.stats.dom.classList.add("tracker-fps")
    this.requestId = null
    this.enableVR = enableVR //activate VR mode
    this.enableQuadCamera = enableQuadCamera
    this.canvasWidth = canvasWidth
    this.canvasHeight = canvasHeight
    this.renderCalls = renderCalls
    this.canvasDomElement = canvasDomElement
    this.clock = clock
    this.fullscreen = fullscreen
    this.effect = null
    this.scene = new Scene()
    this.scene.name = SCENE_NAME
    this.renderer = new WebGLRenderer({
      canvas: this.canvasDomElement,
      powerPreference: 'high-performance',
      antialias: true,
      alpha: true,
      failIfMajorPerformanceCaveat: true
    })
    // this.renderer.gammaOutput = true
    // this.renderer.gammaOutput = true
    // this.renderer.gammaFactor = gammaFactor
    //!модификации для оптимизации
    this.renderer.autoClear = true
    this.renderer.autoClearColor = true
    this.renderer.autoClearDepth = true
    this.renderer.autoClearStencil = true
    this.renderer.PhysicallyCorrectLights = true

    this.renderer.shadowMap.enabled = enableShadows
    this.renderer.shadowCameraNear = 3
    this.renderer.shadowCameraFar = 1000
    this.renderer.shadowCameraFov = 50
    // this.renderer.vr.enabled = enableVR

    if (enableVR) {
      this.effect = new StereoEffect(this.renderer)
    }
    if (enableQuadCamera) {
      this.effect = new QuadViewPorts(this.renderer, canvasDomElement, quadData)
    }

    this.resize()
    window.addEventListener('resize', this.resize)
    this.getVRDisplay((display) => {
      this.renderer.xr.setDevice(display)
    })

    this.clock = new Clock()
    this.animate()
  }

  resize = () => {
    const {
      fullscreen = false,
    } = this;
    if (fullscreen) {
      this.canvasHeight = window.innerHeight;
      this.canvasWidth = window.innerWidth;
    } else {
      const parentElement = this.canvasDomElement.parentElement;
      if (parentElement) {
        this.canvasHeight = parentElement.clientHeight;
        this.canvasWidth = parentElement.clientWidth;
      }
    }

    if (this.enableVR) {
      this.effect.setSize(this.canvasWidth, this.canvasHeight)
    } else if (this.enableQuadCamera) {
      this.effect.setSize(this.canvasWidth, this.canvasHeight)
    } else {
      this.renderer.setSize(this.canvasWidth, this.canvasHeight)
    }
  }

  addRenderCall = (renderCall) => {
    this.renderCalls.push(renderCall)
  }

  // Основная функция отрисовки - берет все вызовы рендера в куче и рендерит их. работает только когда сцена готова
  animate = () => {
    // if (this.fps) {
    //   this.stats.showPanel(0);// 0: fps, 1: ms, 2: mb, 3+: custom
    //   this.canvasDomElement.parentNode.appendChild(this.stats.dom);
    // }
    this.requestId = this.renderer.setAnimationLoop(this.animate)
    const deltaSeconds = this.clock.getDelta()
    this.renderCalls.forEach((callback) => {
      // if (this.fps) this.stats.begin();
      callback(deltaSeconds)
      // if (this.fps) this.stats.end();
    })
  }

  getVRDisplay = (onDisplay) => {
    if ('getVRDisplays' in navigator) {
      navigator.getVRDisplays()
        .then((displays) => {
          onDisplay(displays[0])
        })
    }
  }
}

export default Canvas