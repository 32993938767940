import React from 'react'
import ParamsDeviceValue from '../../Views/Layout/ParamsDeviceValue';
import ButtonMain from "../../Views/Layout/ButtonMain";
import { v4 } from 'uuid'
import connect from 'storeon/react/connect'
import  isEqual  from 'react-fast-compare';

class DotDataSidebar extends React.Component {
	constructor(props) {
		super()
		this.state = {
			paramsDeviceDataNamefields: [
				{ id: v4(), nameOfValue: 'ID = ', units: `    `, value: 0 },
				{ id: v4(), nameOfValue: 'Xр = ', units: "(мм)", value: 0, },
				{ id: v4(), nameOfValue: 'Yр = ', units: "(мм)", value: 0, },
				{ id: v4(), nameOfValue: 'Rр = ', units: "(мм)", value: 5, },
			]
		}
	}

	updateStor = () => {
		const { paramsDeviceDataNamefields } = this.state
		this.props.dispatch("DotData", {
			id: paramsDeviceDataNamefields[0].value,
			x: paramsDeviceDataNamefields[1].value,
			y: paramsDeviceDataNamefields[2].value,
			r: paramsDeviceDataNamefields[3].value,
		})
	}

	updateDeviceData = (data) => {
		this.setState(data, this.updateStor)
	}

	defaultDeviceDataChange = (e) => {
		const { paramsDeviceDataNamefields } = this.state
		let resultsUpdate = []
		for (let i = 0; i < paramsDeviceDataNamefields.length; i++) {
			const element = paramsDeviceDataNamefields[i];
			if (element.id === e.target.id) {
				resultsUpdate.push({
					...element,
					value: e.target.value
				})
			} else {
				resultsUpdate.push(element)
			}
		}
		let newData = {
			paramsDeviceDataNamefields: resultsUpdate
		}
		this.updateDeviceData(newData)
	}

	setData = (props) => {
		const { DotData } = props
		this.setState({
			paramsDeviceDataNamefields: [
				{ id: v4(), nameOfValue: 'ID = ', units: `    `, value: DotData.id },
				{ id: v4(), nameOfValue: 'Xр = ', units: "(мм)", value: DotData.x, },
				{ id: v4(), nameOfValue: 'Yр = ', units: "(мм)", value: DotData.y, },
				{ id: v4(), nameOfValue: 'Rр = ', units: "(мм)", value: DotData.r, },
			]
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(prevProps.DotData, this.props.DotData)) {
			this.setData(this.props)
		}
	}


	componentDidMount() {
		this.setData(this.props)
	}

	render() {
		const {
			paramsDeviceDataNamefields
		} = this.state
		return (
			<React.Fragment>
				<span className={"pfml-sidebar-device_title"}>ТОЧКА</span>
				<div className={"pfml-sidebar-dot-position_btn"}>
					<span>Координаты точки</span>
					<ButtonMain
						onClick={this.contentForModal}
						className={'button__option-menu'}
						disabled={true}
					>Сбросить</ButtonMain>
				</div>
				<ParamsDeviceValue
					onChange={this.defaultDeviceDataChange}
					classNameForInput={"pfml-sidebar-dot-input"}
					firstItemClassName={"custom_first_items_offset"}
					data={paramsDeviceDataNamefields}
				/>
			</React.Fragment>
		)
	}
}
export default connect("DotData", DotDataSidebar)