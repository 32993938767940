import React from "react";
import connect from "storeon/react/connect";
import GetElementsVLS from "./ElementsVLS";
import ElementToolPanelCanvasLayout from "../../Views/Layout/ElementToolPanelCanvasLayout";
import DataObjectScene from "./DataObjectScene";
import uuid from "uuid";
import Api from '../../api'


const ApiResearch = Api.ResearchApi
class ElementsToVLSPanel extends React.Component {
  constructor(props) {
    super(props);
    this.randPos = () => Math.random() * Math.random() * 100;
    this.randId = () => uuid.v4();
    this.socketStateEnum = {
      start: "start",
      stop: "stop",
      pause: "pause",
      play: "play"
    }
    this.state = {
      mounted: false,
      elementsPanelVLS: [],
      backOpenMenuIndex: null,
      isPlay: false,
      isPaused: false,
      socketState: this.socketStateEnum.stop
    };
  }
  /**
    * открывает менюшку если у инструмента на панели есть что открыть
    * @param { id: 1,
               menu: [],
               isView: false,
               position: [0, 0.5, 0],
               image: wire,
               type: "wire"
             } element объект для 1 инструмента меню
    * @param Number index 
    */
  openMenuElements = (element, index) => {
    const { backOpenMenuIndex, elementsPanelVLS } = this.state;
    let indexNew = index;

    if (backOpenMenuIndex !== null) {
      elementsPanelVLS[backOpenMenuIndex].isView = !elementsPanelVLS[
        backOpenMenuIndex
      ].isView;
      if (backOpenMenuIndex === indexNew) indexNew = null;
    }
    if (indexNew !== null)
      elementsPanelVLS[indexNew].isView = !elementsPanelVLS[indexNew].isView;

    this.setState({
      elementsPanelVLS: elementsPanelVLS,
      backOpenMenuIndex: indexNew,
    });
  };

  /**
   * закрывает меню на панели инструментов
   */
  closedMenuElements = () => {
    const { backOpenMenuIndex, elementsPanelVLS } = this.state;
    if (backOpenMenuIndex !== null) {
      elementsPanelVLS[backOpenMenuIndex].isView = !elementsPanelVLS[
        backOpenMenuIndex
      ].isView;
      this.setState({
        elementsPanelVLS: elementsPanelVLS,
        backOpenMenuIndex: null,
      });
    }
  };

  /*
    функция получит информацию об элементе по которому пользователь кликнул
    и сделает запрос за подробностями об этом элементе после чего запишет это в стор
    и на в кавасе произойдет апдейт
   */
  clickElementVLS = (params) => {
    //todo: имитируем запрос на бек за подробностями об элементе подумать над отправкой не только id в стор

    const { type } = params;
    let dataObject = null;
    const dispathCase = (type) => {
      let { id } = this.props.match.params
      let params = {
        device_type: type,
        type: type
      }
      // ApiResearch.addDeviceToScene(id, params)
      //   .then((res) => {
      //     dataObject = {
      //       ...res.device_data,
      //       id: res.device_id
      //     }
      //     this.props.dispatch("ElementsVLS", dataObject);
      //   })
      //   .catch((err) => {
      dataObject = DataObjectScene()[type];
      this.props.dispatch("ElementsVLS", dataObject);
      dataObject = DataObjectScene()[type];
      this.props.dispatch("ElementsVLS", dataObject);
      // })
      // dataObject = DataObjectScene()[type];
      // console.log(JSON.stringify(dataObject))
      // this.props.dispatch("ElementsVLS", dataObject);
    };
    switch (type) {
      case "wire":
        dispathCase(type);
        break;
      case "voltmeter":
        dispathCase(type);
        break;
      case "ampermeter":
        dispathCase(type);
        break;
      case "watmeter":
        dispathCase(type);
        break;
      case "oscilograf":
        dispathCase(type);
        break;
      case "voltageSource":
        dispathCase(type);
        break;
      case "oneComponentReostat":
        dispathCase(type);
        break;
      case "twoComponentReostat":
        dispathCase(type);
        break;
      case "threeComponentReostat":
        dispathCase(type);
        break;
      case "rezistor":
        dispathCase(type);
        break;
      case "capacitor":
        dispathCase(type);
        break;
      case "inductor":
        dispathCase(type);
        break;
      case "oneComponentVendingMachine":
        dispathCase(type);
        break;
      case "twoComponentVendingMachine":
        dispathCase(type);
        break;
      case "threeComponentVendingMachine":
        dispathCase(type);
        break;
      case "terminal":
        dispathCase(type);
        break;
      case "terminal_4":
        dispathCase(type);
        break;
      case "terminal_5":
        dispathCase(type);
        break;
      case "terminal_5":
        dispathCase(type);
        break;
      case "terminal_6":
        dispathCase(type);
        break;
      case "terminal_7":
        dispathCase(type);
        break;
      case "terminal_8":
        dispathCase(type);
        break;
      case "terminal_9":
        dispathCase(type);
        break;
      case "terminal_10":
        dispathCase(type);
        break;
      case "elementEarth":
        dispathCase(type);
        break;
      case "constantCurrentMachine":
        dispathCase(type);
        break;
      case "asyncMachine":
        dispathCase(type);
        break;
      case "dadCouplingSleeve":
        dispathCase(type);
        break;
      case "mumCouplingSleeve":
        dispathCase(type);
        break;
      case "onePhaseTransformer":
        dispathCase(type);
        break;
      case "twoPhaseTransformer":
        dispathCase(type);
        break;
      case "threePhaseTransformer":
        dispathCase(type);
        break;
      case "thermistor":
        dispathCase(type);
        break;
      case "mechanicalLoad":
        dispathCase(type);
        break;
      default:
        return;
    }
  };

  clickElement = (element, index) => {
    if (element.menu) {
      this.openMenuElements(element, index);
    } else {
      this.closedMenuElements();
      this.clickElementVLS(element);
    }
  };

  /**
   * запустит или остановит сокет
   * @param {} socketState 
   */
  socketStarter = (socketState) => {
    // debugger
    console.log(socketState)
    this.props.dispatch("StartOrStopVLS", {
      socketState: socketState
    })
  }

  PlayOrStop = () => {
    const { isPlay, isPaused } = this.state;
    this.setState({
      isPlay: !isPlay,
      socketState: isPlay ? this.socketStateEnum.stop : this.socketStateEnum.start,
      isPaused: !isPlay,
    });
  };

  PausedOrPlay = () => {
    const { isPaused, isPlay } = this.state;
    if (isPlay) {
      this.setState({
        isPaused: !isPaused,
        socketState: isPaused ? this.socketStateEnum.pause : this.socketStateEnum.play
      });
    }

  };

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.socketState !== this.state.socketState) {
      this.socketStarter(this.state.socketState)
    }
  }


  /*
    после прогрузки компоненты получаем список элементов для отображения (подумать над оптимизацией в виде бесконечной прогрузки)
    тк элементов будет очень много
  */
  componentDidMount() {
    // console.log(JSON.stringify(DataObjectScene()))
    this.setState({
      mounted: true,
      elementsPanelVLS: GetElementsVLS(),
    });
  }

  render() {
    const { processLoadDevice } = this.props
    const { elementsPanelVLS, isPlay, isPaused } = this.state;
    return (
      <ElementToolPanelCanvasLayout
        processLoadDevice={processLoadDevice}
        elementsPanel={elementsPanelVLS}
        isPlay={isPlay}
        isPaused={isPaused}
        PlayOrStop={this.PlayOrStop}
        PausedOrPlay={this.PausedOrPlay}
        clickElement={this.clickElement}
      />
    );
  }
}

export default connect("ElementsVLS", "StartOrStopVLS", ElementsToVLSPanel);
