import React from 'react'
import Container from '../Container'
import Screen from '../../assets/images/bmp_2_4.svg'

const FirstScreenSection = (props) => {
	return (
		<section className={"landing-first_screen_section"}>
			<Container>
				<div className="landing-first_screen_section-wrapper">
					<h1 className={"landing-first_screen_section-wrapper-title"}>GARPIX DIGITAL TWIN</h1>
					<span className={"landing-first_screen_section-wrapper-subtitle"}>
						Система имитационного моделирования для разработки
						и мониторинга электрооборудования и электросетей с
						помощью цифровых двойников
					</span>
					<div className={"landing-first_screen_section-wrapper-screen"}>
						<img
							className={"landing-first_screen_section-wrapper-screen-img"}
							src={Screen}
							alt={"screen"}
						/>
					</div>
				</div>
			</Container>
		</section>
	)
}

export default FirstScreenSection