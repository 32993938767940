import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import "./styles/scss/index.scss";
import 'bootstrap/dist/css/bootstrap.css';
import App from './App'
import * as serviceWorker from './serviceWorker'
import StoreContext from 'storeon/react/context'
import { store } from './store'


ReactDOM.render(
  <StoreContext.Provider value={store}>
    <Router>
      <App />
    </Router>
  </StoreContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
