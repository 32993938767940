import React from 'react'
import InputValueData from '../InputValueData'

const ParamsDeviceValue = (props) => {
  return (
    <>
      <div className='sidebar__options'>
        {props.data.map((item, index) => {
          return (
            <div key={index} className='sidebar__options__options-device'>
              <InputValueData
                firstItemClassName={props.firstItemClassName && index === 0 ? props.firstItemClassName : ""}
                className={props.classNameForInput ? `data ${props.classNameForInput}` : 'data'}
                defaultValue={item.value}
                firstDescription={item.nameOfValue}
                secondDescription={item.units}
                id={item.id}
                onChange={props.onChange}
              />
            </div>
          )
        })}

      </div>
      <div className='content-border-line'></div>
    </>
  )
}
export default ParamsDeviceValue