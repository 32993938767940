import React from "react";
import LandingHeader from "../../common/LandingHeader";
import FirstScreenSection from "../../common/FirstScreenSection";
import AboutServiceSection from "../../common/AboutServiceSection";
import WhomIsItSuitable from "../../common/WhomIsItSuitable";
import HowDoesItWork from "../../common/HowDoesItWork";
import FeaturesSection from "../../common/FeaturesSection";
import LandingFooter from "../../common/LandingFooter";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {});
  }

  render() {
    return (
      <main className="landing-main">
        <LandingHeader />
        <FirstScreenSection />
        <AboutServiceSection />
        <WhomIsItSuitable />
        <HowDoesItWork />
        <FeaturesSection />
        <LandingFooter />
      </main>
    );
  }
}

export default LandingPage;
