import React from 'react'
import { Canvas as CanvasAbstract } from '../../../abstract'

class Canvas extends React.Component {

  // Принимает следующее
  // onReady() - коллбек, когда все внешние данные загружены и можно рендерить сцену.

  constructor(props) {
    super()
    // this.requestId = null;
    this.effect = false
    this.enableVR = false
    this.enableQuadCamera = null
    this.quadData = null
    this.canvasWidth = null
    this.canvasHeight = null
    this.scene = null
    this.renderer = null
    this.addRenderCall = () => { }
    this.refCanvas = React.createRef()
    this.canvasDomElement = null
    this.components = new Map() // {name: 'example', obj: threejsObj, ready: false, onClick: null, onHover: null}
    this.debug = false
    // Object3D.DefaultUp = new Vector3(0,0,1);
    this.refDomElHud = React.createRef()
    this.state = {
      ready: false,
      renderHud: () => null
    }
  }

  // componentWillUnmount() {
  //     // drop all.
  // }

  getComponentByName = (name) => {
    return this.components.find((component, index) => {
      return component.name === name
    })
  }

  getComponentByUuid = (uuid) => {
    return this.components.get(uuid)
  }

  onLoadingProgress = () => {
    const { onLoadingProgress } = this.props
    if (onLoadingProgress) {
      const componentsCount = this.components.length
      const readyComponentsCount = this.components.filter((component) => { return component.ready }).length
      const progress = Math.round((readyComponentsCount * 100) / componentsCount)
      onLoadingProgress(progress)
    }
  }

  onComponentInit = ({
    name,
    obj,
    uuid,
    onClick = null,
    onHover = null,
    onBlur = null,
    instance,
  }) => {
    // console.log('onComponentInit', name);
    this.components.set(uuid, {
      name,
      uuid,
      obj,
      ready: false,
      onClick,
      onHover,
      onBlur,
      instance,
    })
    this.onLoadingProgress()
  }

  // Когда какой-то компонент загружен, то вызывается это событие
  onComponentReady = ({
    name,
    obj,
    uuid,
    instance,
  }) => {
    // console.log('onComponentReady', name);
    const component = this.getComponentByUuid(uuid)
    component.ready = true
    component.obj = obj
    component.uuid = uuid
    component.instance = instance
    this.onLoadingProgress()
  }

  // При монтировании компонента начинаем инициализировать сцену
  componentDidMount() {
    const {
      fullscreen = false,
      enableShadows = false,
      enableVR = false,
      enableQuadCamera = false,
      quadData = null,
      debug = false,
      gammaFactor = 1,
      getScene = null,
      fps = false
    } = this.props

    this.debug = debug
    this.canvasDomElement = this.refCanvas.current
    this.canvasHeight = this.canvasDomElement.parentElement.clientHeight
    this.canvasWidth = this.canvasDomElement.parentElement.clientWidth
    if (this.props.getSize) {
      this.props.getSize(this.canvasHeight, this.canvasWidth)
    }
    const canvas = new CanvasAbstract({
      fullscreen: fullscreen,
      canvasWidth: this.canvasWidth,
      canvasHeight: this.canvasHeight,
      canvasDomElement: this.canvasDomElement,
      enableVR,
      enableQuadCamera,
      quadData,
      enableShadows,
      gammaFactor,
      fps
    })
    this.scene = canvas.scene
    this.renderer = canvas.renderer
    this.enableVR = canvas.enableVR
    this.enableQuadCamera = canvas.enableQuadCamera
    this.effect = canvas.effect
    this.addRenderCall = canvas.addRenderCall
    if (getScene) {
      getScene(canvas.scene)
    }
    this.setState({
      ready: true,
    })
  }

  render() {
    const { ready } = this.state
    // если сцена еще не проинициализирована, то нельзя рендерить детей
    const childrenWithProps = ready ? React.Children.map(this.props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          scene: this.scene,
          enableVR: this.enableVR,
          enableQuadCamera: this.enableQuadCamera,
          renderer: this.renderer,
          effect: this.effect,
          canvasWidth: this.canvasWidth,
          canvasHeight: this.canvasHeight,
          addRenderCall: this.addRenderCall,
          canvasDomElement: this.canvasDomElement,
          onComponentInit: this.onComponentInit,
          onComponentReady: this.onComponentReady,
          getComponentByName: this.getComponentByName,
          getComponentByUuid: this.getComponentByUuid,
          enableShadows: this.enableShadows,
          debug: this.debug,
        })
      } else {
        return null
      }
    }) : null

    return (
      <canvas ref={this.refCanvas}>
        {childrenWithProps}
      </canvas>
    )
  }
}

export default Canvas
