import React from "react";
import api from "../../api";

const SubmitForm = (method, params, callBack = (e) => {}, error = (e) => {}, redirectTo = "#") => {
  method(params)
    .then((res) => {
      callBack(res);
      //todo: добавить нотифашки
    })
    .catch((err) => {
      error(err);
      console.log(err)
      //todo: добавить нотифашки
    });
};
export default SubmitForm;
