import React from "react";
import {
  AmbientLight,
  Canvas,
  DirectionalLight,
  Grid,
  PerspectiveCamera,
  OrthoganalControl,
  OrbitControls,
  Raycast,
  Box,
  DebugMode
} from "../../gengine";
// import ContextMenu from "../../common/ContextMenu";
import connect from "storeon/react/connect";
import DotCreator from "../CustomComponentToCanvas/DotCreator";
import StraightCreator from '../CustomComponentToCanvas/StraightCreator'
import CurveCreator from '../CustomComponentToCanvas/CurveCreator'
import CheckPositionMoseToScene from "../CustomComponentToCanvas/CheckPositionMoseToScene";
import { createArcPreStraight, createArcAllPoints } from '../CustomComponentToCanvas/MeshBuilder'
import SyncGeometry from '../CustomComponentToCanvas/SyncGeometry'
import serializers from '../../utils/SerializedObject'
import isEqual from "react-fast-compare";
import { checkDirectionForVector2, nugol, sercheAllObjectByProperty } from '../../utils'
import { v4 } from 'uuid'
import testData from './testData'
import { DATA_OBJECT_SCENE, PATH } from "../../const";
import { Vector2 } from "three";
import { checkConnectedDots } from './checkConnectedDots'
import { result } from "lodash";


class CanvasPFML extends React.Component {
  constructor(props) {
    super();
    this.toolEnum = {
      straight: "straight",
      dot: "dot",
      arc: "arc",
    }
    this.state = {
      heightCanvas: null,
      widthCanvas: null,
      tool: null,
      uslesCheckPosMouse: false,
      activeDot: null,
      activeDotForCreateStaight: [],
      oldActiveDotForCreateStaight: false,
      renderDots: testData.renderDots,
      renderStraight: [],
      renderArc: [],
      verticesForArc: [],
      dataFraments: {},
      straightCreatorFirst: false,
      positionDotMoveMouse: null,
      straightTargetMoveOrOut: null,
      isAddLastDotForArc: false
    };
  }


  clearMemory = () => {
    const { linkToSceneObject } = this.state
    const clearScene = (data = []) => {
      data.forEach((el) => {
        el.geometry.dispose()
        el.material.dispose()
        linkToSceneObject.remove(el)
      })
    }
    let line = sercheAllObjectByProperty("name", DATA_OBJECT_SCENE.line_direction_for_create_arc.name, linkToSceneObject)
    let colizionHandlerLine = sercheAllObjectByProperty("name", DATA_OBJECT_SCENE.PRIMITIVE_LINE_CHECKED.name, linkToSceneObject)
    clearScene(colizionHandlerLine)
    clearScene(line)
  }
  /**
   * обработает потерю наведения мышки на элемент
   * @param {*} group 
   */
  addEventListenerDotWrapperOut = (group) => {
    const { tool, straightTargetMoveOrOut } = this.state
    const { target } = group.data
    if (tool) {
      if (tool.type === this.toolEnum.straight) {
        if (straightTargetMoveOrOut) {
          this.setDefaultColorDot(straightTargetMoveOrOut)
          this.setState({
            straightTargetMoveOrOut: null// !!!!!!!
          })
        }
      }
    }

  }
  /**
   * обработает наведение мышкой на точку
   * @param {*} group 
   */
  addEventListenerDotWrapperMove = (group) => {
    const { tool, straightTargetMoveOrOut, activeDotForCreateStaight, renderDots, linkToSceneObject } = this.state
    const { target } = group.data
    if (tool) {
      if (tool.type === this.toolEnum.straight) {
        if (!straightTargetMoveOrOut && activeDotForCreateStaight.length) {
          let checkDot = checkConnectedDots(
            activeDotForCreateStaight[0].uuid,
            target.uuid,
            renderDots,
            this.clearMemory,
            linkToSceneObject
          )
          if (checkDot)
            this.setState({
              straightTargetMoveOrOut: target
            }, () => {
              this.setHoverColorDot(target)
            })
        }
      }
    }
  }
  /**
   * принимает ссылку на событиые группы точки и отвечает за клик по точкам
   * @param {*} group 
   */
  addEventListenerDotWrapperClick = (group) => {
    let { tool, activeDotForCreateStaight } = this.state;
    console.log("click dot")
    this.straightGenerate(tool, activeDotForCreateStaight, group)
    if (!tool) {
      return this.setState({
        activeDot: group.data.target,
      });
    }
  };
  /**
   * соберет масив точек с шагом апроксимации
   * !кусочно линейная апроксимация для прямых
   * @param {*} dataForAproximationCollector = startPointVector endPointVector directionr rAproximationStartDot rAproximationEndDot
   */
  aproximationVerticesCollector = (dataForAproximationCollector) => {
    const {
      startPointVector,
      endPointVector,
      direction,
      rAproximationStartDot,
      rAproximationEndDot
    } = dataForAproximationCollector
    let result = []
    let angle = nugol(startPointVector.x, startPointVector.y, endPointVector.x, endPointVector.y)
    if (rAproximationStartDot !== rAproximationEndDot) {
      if (rAproximationStartDot < direction && rAproximationEndDot < direction) {
        let coeficent = (direction - rAproximationStartDot) / (direction - rAproximationEndDot)
        let countDot = Math.round(Math.log10(rAproximationEndDot / rAproximationStartDot) / Math.log10(coeficent) + 1)
        if (countDot < 1) {
          countDot = 1
        } else {
          let rAproximationChanged = direction * (coeficent - 1) / (Math.pow(coeficent, countDot) - 1)
          let offsetX = 0
          let offsetY = 0

          for (let i = 0; i < countDot; i++) {
            offsetX = offsetX + rAproximationChanged * Math.cos(angle)
            offsetY = offsetY + rAproximationChanged * Math.sin(angle)
            rAproximationChanged = rAproximationChanged * coeficent
            result.push({
              x: startPointVector.x + offsetX,
              y: startPointVector.y + offsetY,
              uuid: v4(),
            })
          }
        }
      }
    }
    if (rAproximationStartDot === rAproximationEndDot) {
      let countDot = Math.round(direction / rAproximationStartDot)
      if (countDot < 1) {
        countDot = 1
      }
      let offsetXForLineSegment = rAproximationStartDot * Math.cos(angle)
      let offsetYForLineSegment = rAproximationStartDot * Math.sin(angle)
      for (let i = 1; i < countDot; i++) {
        result.push({
          x: startPointVector.x + offsetXForLineSegment * i,
          y: startPointVector.y + offsetYForLineSegment * i,
          uuid: v4(),
        })
      }
    }
    return result
  }
  /**
   * !кусочно линейная апроксимация для кривых
   * за нейминг не пиздить это все Ельдар
   */
  aproximationVerticesCollectorForArc = (
    P1 = { x: 200, y: 134 },
    P2 = { x: 353, y: 104 },
    P3 = { x: 365, y: 330 },
    Ra1 = 20,
    Ra2 = 20
  ) => {
    const nugol2 = (x1, y1, x2, y2) => {
      let xf = x2 - x1
      let yf = y2 - y1
      let result = 0
      if (xf !== 0) {
        result = Math.atan(yf / xf)
      } else {
        result = Math.PI * 3 / 2
      }
      if (xf < 0) {
        result += Math.PI
      }
      if (result < 0) {
        result += Math.PI * 2
      }
      if (result >= (Math.PI * 2)) {
        result -= Math.PI * 2
      }
      return result
    }
    let result = []
    let step1 = Ra1
    let step2 = Ra2
    let rad1 = Math.sqrt(Math.pow(P2.x - P1.x, 2) + Math.pow(P2.y - P1.y, 2))
    let rad2 = Math.sqrt(Math.pow(P3.x - P1.x, 2) + Math.pow(P3.y - P1.y, 2))
    if (rad1 <= 0 || rad2 <= 0) {
      return false
    }
    let un = nugol2(P1.x, P1.y, P2.x, P2.y)
    let uk = nugol2(P1.x, P1.y, P3.x, P3.y)
    if (uk <= un) {
      uk += 2 * Math.PI
    }
    let direction = uk - un
    step1 = Ra1 / rad1
    step2 = Ra2 / rad2
    let q, nt
    if (step1 < direction && step2 < direction) {
      q = (direction - step1) / (direction - step2)
      if (q === 1) {
        nt = Math.round(direction / step1)
      }
      if (q === 1) {
        nt = Math.round(direction / step1)
        if (nt < 1) {
          nt = 1
        }
        step1 = direction / nt
      } else {
        nt = Math.round(Math.log10(step2 / step1) / Math.log10(q) + 1)
        if (nt < 1) {
          nt = 1
        }
        if (nt > 1) {
          step1 = direction * (q - 1) / (Math.pow(q, nt) - 1)
        }
      }
      let du = 0
      for (let i = 1; i < nt; i++) {
        du = du + step1
        let u = un + du
        let rad = rad1 + (rad2 - rad1) / direction * du
        let x = P1.x + rad * Math.cos(u)
        let y = P1.y + rad * Math.sin(u)
        step1 *= q
        result.push(new Vector2(x, y))
      }
    }
    return result
  }
  /**
   * добавил прямую в граф точек
   * @param {*} target 
   * @param {*} active 
   */
  addStraight = (target, active) => {
    const { renderDots, activeDotForCreateStaight, straightTargetMoveOrOut } = this.state
    const addDataForArr = (arr, content) => {
      arr.push(content)
      return arr
    }
    const getDotData = (uuid) => {
      for (let i = 0; i < renderDots.length; i++) {
        const element = renderDots[i];
        if (element.uuidDot === uuid) {
          return element
        }
        if (element.type === 'arc') {
          if (element.startPoint.uuidDot === uuid) {
            return element.startPoint
          }
          if (element.centerPoint.uuidDot === uuid) {
            return element.centerPoint
          }
          if (element.endPoint.uuidDot === uuid) {
            return element.endPoint
          }
        }

      }
    }
    const collectorGrafPrimitive = (el) => {
      if (el.uuidDot === active.uuid) {
        let startPointVector = { x: el.x, y: el.y };
        let endPointVector = { x: target.position.x, y: target.position.z }
        let direction = checkDirectionForVector2(startPointVector, endPointVector)
        let rAproximationStartDot = el.coeficentR
        let rAproximationEndDot = getDotData(target.uuid)
        //!объект необходимый для функции кусочно линейной апроксимации
        let dataForAproximationCollector = {
          startPointVector,
          endPointVector,
          direction,
          rAproximationStartDot: Number(rAproximationStartDot),
          rAproximationEndDot: Number(rAproximationEndDot.coeficentR)
        }
        return {
          ...el,
          edge: addDataForArr(el.edge, {
            direction: direction,
            color: "red",
            idEndLink: target.uuid,
            aproximationVertices: this.aproximationVerticesCollector(dataForAproximationCollector)
          }),
          linkToStraight: addDataForArr(el.linkToStraight, target.uuid)
        }
      } else {
        return false
      }
    }
    let newRenderDots = renderDots.map((el) => {
      if (el.type !== "arc") {
        let result = collectorGrafPrimitive(el)
        return result ? result : el
      } else {
        //todo: not create arc
        let result = el
        let startPoint = collectorGrafPrimitive(el.startPoint)
        let centerPoint = collectorGrafPrimitive(el.centerPoint)
        let endPoint = collectorGrafPrimitive(el.endPoint)
        if (startPoint) result = {
          ...el,
          startPoint
        }
        if (centerPoint) result = {
          ...el,
          centerPoint
        }
        if (endPoint) result = {
          ...el,
          endPoint
        }
        return result
      }
    })
    this.setDefaultColorDot(activeDotForCreateStaight[0])
    if (straightTargetMoveOrOut)
      this.setDefaultColorDot(straightTargetMoveOrOut)
    this.setState({
      renderDots: newRenderDots,
      activeDotForCreateStaight: [],
      straightTargetMoveOrOut: null
    })
  }
  /**
   * отвечает за созданые линии
   * @param {*} tool 
   * @param {*} activeDotForCreateStaight 
   * @param {*} group
   */
  straightGenerate = (tool, activeDotForCreateStaight, group) => {
    const { renderDots, linkToSceneObject } = this.state
    if (tool) {
      if (tool.type === this.toolEnum.straight) {
        if (activeDotForCreateStaight.length <= 0) {
          activeDotForCreateStaight.push(group.data.target)
          return this.setState({
            activeDotForCreateStaight: activeDotForCreateStaight,
            activeDot: false,
          });
        } else {
          let checkDoobleClick = activeDotForCreateStaight[0].uuid === group.data.target.uuid
          if (checkDoobleClick) {
            this.setDefaultColorDot(group.data.target)
            this.setState({
              activeDotForCreateStaight: []
            })
          } else {
            let checkDot = checkConnectedDots(
              activeDotForCreateStaight[0].uuid,
              group.data.target.uuid,
              renderDots,
              this.clearMemory,
              linkToSceneObject
            )
            if (checkDot) {
              this.addStraight(group.data.target, activeDotForCreateStaight[0])
            } else {
              console.log('mis')
            }
          }
        }
      }
    }
  }
  /**
   * отвечает за созданые кривой
   * @param {*} position 
   */
  arcGenerate = (position) => {
    let { renderDots, renderArc, isAddLastDotForArc } = this.state;
    let dataForDot = {
      coeficentR: this.getDataDotFromSidebar().r,
      position: position,
      x: position.x,
      y: position.z,
      countId: renderDots.length,
      uuidDot: v4(),
      edge: [],
      linkToStraight: []
    };
    if (renderArc.length === 2) {
      if (isAddLastDotForArc) {
        renderArc.push(dataForDot)
      }
    } else {
      renderArc.push(dataForDot)
    }

    this.setState({
      renderArc: renderArc
    })
  }
  /**
   * получит данные из сайд бара для точек
   */
  getDataDotFromSidebar = () => {
    const { x, y, r, id } = this.props.DotData
    return { x, y, r, id }
  }
  /**
   * принимает позицию и на её основе соберет новую точку 
   * @param {*} positionParams 
   */
  createDot = (positionParams) => {
    let { renderDots } = this.state;
    renderDots.push({
      ...positionParams,
      coeficentR: this.getDataDotFromSidebar().r,
      x: this.getDataDotFromSidebar().x,
      y: this.getDataDotFromSidebar().y,
      countId: renderDots.length,
      uuidDot: v4(),
      edge: [],
      linkToStraight: []
    });
    this.setState({
      renderDots: renderDots,
    });
  }
  /**
   * метод отвечающий за подготовку к созданию прямой
   */
  createStraight = () => {
    const { activeDot, uslesCheckPosMouse } = this.state
    // debugger
    if (activeDot || !uslesCheckPosMouse) {
      this.setState({
        uslesCheckPosMouse: true,
        activeDot: false
      })
    }
  }
  /**
     * метод отвечающий за подготовку к созданию кривой
     */
  createArc = () => {
    const { activeDot, uslesCheckPosMouse } = this.state
    // debugger
    if (activeDot || !uslesCheckPosMouse) {
      this.setState({
        uslesCheckPosMouse: false,
        activeDot: false
      })
    }
  }
  /**
   * @positionParams = { position, distance }
   * выбирает новые объекты для рендера исходя из инструмента
   */
  addRenderElemet = (positionParams) => {
    let { tool } = this.state;
    if (!tool) return
    switch (tool.type) {
      case this.toolEnum.dot:
        this.setState({
          uslesCheckPosMouse: false
        }, () => this.createDot(positionParams))
        break;
      case this.toolEnum.straight:
        this.createStraight(positionParams)
        break;
      case this.toolEnum.arc:
        this.createArc(positionParams)
        break;
      default:
        return;
    }
  };
  /**
 * Получит ссылку на объект сцены для дальнешего использования в основном для поиска елементов
 * @param {*} scene 
 */
  getLinkScene = (scene) => {
    this.setState({
      linkToSceneObject: scene
    })
  }
  /**
* Получит размеры холста
* @param {*} scene 
*/
  getSizeCanvasDomEl = (heightCanvas, widthCanvas) => {
    this.setState({
      heightCanvas: heightCanvas,
      widthCanvas: widthCanvas
    })
  }
  /**
   * соберет верменную кривую из входных параметров
   */
  canvasMoveToCreateArc = (renderArc, position, DotData) => {
    const { heightCanvas, widthCanvas, linkToSceneObject, renderDots, verticesForArc } = this.state
    if (renderArc.length === 1) {
      let startVector = new Vector2(position.x + widthCanvas, position.z + heightCanvas)
      let stopVector = new Vector2(renderArc[0].x + widthCanvas, renderArc[0].y + heightCanvas)
      createArcPreStraight(
        startVector,
        stopVector,
        linkToSceneObject,
        this.clearMemory
      )
    } else if (renderArc.length === 2) {
      this.clearMemory()
      let startDot = new Vector2(renderArc[0].x + widthCanvas, renderArc[0].y + heightCanvas)
      let twoDot = new Vector2(renderArc[1].x + widthCanvas, renderArc[1].y + heightCanvas)
      let aproximationVector = new Vector2(position.x + widthCanvas, position.z + heightCanvas)
      let verticesFromAproximationArc = this.aproximationVerticesCollectorForArc(
        startDot, twoDot, aproximationVector,
        renderArc[1].coeficentR,
        DotData.r,
      )
      let newVerticesForArc = createArcAllPoints(
        aproximationVector,
        twoDot,
        verticesFromAproximationArc,
        linkToSceneObject,
        this.clearMemory,
        heightCanvas,
        widthCanvas,
        renderArc,
        renderDots
      )
      if (Array.isArray(newVerticesForArc)) {
        this.setState({
          isAddLastDotForArc: true,
          verticesForArc: newVerticesForArc
        })
      } else {
        this.setState({
          verticesForArc: [],
          isAddLastDotForArc: false
        })
      }
    } else if (renderArc.length >= 2) {
      let paramsArc = {
        radiusAStartPoint: renderArc[1].coeficentR,
        radiusAEndPoint: DotData.r,
        renderDots: renderDots,
        verticesForArc: verticesForArc,
        renderArc: renderArc
      }
      let newPointArc = serializers.addPointToRenderDots(paramsArc)
      this.setState({
        renderArc: [],
        verticesForArc: [],
        renderDots: newPointArc
      })
      //!merge in renderdots and clear renderarc
      this.clearMemory()
    }
  }
  /**
   * Получает позицию мышки когда выбран инструмент
   * @param {*} position 
   */
  getPositionCanvasMove = ({ position }) => {
    const { DotData } = this.props
    const {
      tool,
      renderArc,
    } = this.state
    if (tool) {
      //обновляем данные в сайдбаре
      this.props.dispatch("DotData", {
        x: position.x,
        y: position.z,//сделал так потому что мы смотрим в проекции  сверху а не с боку
        r: DotData.r,
        id: DotData.id
      })
      // работаем с построением временных мешей
      if (tool.type === this.toolEnum.arc) {
        this.canvasMoveToCreateArc(renderArc, position, DotData)
      }
    }

  }
  /**
   * по клику возьмет координаты и попытается добавить выбранный элемент
   * @param {*} { position, distance } 
   */
  getPositionCanvasClick = ({ position, distance }) => {
    const { tool } = this.state
    let positionParams = {
      position,
      distance,
    };
    this.addRenderElemet(positionParams);
    if (tool)
      if (tool.type === this.toolEnum.arc) {
        this.arcGenerate(position)
      }
  }
  /**
   * принимает точку и востановит ей дефолтный цвет
   * @param {*} dot 
   */
  setDefaultColorDot = (dot) => {
    dot.children[0].material.color.set("#f54248");
    dot.children[1].material.color.set("#d1db16");
  }
  /**
   * принимает точку и установит ей цвет выбранной точки
   * @param {*} dot 
   */
  setSelectedColorDot = (dot) => {
    dot.children[0].material.color.set("#4a65ff");
    dot.children[1].material.color.set("#4a65ff");
  }
  /**
   * принимает точку и установит ей цвет при на вередении
   * @param {*} dot 
   */
  setHoverColorDot = (dot) => {
    dot.children[0].material.color.set("red");
    dot.children[1].material.color.set("red");
  }
  /**
   * Используется для визуальной части не должен перерендеривать в случае прохода хоть в 1 if
   * так сделано из-за того что тут можно контролировать ререндер
   * @param {*} nextProps
   * @param {*} nextState
   * @param {*} nextContext
   */
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { activeDotForCreateStaight, tool, activeDot } = nextState
    let { activeDot: thisActiveDot, oldActiveDotForCreateStaight } = this.state
    // проверяет какие пользователь выбрал точки для построения прямой
    if (activeDotForCreateStaight) {
      if (oldActiveDotForCreateStaight) {
        //!выделение снято
        this.setDefaultColorDot(oldActiveDotForCreateStaight)
      }
      activeDotForCreateStaight.forEach((el, i) => {
        //!выделенные точки
        this.setSelectedColorDot(el)
      })
    }
    //проверит обновился ли выделеный примитив точка
    if (thisActiveDot !== activeDot) {
      //!выделение снято
      if (thisActiveDot) {
        this.setDefaultColorDot(thisActiveDot)
      }
      //!выделенная точка
      if (!tool) {
        if (thisActiveDot) {
          this.setSelectedColorDot(activeDot)
          this.props.dispatch("DotData", {
            x: activeDot._customAttribute.x,
            y: activeDot._customAttribute.y,
            r: activeDot._customAttribute.coeficentR,
            r: activeDot._customAttribute.coeficentR,
            id: activeDot._customAttribute.countId
          })
        }
      }
      return true;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //Проверяет обновился ли выбранный инструмент
    if (!isEqual(this.props.SelectedToolPFML, prevProps.SelectedToolPFML)) {
      let tool = this.props.SelectedToolPFML;
      this.setState({
        tool: tool,
        uslesCheckPosMouse: false,
        // oldActiveDotForCreateStaight: false,      
        activeDotForCreateStaight: [],
        straightCreatorFirst: false,
        straightTargetMoveOrOut: null
      });
      return true
    }
    //Проверяет обновился ли массив с точками
    if (this.state.renderDots !== prevState.renderDots) {
      return true
    }
    // if (!isEqual(this.props, prevProps)) {
    //   return true
    // }
    return false
  }

  componentDidMount() {
    // console.log(this.props, "didmount");
  }
  //todo: сделай метод обновляющий количество мусора на сцене для сайдбара 
  render() {
    const {
      renderDots,
      renderArc,
      renderStraight,
      straightCreatorFirst,
      positionDotMoveMouse,
      tool,
      activeDotForCreateStaight,
      uslesCheckPosMouse,
      linkToSceneObject,
      zoomOrhoganal = 0.2,
      widthCanvas
    } = this.state;
    console.log('PFML', linkToSceneObject)
    return (
      <React.Fragment>
        <Canvas
          getScene={!linkToSceneObject ? this.getLinkScene : null}
          getSize={!widthCanvas ? this.getSizeCanvasDomEl : null}
          enableShadows={false}
          id={"canvas"}
          debug={false}
          fullscreen={true}
        >
          <PerspectiveCamera orthoganalEnable={true} position={[0, 20, 0]} zoomOrhoganal={zoomOrhoganal}>
            <OrthoganalControl />
            {/* <OrbitControls /> */}
            <Raycast />
            <CheckPositionMoseToScene
              usles={uslesCheckPosMouse}
              getPositionMove={this.getPositionCanvasMove}
              getPositionClick={this.getPositionCanvasClick}
            />
            {/* <DebugMode object={null} /> */}
          </PerspectiveCamera>
          <DotCreator
            addEventListenerDotWrapperClick={this.addEventListenerDotWrapperClick}
            addEventListenerDotWrapperMove={this.addEventListenerDotWrapperMove}
            addEventListenerDotWrapperOut={this.addEventListenerDotWrapperOut}
            renderDots={renderDots}
            renderArc={renderArc}
          />
          <StraightCreator
            renderDots={renderDots}
          />
          <CurveCreator
            addEventListenerDotWrapperClick={this.addEventListenerDotWrapperClick}
            addEventListenerDotWrapperMove={this.addEventListenerDotWrapperMove}
            addEventListenerDotWrapperOut={this.addEventListenerDotWrapperOut}
            renderDots={renderDots}
            renderArc={renderArc}
          />
          {/* <SyncGeometry name={DATA_OBJECT_SCENE.ARC_FRAGMETS.name}/> */}
          {/* <Grid position={[0, -20, 0]} divisions={999} size={999} color={"gray"} colorCenterLine={"gray"} /> */}
          <AmbientLight position={[0, 10, 0]} intensity={1} />
        </Canvas>
      </React.Fragment>
    );
  }
}

export default connect(
  "DataCountAllPrimitiveScenePFML",
  "DeleteElementData",
  "ElementsContext",
  "ElementsPFML",
  "SelectedToolPFML",
  "DotData",
  CanvasPFML
);