import React, { useState } from "react";
import ButtonMain from "../ButtonMain";
import ControllerOffAndOn from "../../../common/ControllerOffAndOn";
import { ReactComponent as Polygon } from "../../../assets/images/Polygon.svg";
import ToolTipPanelDevice from "../../../common/ToolTipPanelDevice";
import connect from 'storeon/react/connect'


const ElementToolPanelCanvasLayout = (props) => {
  const [helper, setHelper] = useState({});
  const [showhelper, setShowHelper] = useState(false)
  let timeOut
  let eventWindowMove = (e) => {
    closeHelper()
    window.removeEventListener('mousemove', eventWindowMove)
  }
  const helperQuestion = (ev, data) => {
    ev.persist()
    const renderHelper = () => {
      clearTimeout(timeOut)
      setShowHelper(true)
      setHelper({
        target: ev.target,
        data: data
      })
    }
    timeOut = setTimeout(renderHelper, 2000)
  }
  const closeHelper = () => {
    clearTimeout(timeOut)
    setShowHelper(false)
  }
  let {
    elementsPanel,
    isPaused,
    isPlay,
    PlayOrStop,
    PausedOrPlay,
    clickElement,
    ofOrIn = true,
    processLoadDevice
  } = props;
  // debugger
  let theme = props.ProjectTheme === 'dark' ? 'panel' : 'light-panel'
  if (showhelper) {
    window.addEventListener('mousemove', eventWindowMove)
  }
  return (
    <div className={`tool_panel-wrap ${theme}`}>
      {elementsPanel.map((el, i) => {
        return (
          <div key={i} className={`tool_panel-elements`}>
            <ButtonMain
              className={`tool_panel-elements-btn ${el.active ? "active" : ""}`}
              onClick={(e) => {
                closeHelper()
                clickElement(el, i)
              }}
              onMouseOver={(ev) => {
                helperQuestion(ev, el)
              }}
              onMouseOut={closeHelper}
            >
              <img
                className={`tool_panel-elements-icon ${el.type === "OwnDevice" ? "min" : ""
                  }`}
                src={el.image}
              ></img>
            </ButtonMain>
            {el.menu ? (
              <Polygon
                fill={props.ProjectTheme === 'dark' ? (el.isView ? "#4a9eea" : "white") : (el.isView ? "#4a9eea" : "#25282B")}
                className={`tool_panel-add_element_icon`}
              />
            ) : null}
            <div
              className={`tool_panel-add_element_select ${el.isView ? "active" : "deactive"
                }`}
            >
              {el.menu
                ? el.menu.map((el2, i) => {
                  return (
                    <div
                      className={`tool_panel-add_element_select-text ${el2.active ? "active" : ""}`}
                      onClick={(e) => {
                        closeHelper()
                        clickElement(el2, i)
                      }}
                      onMouseOver={closeHelper}
                      key={i}
                    >
                      {el2.name}
                      <span
                        className={`tool_panel-add_element_select-key_code`}
                      >
                        {el2.keyCode}
                      </span>
                    </div>
                  );
                })
                : null}
            </div>
          </div>
        );
      })}
      {
        ofOrIn !== "disabled" ? (
          <ControllerOffAndOn
            isPlay={isPlay}
            isPaused={isPaused}
            PlayOrStop={PlayOrStop}
            PausedOrPlay={PausedOrPlay}
          />
        ) : null
      }

      {showhelper && !processLoadDevice ? <ToolTipPanelDevice {...helper} /> : null}

    </div>
  );
};

export default connect('ProjectTheme', ElementToolPanelCanvasLayout);
