import React from "react";
import ButtonMain from "../../Views/Layout/ButtonMain";
import { DATA_OBJECT_SCENE } from "../../const";
import ToolTipCreator from '../ToolTipCreator'
import ColorPicker from "../ColorPicker";
import Api from '../../api'

const ApiResearch = Api.ResearchApi
class ContextMenu extends React.Component {
  constructor() {
    super();
    this.contextMenu = React.createRef();
    this.targetRef = React.createRef()
    this.state = {
      show: false,
      renderBtn: [],
      styleWrapper: {
        translateX: 0,
        translateY: 0,
      },
    };
  }

  getOptionsFromTarget = (target) => {
    let newRenderBtn = []
    switch (target.name) {
      case DATA_OBJECT_SCENE.connector.name:
        newRenderBtn.push(this.renderBtnAddWire())
        newRenderBtn.push(this.renderBtnColorPicker())
        break
      case DATA_OBJECT_SCENE.deviceConnector.name:
        newRenderBtn.push(this.renderConnectorsManager())
        break
      default:
    }
    newRenderBtn.push(this.renderBtnDelete())
    newRenderBtn.push(this.renderBtnClose())
    return newRenderBtn
  }

  checkIsOpen = (event) => {
    // debugger
    let target = event.data.data.target
    // if(!target) return false
    switch (target.name) {
      case DATA_OBJECT_SCENE.connector.name:
        return true
      case DATA_OBJECT_SCENE.deviceConnector.name:
        return true
      default:
        return false
    }
  }

  positionRender = (props) => {
    if (props.visibleContextMenu) {
      let target = props.data.data.target;
      // debugger
      if (target) {
        switch (target.name) {
          case "buttonOffOrIn":
          case "buttonQuestrion":
          case "range":
          case "verticalRange":
          case "increment btn":
          case "decriment btn":
          case "reostatRange":
          case DATA_OBJECT_SCENE.objectConnector.name:
            // return false
            props.data.intersects.forEach(element => {
              if (element.object.name === DATA_OBJECT_SCENE.deviceConnector.name) {
                target = element.object
                props.data.data.target = element.object
              }
            });
        }
      }
      let checkDevice = this.checkIsOpen(props)
      if (!checkDevice) {
        return false
      }
      let newRenderBtn = this.getOptionsFromTarget(target)
      let { offsetX: screenX, offsetY: screenY } = props.data.data.originalEvent;
      this.setState({
        renderBtn: newRenderBtn,
        styleWrapper: {
          translateX: screenX,
          translateY: screenY,
        },
      });
    }
  };

  /**
   * поиск по проводам если что то подключено он вернет тру иначе фолс
   * @param {*} id 
   * @param {*} deviceId 
   * @param {*} data 
   * @returns bool
   */
  getIsConnectedDevice = (id, data) => {
    let result = false
    data.forEach((el, i) => {
      el.connections.forEach((el, i2) => {
        if (el.lastConnector.uuid === id) {
          result = {
            wireIter: i,
            connectorIter: i2
          }
        }
      })

    })
    return result
  }

  /**
   * !проблема при клике вне области прибора не проходит поиск конекторов
   */
  renderContentForTooltip = () => {
    const { wiresData, componentData } = this.props.dataAllObjects;
    const target = this.props.data.data.target;
    let elementFromComponentDataDevice = null
    for (let i = 0; i < componentData.length; i++) {
      const element = componentData[i];
      if (element.id === target.uuid) elementFromComponentDataDevice = element
    }
    if (elementFromComponentDataDevice) {
      return <div className={"custom_menu-connector_manager-wrapper"}>
        {
          elementFromComponentDataDevice.connectors.map((el, i) => {
            let renderBtn = this.getIsConnectedDevice(el.id, wiresData)
            return (
              <div key={i} className={"custom_menu-connector_manager-block_conection_element"}>
                <span>{el.type, i}</span>
                {
                  renderBtn
                    ? <ButtonMain onClick={(e) => {
                      this.props.handlerIsDisconnectedWire(true)
                      this.props.handlerClickDisconnectConnector(renderBtn, {
                        deviceId: target.uuid,
                        clema: el.id
                      })
                    }} className={"custom_menu-connector_manager-btn-connected"}>отключить от сети</ButtonMain>
                    : <ButtonMain disabled={true} className={"custom_menu-connector_manager-btn-disconnected"}>отключено</ButtonMain>
                }
              </div>
            )
          })
        }
      </div>
    } else {
      //todo: сделать нормальный набор кнопок
      this.setState({
        show: false
      })
      return null
    }
  }

  renderConnectorsManager = () => {
    return <div className={"custom_menu-connector_manager"} ref={this.targetRef} onClick={() => {
      this.setState({
        show: !this.state.show
      })
    }}></div>
  }

  renderBtnClose = () => {
    return <ButtonMain className={"custom_menu-close"} onClick={() => {
      this.setState({
        show: false
      }, () => this.props.callbackCloseContextMenu())
    }}> x </ButtonMain>
  }

  renderBtnDelete = () => {
    const target = this.props.data.data.target;
    const { callbackDeleteElementScene, dataAllObjects } = this.props
    const { wiresData } = dataAllObjects
    return <ButtonMain onClick={() => {
      let resutUuid = target.uuid
      if (target.name === "device input" || target.name === "connector") {
        if (target.name === "connector") {
          wiresData.forEach((el) => {
            el.vertices.forEach(elVertices => {
              if (elVertices.id === target.uuid) {
                resutUuid = el.id
              }
            })
          })
        }
        ApiResearch.deleteDeviceFormResearch(resutUuid)
      }
      callbackDeleteElementScene(target);
      this.setState({
        show: false
      }, () => this.props.callbackCloseContextMenu())
    }} className={"custom_menu-delete"}
    ></ButtonMain>
  }
  /**
   * изменит цвет провода сразу по изменению в колор пикере
   * @param {*} uuid 
   * @param {*} data 
   * @param {*} color 
   */
  materialSetForWire = (uuid, data, color) => {
    const { linkToSceneObject } = this.props
    let resutUuid = null
    data.forEach((el) => {
      el.vertices.forEach(elVertices => {
        if (elVertices.id === uuid) {
          resutUuid = el.id
        }
      })
    })
    let wire = linkToSceneObject.getObjectByProperty("uuid", resutUuid)

    if (wire) {
      wire.material.color.set(color)
    }
  }

  renderBtnColorPicker = () => {
    const target = this.props.data.data.target;
    const { callbackColorPicker, dataAllObjects } = this.props//defaultColor
    const { wiresData } = dataAllObjects
    return <ButtonMain className={"custom_menu-color_picker"}>
      <ColorPicker
        onChangeColor={(color) => {
          callbackColorPicker(color, target)
          this.materialSetForWire(target.uuid, wiresData, color)
        }}
        defaultColor={target.material.color}
      />
    </ButtonMain>
  }

  renderBtnAddWire = () => {
    const { callbackAddPointToWires } = this.props
    return <ButtonMain className={"custom_menu-plus"} onClick={() => {
      callbackAddPointToWires(this.props.data.data)
      this.setState({
        show: false
      }, () => this.props.callbackCloseContextMenu())
    }}
    > + </ButtonMain>
  }

  componentWillUnmount() {
    this.targetRef = null
    this.setState({
      show: false
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.visibleContextMenu !== prevProps.visibleContextMenu) {
      this.positionRender(this.props);
      return true;
    }
    return this.state.styleWrapper !== prevState.styleWrapper;
  }

  componentDidMount() {
    this.positionRender(this.props);
  }

  render() {
    const {
      styleWrapper,
      renderBtn,
      show,
      target,
      fadeOutContextMenu,
      contentForModal
    } = this.state;
    const {
      visibleContextMenu,
    } = this.props;
    if (!this.props.data) return null
    return (
      <>
        <div
          ref={(ref) => {
            this.contextMenu = ref;
          }}
          style={{
            transform: `translateX(${styleWrapper.translateX}px)translateY(${styleWrapper.translateY}px)`,
          }}
          className={
            visibleContextMenu
              ? "custom_menu-wrap_visible"
              : "custom_menu-wrap_visible_false"
          }
        >
          {/* <div> */}
          {renderBtn.map((el, i) => <div className={"custom_menu-wrap_btn"} key={i}>{el}</div>)}
          {/* </div> */}
        </div>
        <div
          className={visibleContextMenu ? "custom_menu-wrap_close" : ""}
          onClick={() => {
            this.setState({
              show: false
            }, () => this.props.callbackCloseContextMenu())
          }}
        />
        {
          show ? <ToolTipCreator show={show} target={this.targetRef} contentForModal={this.renderContentForTooltip()} /> : null
        }

      </>
    );
  }
}

export default ContextMenu;
