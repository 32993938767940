import BaseApi from "@garpix/base-api";
import { getSerializedItemNews } from "./Serialized";

class ContentApi extends BaseApi {
  getNewsContent = async (params = {}) => {
    const res = await this.get(`/content/news/`, params);
    const data = res.data;
    data.results = data.results.map(getSerializedItemNews);
    return data;
  };
  getNewsInSlug = async (slug, params = {}) => {
    const res = await this.get(`/content/news/${slug}/`, params);
    const data = res.data;
    return data.data;
  };
  getQuestrionsForFaq = async (params = {}) => {
    const res = await this.get(`/content/faq/`, params);
    const data = res.data;
    return data.data;
  };
}

export default ContentApi;
