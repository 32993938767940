import React from "react";
import ComponentCreator from './Components'
import SubmitForm from '../../../common/SubmitForm'
import Api from '../../../api'


const ApiResearch = Api.ResearchApi
class ComponentCreatorForModels extends ComponentCreator {
  constructor(props) {
    super();
    this.maxThetaLength = 3.15 //это максимально возможное значение заполнения range
    this.state = {
      groupData: {
        position: [0, 0, 0],
        rotation: [0, 0, 0],
        scale: [1, 1, 1],
      },
      IncOrDecBtn: null,
      renderComponent: [],
    };
  }

  submitData = (componentId, data) => {
    const { uuid } = this.props.parent
    let params = {
      device_id: uuid,
      params: {
        type: this.props.device_type,
        component: {
          [componentId]: data
        }
      }
    }
    SubmitForm(ApiResearch.putResearchUpdateDeviceData, params, this.successSubmit)
  }

  successSubmit = (response) => {
    console.log(response)
  }


  updateComponentOrContinueRender = (type, id, name) => {
    let component = this.props.parent.getObjectByProperty("uuid", id)
    if (component)
      component.geometry.dispose()
    this.props.parent.remove(component)
  }
  /**
   * инициализирует создание дополнительных компонент и решает что отрисовать
   * @param {*} data просы
   * @param boolean флаг говорящий за то что это обновление
   */
  initCreatorComponent = (data, update = false) => {

    data.map((el, i) => {
      switch (el.type) {
        case "display":
          return this.addRenderDisplay(el);
        case "displayCustomTexture":
          return this.addRenderdisplayCustomTexture(el);
        case "buttonOffOrIn":
          return this.addRenderButtonOffOrIn(el);
        case "buttonQuestrion":
          if (!update) return this.addRenderButtonQuestrion(el);
          break
        case "chart":
          return this.addRenderChart(el)
        case "range":
          return this.addRenderRangeCilinder(el)
        case "verticalRange":
          return this.addRenderVerticalRange(el);
        case "outerShaft":
          if (!update) return this.addRenderOuterShaft(el)
          break
        case "IncOrDecBtn":
          return this.addRenderIncOrDecBtn(el)
        case "reostatRange":
          return this.addRenderVerticalRangeForReostat(el)
        default:
          return false;
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.IncOrDecBtn !== this.state.IncOrDecBtn) {
      // if (this.props.dataComponent) this.initCreatorComponent(this.props.dataComponent, true);
      return true
    }
  }


  componentDidMount() {
    console.log('component', this.props)
    if (this.props.dataComponent) this.initCreatorComponent(this.props.dataComponent);
  }

  render() {
    const { renderComponent } = this.state;
    if (renderComponent.length >= 1) {
      return renderComponent.map((el, i) => (
        <React.Fragment key={i}>{el}</React.Fragment>
      ));
    } else {
      return null;
    }
  }
}

export default ComponentCreatorForModels;
