import React from 'react'
import { Interaction } from 'three.interaction'

class Raycaster extends React.Component {
  constructor (props) {
    super()
  }

  componentDidMount () {
    const { renderer, scene, camera } = this.props
    /**
     * @param {WebGLRenderer} renderer - A reference to the current renderer
     * @param {Scene} scene - A reference to the current scene
     * @param {Camera} camera - A reference to the current camera
     * @param {Object} [options] - The options for the manager.
     * @param {Boolean} [options.autoPreventDefault=false] - Should the manager automatically prevent default browser actions.
     * @param {Boolean} [options.autoAttach=false] - Should the manager automatically attach target element.
     * @param {Number} [options.interactionFrequency=10] - Frequency increases the interaction events will be checked.
     */
    // if(window) {
    //   import(`three.interaction`)
    //     .then(({Interaction}) => {
    //       return new Interaction(renderer, scene, camera, false, false, 1)
    //     })
    // }
    new Interaction(renderer, scene, camera, false, false, 1)
  }

  render () {
    return null
  }
}

export default Raycaster