import { Vector3 } from "three";
import uuid from "uuid";
export default () => {
  return {
    wire: {
      id: uuid.v4(),
      type: "wire",
      polarity: true,
      color: "#000000".replace(/0/g, function () {
        return (~~(Math.random() * 16)).toString(16);
      }),
      connections: [],
      vertices: [
        {
          id: uuid.v4(),
          position: new Vector3(2, 3, 0),
          type: "line",
        },
        {
          id: uuid.v4(),
          position: new Vector3(-2, 3, 0),
          type: "line",
        },
      ],
      verticesLength: 2,
    },
    voltmeter: {
      url: "/models/voltmeter/voltmentr.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          name: "vol",
          maxValue: "9999999E+55"
        },
        deviceSettings: {
          scale: [
            { id: uuid.v4(), name: 'мили', isActive: false },
            { id: uuid.v4(), name: '1:1', isActive: false },
            { id: uuid.v4(), name: 'кило', isActive: true }
          ],
          kindOfCurrent: [
            { id: uuid.v4(), name: 'Постоянный(=)', isActive: false },
            { id: uuid.v4(), name: 'Переменный(≈)', isActive: true }
          ]
        }
      },
      component: [
        {
          id: uuid.v4(),
          position: [0.08, -0.17, -2.12],
          rotation: [0, 0, 0],
          scale: [4.3, 0.6, 3],
          type: "display",
          GLTF_NAME: "display",
          GLTF_POSITION: [1.46, -0.12, 0.53],
          GLTF_PIVOT: [-1.43, 0.55, -0.23],
          GLTF_ROTATION: [0, 121.58, 0], //GLTF_ROTATION[1] === value for display min = 121.58 max = 117.18
          GLTF_QUATERNION: [90, 0, 0, 190],
          GLTF_SCALE: [150, 0.06, 200],
          GLTF_URL: "/models/voltmeter/strelka.gltf",
        },
        {
          id: uuid.v4(),
          type: "displayCustomTexture",
          value: "99999",
          color: "#000",
          textParams: { fillTextXAxias: 10, fillTextYAxias: 15, font: "14pt a_lcdnova", width: 70, height: 20 },
          GROUP_NAME: "displayCustomTexture-number",
          GROUP_POSITION: [0.19, -0.25, -0.11],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [2.26, 0.26, 0.56],
          MESH_SCALE: [2.26, 0.26, 0.56, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "buttonQuestrion",
          GROUP_NAME: "buttonQuestrion",
          GROUP_POSITION: [2.21, -0.31, -4.07],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1, 1.9, 1],
          QUESTRION_NAME: "buttonQuestrion",
          QUESTRION_POSITION: [0, 0, 0],
          QUESTRION_ROTATION: [0, 90, 0],
          QUESTRION_SCALE: [0.71, 1, 0.71],
        },
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [0.74, 0, 1.45],
          rotation: [0, 0, 0],
          scale: [0.2, 5, 0.2, 5, 5, 5],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.57, 0, 1.45],
          rotation: [0, 0, 0],
          scale: [0.2, 5, 0.2, 5, 5, 5],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [5, 1, 5, 1, 1, 1],
      positionGLTF: [31.9, -0.6, 5],
      scaleToGLTF: [25, 25, 25],
      type: "voltmeter",
    },
    ampermeter: {
      url: "/models/ampermetr/main.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          name: "amp",
          maxValue: "9999999E+55"
        },
        deviceSettings: {
          scale: [
            { id: uuid.v4(), name: 'мили', isActive: false },
            { id: uuid.v4(), name: '1:1', isActive: false },
            { id: uuid.v4(), name: 'кило', isActive: true }
          ],
          kindOfCurrent: [
            { id: uuid.v4(), name: 'Постоянный(=)', isActive: false },
            { id: uuid.v4(), name: 'Переменный(≈)', isActive: true }
          ]
        }
      },
      component: [
        {
          id: uuid.v4(),
          position: [0.08, -0.17, -2.12],
          rotation: [0, 0, 0],
          scale: [4.3, 0.6, 3],
          type: "display",
          GLTF_NAME: "display",
          GLTF_POSITION: [1.46, -0.12, 0.53],
          GLTF_PIVOT: [-1.43, 0.55, -0.23],
          GLTF_ROTATION: [0, 121.58, 0], //GLTF_ROTATION[1] === value for display min = 121.58 max = 117.18
          GLTF_QUATERNION: [90, 0, 0, 190],
          GLTF_SCALE: [150, 0.06, 200],
          GLTF_URL: "/models/ampermetr/strelka.gltf",
        },
        {
          id: uuid.v4(),
          type: "displayCustomTexture",
          value: "99999",
          color: "#000",
          textParams: { fillTextXAxias: 10, fillTextYAxias: 15, font: "14pt a_lcdnova", width: 70, height: 20 },
          GROUP_NAME: "displayCustomTexture-number",
          GROUP_POSITION: [0.19, -0.25, -0.11],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [2.26, 0.26, 0.56],
          MESH_SCALE: [2.26, 0.26, 0.56, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "buttonQuestrion",
          GROUP_NAME: "buttonQuestrion",
          GROUP_POSITION: [2.21, -0.38, -4.07],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1, 1.9, 1],
          QUESTRION_NAME: "buttonQuestrion",
          QUESTRION_POSITION: [0, 0, 0],
          QUESTRION_ROTATION: [0, 90, 0],
          QUESTRION_SCALE: [0.71, 1, 0.71],
        },
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [0.7, 0, 1.5],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.6, 0, 1.5],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [5, 1, 5, 1, 1, 1],
      positionGLTF: [36.88, -0.6, 5.04],
      scaleToGLTF: [25, 25, 25],
      type: "ampermeter",
    },
    watmeter: {
      url: "/models/wattmeter/wattmetr.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {},
      meta: {
        defaultSettings: {
          name: "wat",
          maxValue: "9999999E+55"
        },
        deviceSettings: {
          scale: [
            { id: uuid.v4(), name: 'мили', isActive: false },
            { id: uuid.v4(), name: '1:1', isActive: false },
            { id: uuid.v4(), name: 'кило', isActive: true }
          ],
          kindOfCurrent: [
            { id: uuid.v4(), name: 'Постоянный(=)', isActive: false },
            { id: uuid.v4(), name: 'Переменный(≈)', isActive: true }
          ]
        }
      },
      component: [
        {
          id: uuid.v4(),
          position: [0.08, -0.17, -2.12],
          rotation: [0, 0, 0],
          scale: [4.3, 0.6, 3],
          type: "display",
          GLTF_NAME: "display",
          GLTF_POSITION: [2.04, -0.12, 0.53],
          GLTF_PIVOT: [-1.43, 0.55, -0.23],
          GLTF_ROTATION: [0, 121.58, 0], //GLTF_ROTATION[1] === value for display min = 121.58 max = 117.18
          GLTF_QUATERNION: [90, 0, 0, 190],
          GLTF_SCALE: [150, 0.06, 200],
          GLTF_URL: "/models/wattmeter/strelka.gltf",
        },
        {
          id: uuid.v4(),
          type: "displayCustomTexture",
          value: "99999",
          color: "#000",
          textParams: { fillTextXAxias: 10, fillTextYAxias: 15, font: "14pt a_lcdnova", width: 70, height: 20 },
          GROUP_NAME: "displayCustomTexture-number",
          GROUP_POSITION: [0.74, -0.25, -0.13],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [0.73, 0.26, 0.82],
          MESH_SCALE: [2.26, 0.26, 0.56, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "buttonQuestrion",
          GROUP_NAME: "buttonQuestrion",
          GROUP_POSITION: [2.78, -0.31, -4.07],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1, 1.9, 1],
          QUESTRION_NAME: "buttonQuestrion",
          QUESTRION_POSITION: [0, 0, 0],
          QUESTRION_ROTATION: [0, 90, 0],
          QUESTRION_SCALE: [0.71, 1, 0.71],
        },
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.7, 0, 1.4],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.2, 0, 1.4],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [1.15, 0, 1.4],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [2, 0, 1.4],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [5, 1, 5, 1, 1, 1],
      positionGLTF: [26.22, -0.6, 4.97],
      scaleToGLTF: [25, 25, 25],
      type: "watmeter",
    },
    oscilograf: {
      url: "/models/oscilloscope/oscilograf_v1.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          timeScale: "5.00000Е-006",
          minY: "5.00000Е-006",
          maxY: "5.00000Е-006",
        },
        measurementQuantity: {
          voltage: true,
          pairPotential: false,
          tableData: [
            {
              id: uuid.v4(),
              timeXAsias: { title: "Время", active: false, disabled: false },
              valueDivision: "100.00001",
              startCount: "100.00001",
            },
            {
              id: uuid.v4(),
              timeXAsias: { title: "Канал 1", active: false, disabled: false },
              valueDivision: "100.00001",
              startCount: "100.00001",
            },
            {
              id: uuid.v4(),
              timeXAsias: { title: "Канал 2", active: false, disabled: false },
              valueDivision: "100.00001",
              startCount: "100.00001",
            },
            {
              id: uuid.v4(),
              timeXAsias: { title: "Канал 3", active: false, disabled: false },
              valueDivision: "100.00001",
              startCount: "100.00001",
            },
            {
              id: uuid.v4(),
              timeXAsias: { title: "Канал 4", active: false, disabled: false },
              valueDivision: "100.00001",
              startCount: "100.00001",
            },
            {
              id: uuid.v4(),
              timeXAsias: { title: "Канал 5", active: false, disabled: false },
              valueDivision: "100.00001",
              startCount: "100.00001",
            },
            {
              id: uuid.v4(),
              timeXAsias: { title: "Канал 6", active: false, disabled: false },
              valueDivision: "100.00001",
              startCount: "100.00001",
            },
            {
              id: uuid.v4(),
              timeXAsias: { title: "Канал 7", active: false, disabled: false },
              valueDivision: "100.00001",
              startCount: "100.00001",
            },
            {
              id: uuid.v4(),
              timeXAsias: { title: "Канал 8", active: false, disabled: false },
              valueDivision: "100.00001",
              startCount: "100.00001",
            },
            {
              id: uuid.v4(),
              timeXAsias: { title: "Канал 9", active: false, disabled: false },
              valueDivision: "100.00001",
              startCount: "100.00001",
            },
          ]
        },
        autoformat: false,
        autoComlite: true
      },
      component: [
        {
          id: uuid.v4(),
          type: "chart",
          GROUP_NAME: "chart",
          GROUP_POSITION: [0.05, 0.51, -1.33],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [13.10, 1.02, 5.8],
          CHART_NAME: "chart",
          CHART_POSITION: [0, 1.31, -0.2],
          CHART_ROTATION: [-1.56, 0, 0],
          CHART_SCALE: [1, 1, 1],
          CHART_WIDTH: '12.8',
          CHART_HEIGHT: '6',
          CHART_SEGMENTS: '10'
        }
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-5.74, 0.4, 3.11],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 5, 5, 5],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-4.47, 0.4, 3.11],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 5, 5, 5],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-3.22, 0.4, 3.11],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 5, 5, 5],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-1.89, 0.4, 3.11],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 5, 5, 5],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.6, 0.4, 3.11],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 5, 5, 5],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [0.69, 0.4, 3.11],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 5, 5, 5],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [1.98, 0.4, 3.11],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 5, 5, 5],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [3.28, 0.4, 3.11],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 5, 5, 5],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [4.58, 0.4, 3.11],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 5, 5, 5],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [5.83, 0.4, 3.11],
          rotation: [0, 0, 0],
          scale: [0.6, 5, 0.6, 5, 5, 5],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [15, 0.6, 9, 1, 1, 1],
      positionGLTF: [0.81, 0, 0.97],
      scaleToGLTF: [30, 11, 30],
      type: "oscilograf",
    },
    oneComponentReostat: {
      url: "/models/reostat/reostat1.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          oneResistanceName: 5.0000e+32,
          twoResistanceName: 5.0000e+32,
          maxResistance: 5.0000e+32,
          maxVoltage: 5.0000e+32,
        },
        valueNumberPhases: 1
      },
      component: [
        {
          id: uuid.v4(),
          type: "buttonQuestrion",
          GROUP_NAME: "buttonQuestrion",
          GROUP_POSITION: [2.29, -0.31, -3.45],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1, 1.9, 1],
          QUESTRION_NAME: "buttonQuestrion",
          QUESTRION_POSITION: [0, 0, 0],
          QUESTRION_ROTATION: [0, 90, 0],
          QUESTRION_SCALE: [0.71, 1, 0.71],
        },
        {
          id: uuid.v4(),
          type: "reostatRange",
          value: 0,
          GROUP_NAME: "reostatRange",
          GROUP_POSITION: [-0.05, 1.34, 0.52],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [3.2, 1, 5],
        }
      ],
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.41, 0, 3.39],
          rotation: [0, 0, 0],
          scale: [0.43,  5, 0.45, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.41, 0, -2.29],
          rotation: [0, 0, 0],
          scale: [0.39,  5, 0.41, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [5, 0.6, 8, 1, 1, 1],
      positionGLTF: [7.49, -0.5, -0.03],
      scaleToGLTF: [30, 11, 30],
      nameToGLTF: "oneComponentReostat",
      type: "oneComponentReostat",
    },
    twoComponentReostat: {
      url: "/models/reostat/reostat2.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          oneResistanceName: 5.0000e+32,
          twoResistanceName: 5.0000e+32,
          maxResistance: 5.0000e+32,
          maxVoltage: 5.0000e+32,
        },
        valueNumberPhases: 1
      },
      component: [
        {
          id: uuid.v4(),
          type: "buttonQuestrion",
          GROUP_NAME: "buttonQuestrion",
          GROUP_POSITION: [2.92, -0.31, -3.45],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1, 1.9, 1],
          QUESTRION_NAME: "buttonQuestrion",
          QUESTRION_POSITION: [0, 0, 0],
          QUESTRION_ROTATION: [0, 90, 0],
          QUESTRION_SCALE: [0.71, 1, 0.71],
        },
        {
          id: uuid.v4(),
          type: "reostatRange",
          value: 0,
          GROUP_NAME: "reostatRange",
          GROUP_POSITION: [0.61, 1.34, 0.47],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [3.2, 1, 5],
        }
      ],
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.32, 0, 3.39],
          rotation: [0, 0, 0],
          scale: [0.43,  5, 0.45, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.32, 0, -2.29],
          rotation: [0, 0, 0],
          scale: [0.39,  5, 0.41, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [1.25, 0, 3.39],
          rotation: [0, 0, 0],
          scale: [0.43,  5, 0.45, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [1.25, 0, -2.29],
          rotation: [0, 0, 0],
          scale: [0.39,  5, 0.41, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [6, 0.6, 8, 1, 1, 1],
      positionGLTF: [-0.9, -0.5, -0.03],
      scaleToGLTF: [30, 11, 30],
      nameToGLTF: "twoComponentReostat",
      type: "twoComponentReostat",
    },
    threeComponentReostat: {
      url: "/models/reostat/reostat3.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          oneResistanceName: 5.0000e+32,
          twoResistanceName: 5.0000e+32,
          maxResistance: 5.0000e+32,
          maxVoltage: 5.0000e+32,
        },
        valueNumberPhases: 1
      },
      component: [
        {
          id: uuid.v4(),
          type: "buttonQuestrion",
          GROUP_NAME: "buttonQuestrion",
          GROUP_POSITION: [2.92, -0.31, -3.45],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1, 1.9, 1],
          QUESTRION_NAME: "buttonQuestrion",
          QUESTRION_POSITION: [0, 0, 0],
          QUESTRION_ROTATION: [0, 90, 0],
          QUESTRION_SCALE: [0.71, 1, 0.71],
        },
        {
          id: uuid.v4(),
          type: "reostatRange",
          value: 0,
          GROUP_NAME: "reostatRange",
          GROUP_POSITION: [0.67, 1.34, 0.42],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [4.5, 1, 5],
        }
      ],
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.61, 0, 3.38],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.45, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.98, 0, 3.38],
          rotation: [0, 0, 0],
          scale: [0.39, 5, 0.41, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [2.58, 0, 3.38],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.45, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.61, 0, -2.3],
          rotation: [0, 0, 0],
          scale: [0.39, 5, 0.41, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.98, 0, -2.3],
          rotation: [0, 0, 0],
          scale: [0.39, 5, 0.41, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [2.58, 0, -2.3],
          rotation: [0, 0, 0],
          scale: [0.39, 5, 0.41, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [7, 0.6, 8, 1, 1, 1],
      positionGLTF: [-10.17, -0.5, -0.03],
      scaleToGLTF: [30, 11, 30],
      nameToGLTF: "threeComponentReostat",
      type: "threeComponentReostat",
    },
    voltageSource: {
      url: "/models/Istochnik napryajeniya/main.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          name: "5.00000Е-006",
          EMF: "5.00000Е-006",
          internalResistance: "5.00000Е-006",
          frequencyGz: "5.00000Е-006",
          voltageLimit: "5.00000Е-006",
          minFrequencyGz: "5.00000Е-006",
          maxFrequencyGz: "5.00000Е-006",
        },
        kindOfCurrent: {
          alternatingCurrent: false,
          DC: true,
          compound: [
            { id: uuid.v4(), name: "Звезда", active: false, disabled: false },
            { id: uuid.v4(), name: "треугольник", active: false, disabled: false },
          ],
          adjustment: [
            { id: uuid.v4(), name: "частоты", active: false, disabled: false },
            { id: uuid.v4(), name: "напряжения", active: false, disabled: false },
          ]
        },
        numberOfPhases: [
          { designation: '№', data: ['1', '2', '3'] },
          { designation: 'Фаза (град)', data: [240.00001, 240.00001, 240.00001] },
        ]
      },
      component: [
        {
          id: uuid.v4(),
          type: "range",
          value: 30,
          RANGE_NAME: "range",
          RANGE_POSITION: [0.35, 0.19, -2.44],
          RANGE_ROTATION: [0, 0, 0],
          RANGE_SCALE: [3.67, 1.53, 3.18],
          RANGE_RING_POSITION: [0.07, -0.27, 0.49],
          RANGE_RING_ROTATION: [90, 0, -180],
          RANGE_RING_SCALE: [0.4, 0.4, 0.5],
        },
        {
          id: uuid.v4(),
          position: [0.08, -0.17, -2.12],
          rotation: [0, 0, 0],
          scale: [4.3, 0.6, 3],
          type: "display",
          GLTF_NAME: "display",
          GLTF_POSITION: [6.23, 0.33, 5.19],
          GLTF_PIVOT: [-1.43, 0.55, -0.23],
          GLTF_ROTATION: [0, 121.58, 0], //GLTF_ROTATION[1] === value for display min = 121.58 max = 117.18
          GLTF_QUATERNION: [90, 0, 0, 190],
          GLTF_SCALE: [150, 0.06, 200],
          GLTF_URL: "/models/Istochnik napryajeniya/strelka.gltf",
        },
        {
          id: uuid.v4(),
          type: "displayCustomTexture",
          value: "30 %",
          adaptive: true,
          color: "#fff",
          textParams: { fillTextXAxias: 0, fillTextYAxias: 20, font: "14pt a_lcdnova", width: 50, height: 40 },
          GROUP_NAME: "displayCustomTexture-procent",
          GROUP_POSITION: [0.59, -0.32, -1.95],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1.58, 0.85, 0.71],
          MESH_SCALE: [1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "displayCustomTexture",
          value: "00010",
          color: "#000",
          textParams: { fillTextXAxias: 10, fillTextYAxias: 15, font: "14pt a_lcdnova", width: 70, height: 20 },
          GROUP_NAME: "displayCustomTexture-number",
          GROUP_POSITION: [4.91, 0.13, 4.77],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [2.26, 0.26, 0.56],
          MESH_SCALE: [2.26, 0.26, 0.56, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "displayCustomTexture",
          value: "F=50, 0 Гц",
          color: "#A1ACBD",
          textParams: { fillTextXAxias: 50, fillTextYAxias: 50, font: "20pt Montserrat", width: 250, height: 70 },
          GROUP_NAME: "displayCustomTexture-info",
          GROUP_POSITION: [5.03, -0.1, -0.97],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [2.26, 0.26, 0.56],
          MESH_SCALE: [2.26, 0.26, 0.56, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "buttonQuestrion",
          GROUP_NAME: "buttonQuestrion",
          GROUP_POSITION: [6.77, -0.26, -3.95],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1, 1.9, 1],
          QUESTRION_NAME: "buttonQuestrion",
          QUESTRION_POSITION: [0, 0, 0],
          QUESTRION_ROTATION: [0, 90, 0],
          QUESTRION_SCALE: [1, 1, 1],
        },
        {
          id: uuid.v4(),
          deviceId: uuid.v4(),// одинаковый с ид девайса
          type: "buttonOffOrIn",
          state: 'on',
          GROUP_NAME: "buttonOffOrIn",
          GROUP_POSITION: [-4.54, 0.56, -1.26],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1.47, 1.9, 1.45],
          LEVER_ARM_NAME: "lever arm",
          LEVER_ARM_POSITION: [0, -0.65, 0.219],
          LEVER_ARM_ROTATION: [0, 0, 0],
          LEVER_ARM_SCALE: [1.5, 0.2, 0.6, 1, 1, 1],
        }
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-3.21, 0, 3.31],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.45, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.34, 0, 3.32],
          rotation: [0, 0, 0],
          scale: [0.39, 5, 0.41, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [6.97, 0, -1.9],
          rotation: [0, 0, 0],
          scale: [0.44, 5, 0.44, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [15, 0.6, 9, 1, 1, 1],
      positionGLTF: [45, -0.5, 15],
      scaleToGLTF: [30, 11, 30],
      nameToGLTF: "voltageSource",
      type: "voltageSource",
    },
    rezistor: {
      url: "/models/kondendator_rez_ind/rezistor.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          name: "rez",
          resistanceOhm: "5.00000Е-006",
          maxVoltageA: "5.00000Е-006"
        },
      },
      component: [
        {
          id: uuid.v4(),
          type: "buttonQuestrion",
          GROUP_NAME: "buttonQuestrion",
          GROUP_POSITION: [2.13, -0.45, -3.54],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1, 1.9, 1],
          QUESTRION_NAME: "buttonQuestrion",
          QUESTRION_POSITION: [0, 0, 0],
          QUESTRION_ROTATION: [0, 90, 0],
          QUESTRION_SCALE: [1, 0.47, 1],
        },
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [0.04, 0, 3.02],
          rotation: [0, 0, 0],
          scale: [0.5, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.04, 0, -2.08],
          rotation: [0, 0, 0],
          scale: [0.5, 5, 0.5, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [6, 0.6, 9, 1, 1, 1],
      positionGLTF: [-7.17, -0.5, 0],
      scaleToGLTF: [30, 11, 30],
      type: "rezistor",
    },
    capacitor: {
      url: "/models/kondendator_rez_ind/kondensator.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          scaleInTime: "5.00000Е-007",
          capacity: "5.00000Е-006",
          maxVoltageB: "5.00000Е-006"
        },
      },
      component: [
        {
          id: uuid.v4(),
          type: "buttonQuestrion",
          GROUP_NAME: "buttonQuestrion",
          GROUP_POSITION: [2.13, -0.45, -3.54],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1, 1.9, 1],
          QUESTRION_NAME: "buttonQuestrion",
          QUESTRION_POSITION: [0, 0, 0],
          QUESTRION_ROTATION: [0, 90, 0],
          QUESTRION_SCALE: [1, 0.47, 1],
        },
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.37, 0, -1.8],
          rotation: [0, 0, 0],
          scale: [0.5, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.44, 0, -1.8],
          rotation: [0, 0, 0],
          scale: [0.5, 5, 0.5, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [6, 0.6, 9, 1, 1, 1],
      positionGLTF: [0.32, -0.5, 0],
      scaleToGLTF: [30, 11, 30],
      type: "capacitor",
    },
    inductor: {
      url: "/models/kondendator_rez_ind/induct.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          name: "5.00000Е-007",
          inductanceGn: "5.00000Е-006",
          maxVoltageA: "5.00000Е-006"
        },
        dataChart: [0, 0, 50, 0, 0, 0, 50, 0, 50, 0, 50],
        corePresence: true,
        tableBodyData: [
          { amperage: 240.00001, LGn: 240.00001 },
          { amperage: 240.00001, LGn: 240.00001 },
          { amperage: 240.00001, LGn: 240.00001 },
          { amperage: 240.00001, LGn: 240.00001 },
          { amperage: 240.00001, LGn: 240.00001 },
          { amperage: 240.00001, LGn: 240.00001 },
          { amperage: 240.00001, LGn: 240.00001 },
          { amperage: 240.00001, LGn: 240.00001 },
          { amperage: 240.00001, LGn: 240.00001 },
          { amperage: 240.00001, LGn: 240.00001 },
        ]
      },
      component: [
        {
          id: uuid.v4(),
          type: "buttonQuestrion",
          GROUP_NAME: "buttonQuestrion",
          GROUP_POSITION: [2.08, -0.45, -2.71],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1, 1.9, 1],
          QUESTRION_NAME: "buttonQuestrion",
          QUESTRION_POSITION: [0, 0, 0],
          QUESTRION_ROTATION: [0, 90, 0],
          QUESTRION_SCALE: [1, 0.47, 1],
        },
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [0.08, 0, -2.22],
          rotation: [0, 0, 0],
          scale: [0.5, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.08, 0, 2.87],
          rotation: [0, 0, 0],
          scale: [0.5, 5, 0.5, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [6, 0.6, 9, 1, 1, 1],
      positionGLTF: [-14.69, -0.5, 0],
      scaleToGLTF: [30, 11, 30],
      type: "inductor",
    },
    oneComponentVendingMachine: {
      url: "/models/Automat/main.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          maxVoltageA: "5.00000Е-007",
          pinchCurrent: "5.00000Е-006",
          innerResist: "5.00000Е-006"
        },
        countItem: 1
      },
      component: [
        {
          id: uuid.v4(),
          deviceId: uuid.v4(),// одинаковый с ид девайса
          type: "buttonOffOrIn",
          state: 'on',
          GROUP_NAME: "buttonOffOrIn",
          GROUP_POSITION: [-0.09, 0.56, 0.24],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [0.59, 1.9, 1.45],
        }
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.09, 1, -1.57],
          rotation: [0, 0, 0],
          scale: [0.42, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.1, 1, 2.14],
          rotation: [0, 0, 0],
          scale: [0.48, 5, 0.53, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [5, 0.6, 8, 1, 1, 1],
      positionGLTF: [6.3, 0, 0.86],
      scaleToGLTF: [30, 11, 30],
      type: "oneComponentVendingMachine",
    },
    twoComponentVendingMachine: {
      url: "/models/Automat/main2.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          maxVoltageA: "5.00000Е-007",
          pinchCurrent: "5.00000Е-006",
          innerResist: "5.00000Е-006"
        },
        countItem: 2
      },
      component: [
        {
          id: uuid.v4(),
          deviceId: uuid.v4(),// одинаковый с ид девайса
          type: "buttonOffOrIn",
          state: 'on',
          GROUP_NAME: "buttonOffOrIn",
          GROUP_POSITION: [0.02, 0.56, 0.24],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [2.43, 1.9, 1.45],
        }
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [0.41, 1, 2.15],
          rotation: [0, 0, 0],
          scale: [0.47, 5, 0.44, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.38, 1, 2.12],
          rotation: [0, 0, 0],
          scale: [0.47, 5, 0.51, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [0.41, 1, -1.53],
          rotation: [0, 0, 0],
          scale: [0.47, 5, 0.44, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.38, 1, -1.53],
          rotation: [0, 0, 0],
          scale: [0.47, 5, 0.51, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [5, 0.6, 8, 1, 1, 1],
      positionGLTF: [0, 0, 0.86],
      scaleToGLTF: [30, 11, 30],
      type: "twoComponentVendingMachine",
    },
    threeComponentVendingMachine: {
      url: "/models/Automat/main3.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          maxVoltageA: "5.00000Е-007",
          pinchCurrent: "5.00000Е-006",
          innerResist: "5.00000Е-006"
        },
        countItem: 3
      },
      component: [
        {
          id: uuid.v4(),
          deviceId: uuid.v4(),// одинаковый с ид девайса
          type: "buttonOffOrIn",
          state: 'on',
          GROUP_NAME: "buttonOffOrIn",
          GROUP_POSITION: [0.02, 0.56, 0.24],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [2.43, 1.9, 1.45],
        }
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.72, 1, 2.12],
          rotation: [0, 0, 0],
          scale: [0.54, 5, 0.55, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.09, 1, 2.12],
          rotation: [0, 0, 0],
          scale: [0.54, 5, 0.55, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.88, 1, 2.12],
          rotation: [0, 0, 0],
          scale: [0.54, 5, 0.55, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.72, 1, -1.53],
          rotation: [0, 0, 0],
          scale: [0.54, 5, 0.55, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.09, 1, -1.53],
          rotation: [0, 0, 0],
          scale: [0.54, 5, 0.55, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.88, 1, -1.53],
          rotation: [0, 0, 0],
          scale: [0.54, 5, 0.55, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [5, 0.6, 8, 1, 1, 1],
      positionGLTF: [-7.23, 0, 0.86],
      scaleToGLTF: [30, 11, 30],
      type: "threeComponentVendingMachine",
    },
    terminal: {
      url: "/models/terminals/terminal_3.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        countItem: 3
      },
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [0.08, 1, 0.42],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.19, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [1.35, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [10, 1, 5],
      positionGLTF: [-7.23, 0, 0.86],
      scaleToGLTF: [30, 11, 30],
      nameToGLTF: "terminal",
      type: "terminal",
    },
    terminal_4: {
      url: "/models/terminals/terminal_4.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        countItem: 4
      },
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [0.08, 1, 0.42],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.19, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [1.35, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [2.56, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [10, 1, 5],
      nameToGLTF: "terminal",
      positionGLTF: [-14.75, 0, 0.86],
      scaleToGLTF: [30, 11, 30],
      type: "terminal",
    },
    terminal_5: {
      url: "/models/terminals/terminal_5.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        countItem: 5
      },
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [0.08, 1, 0.42],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.19, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [1.35, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [2.56, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [3.83, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [10, 1, 5],
      nameToGLTF: "terminal",
      positionGLTF: [-23.83, 0, 0.86],
      scaleToGLTF: [30, 11, 30],
      type: "terminal",
    },
    terminal_6: {
      url: "/models/terminals/terminal_6.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        countItem: 6
      },
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-2.96, 1, 0.42],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.73, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.42, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.83, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [2.16, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [3.37, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [10, 1, 5],
      nameToGLTF: "terminal",
      positionGLTF: [-36.02, 0, 0.86],
      scaleToGLTF: [30, 11, 30],
      type: "terminal",
    },
    terminal_7: {
      url: "/models/terminals/terminal_7.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        countItem: 7
      },
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-3.7, 1, 0.42],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-2.43, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.15, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.17, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [1.45, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [2.58, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [3.91, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        }
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [10, 1, 5],
      nameToGLTF: "terminal",
      positionGLTF: [-48.73, 0, 0.86],
      scaleToGLTF: [30, 11, 30],
      type: "terminal",
    },
    terminal_8: {
      url: "/models/terminals/terminal_8.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        countItem: 8
      },
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-6.13, 1, 0.42],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-4.88, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-3.64, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-2.38, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.1, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.16, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [1.33, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [2.67, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        }
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [19, 1, 5],
      nameToGLTF: "terminal",
      positionGLTF: [-12.09, 0, -6.53],
      scaleToGLTF: [30, 11, 30],
      type: "terminal",
    },
    terminal_9: {
      url: "/models/terminals/terminal_9.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        countItem: 9
      },
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-5.68, 1, 0.42],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-4.4, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-3.17, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.94, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.68, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.61, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [1.9, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [3.18, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [4.4, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        }
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [20, 1, 5],
      nameToGLTF: "terminal",
      positionGLTF: [-25.11, 0, -6.53],
      scaleToGLTF: [30, 11, 30],
      type: "terminal",
    },
    terminal_10: {
      url: "/models/terminals/terminal_10.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        countItem: 10
      },
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-5.18, 1, 0.42],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-3.93, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-2.66, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.37, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.11, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [1.21, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [2.32, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [3.65, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [4.9, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [6.13, 1, 0.43],
          rotation: [0, 0, 0],
          scale: [0.43, 5, 0.5, 1, 1, 1],
        }
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [20, 1, 5],
      nameToGLTF: "terminal",
      positionGLTF: [-39.6, 0, -6.53],
      scaleToGLTF: [30, 11, 30],
      type: "terminal",
    },
    elementEarth: {
      url: "/models/terminals/terminal_zemlya.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        countItem: 1
      },
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.25, 1, 0.73],
          rotation: [0, 0, 0],
          scale: [0.48, 5, 0.53, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [2, 0.6, 8, 1, 1, 1],
      positionGLTF: [-50.8, 0, -5.92],
      scaleToGLTF: [30, 11, 30],
      type: "elementEarth",
    },
    constantCurrentMachine: {
      url: "/models/engine/engine.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        DC: false,
        defaultSettings: {
          nomPowerWt: "5.00000Е-007",
          nomVoltageV: "5.00000Е-006",
          maxVoltageA: "5.00000Е-006",
          nomSpeedRPM: "5.00000Е-006",
          countPlus: "5.00000Е-006",
          numberOfPairsOfParallelBranches: "5.00000Е-006",
          momentOfInertiaOfAnchor: "5.00000Е-006",
          idleTorque: "5.00000Е-006",
          limitingResidual: "5.00000Е-006",
          maxStream: "5.00000Е-006",
          maxMagnetizing: "5.00000Е-006",
          degaussingActionAcrossRI: "5.00000Е-006",
        },
        simplifiedType: false,
        fieldsType: true,
        windingsCheckBoxTable: [
          { name: "ОДП", checked: true, id: uuid.v4() },
          { name: "ШОФ", checked: false, id: uuid.v4() },
          { name: "ЯО", checked: false, id: uuid.v4() },
          { name: "СОВ", checked: false, id: uuid.v4() },
          { name: "КО", checked: true, id: uuid.v4() },
        ],
        windingsTableCoils: [
          { n: "ШОВ", nob: -1, coils: 3584 },
          { n: "СОВ", nob: -1, coils: 0 },
          { n: "КО", nob: -1, coils: 340 },
          { n: "ОДГ", nob: -1, coils: 0 },
          { n: "ОД", nob: -1, coils: 503 },
        ],
        windingsTableInductance: [
          { n: "ШОВ", nob: -1, coils: 3584 },
          { n: "СОВ", nob: -1, coils: 0 },
          { n: "КО", nob: -1, coils: 340 },
          { n: "ОДГ", nob: -1, coils: 0 },
          { n: "ОД", nob: -1, coils: 503 },
        ],
        functionPTableForCharts: [
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
        ],
        dataChartFunctionTab: [90, -30, 0, 30, 0],
      },
      component: [
        {
          id: uuid.v4(),
          type: "outerShaft",
          direction: "179.07",
          name: "outer_shaft",
          position: [6.32, 0, -0.04],
          rotation: [0, 0, 0],
          scale: [1, 1, 1]
        },
        {
          id: uuid.v4(),
          type: "outerShaft",
          direction: "0",
          name: "outer_shaft",
          position: [-6.11, 0, -0.1],
          rotation: [0, 0, 0],
          scale: [1, 1, 1]
        }
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-3.79, 0.5, 3.06],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-3.02, 0.5, 3.06],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-2.05, 0.5, 3.06],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.24, 0.5, 3.06],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.34, 0.5, 3.06],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.44, 0.5, 3.06],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [1.42, 0.5, 3.06],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [2.21, 0.5, 3.06],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [3.9, 0.5, 3.06],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [3.17, 0.5, 3.06],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [10, 0.6, 10, 1, 1, 1],
      positionGLTF: [-2.26, 0, 7.39],
      scaleToGLTF: [30, 11, 30],
      type: "constantCurrentMachine",
    },
    asyncMachine: {
      url: "/models/engine/induction_engine.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        DC: false,
        defaultSettings: {
          nomPowerWt: "5.00000Е-007",
          nomVoltageV: "5.00000Е-006",
          maxVoltageA: "5.00000Е-006",
          nomSpeedRPM: "5.00000Е-006",
          countPlus: "5.00000Е-006",
          numberOfPairsOfParallelBranches: "5.00000Е-006",
          momentOfInertiaOfAnchor: "5.00000Е-006",
          idleTorque: "5.00000Е-006",
          limitingResidual: "5.00000Е-006",
          maxStream: "5.00000Е-006",
          limitingResidual: "5.00000Е-006",
          maxMagnetizing: "5.00000Е-006",
          degaussingActionAcrossRI: "5.00000Е-006",
        },
        simplifiedType: false,
        fieldsType: true,
        windingsCheckBoxTable: [
          { name: "ОДП", checked: true, id: uuid.v4() },
          { name: "ШОФ", checked: false, id: uuid.v4() },
          { name: "ЯО", checked: false, id: uuid.v4() },
          { name: "СОВ", checked: false, id: uuid.v4() },
          { name: "КО", checked: true, id: uuid.v4() },
        ],
        windingsTableCoils: [
          { n: "ШОВ", nob: -1, coils: 3584 },
          { n: "СОВ", nob: -1, coils: 0 },
          { n: "КО", nob: -1, coils: 340 },
          { n: "ОДГ", nob: -1, coils: 0 },
          { n: "ОД", nob: -1, coils: 503 },
        ],
        windingsTableInductance: [
          { n: "ШОВ", nob: -1, coils: 3584 },
          { n: "СОВ", nob: -1, coils: 0 },
          { n: "КО", nob: -1, coils: 340 },
          { n: "ОДГ", nob: -1, coils: 0 },
          { n: "ОД", nob: -1, coils: 503 },
        ],
        functionPTableForCharts: [
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
          { fEqualsA: 240.00001, fr: 240.00001 },
        ],
        dataChartFunctionTab: [90, -30, 0, 30, 0],
      },
      component: [
        {
          id: uuid.v4(),
          type: "outerShaft",
          direction: "179.07",
          name: "outer_shaft",
          position: [6.3, 0, -0.27],
          rotation: [0, 0, 0],
          scale: [1, 1, 1]
        },
        {
          id: uuid.v4(),
          type: "outerShaft",
          direction: "0",
          name: "outer_shaft",
          position: [-6.19, 0, -0.27],
          rotation: [0, 0, 0],
          scale: [1, 1, 1]
        },
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-2.29, 0, 2.96],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-1.53, 0, 2.96],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.72, 0, 2.96],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [0.71, 0, 2.96],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [1.53, 0, 2.96],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [2.32, 0, 2.96],
          rotation: [0, 0, 0],
          scale: [0.63, 5, 0.59, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [10, 0.6, 10, 1, 1, 1],
      positionGLTF: [12.69, 0, 7.27],
      scaleToGLTF: [30, 11, 30],
      type: "asyncMachine",
    },
    dadCouplingSleeve: {
      url: "/models/engine/mufta.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {},
      connectedDeviceId: null,
      deviceType: "DAD",
      inDevice: {
        name: "dadCouplingSleeveInDevice",
        position: [0.51, 0, 0],
        rotation: [0, 0, 0],
        scale: [-0.11, 1, 0.15, 1, 1, 1],
        connection: null
      },
      outDevice: {
        name: "dadCouplingSleeveOutDevice",
        position: [-0.54, 0, 0],
        rotation: [0, 0, 0],
        scale: [-0.11, 1, 0.15, 1, 1, 1],
        connection: null
      },
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [1, 1, 1, 1, 1, 1],
      positionGLTF: [0, 0, 0],
      scaleToGLTF: [2, 2, 2],
      type: "couplingSleeve",
    },
    mumCouplingSleeve: {
      url: "/models/engine/mufta.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {},
      connectedDeviceId: null,
      deviceType: "MUM",
      inDevice: {
        name: "mumCouplingSleeveInDevice",
        position: [0.51, 0, 0],
        rotation: [0, 0, 0],
        scale: [-0.11, 1, 0.15, 1, 1, 1],
      },
      outDevice: {
        name: "mumCouplingSleeveOutDevice",
        position: [-0.54, 0, 0],
        rotation: [0, 0, 0],
        scale: [-0.11, 1, 0.15, 1, 1, 1],
      },
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [1, 1, 1, 1, 1, 1],
      positionGLTF: [0, 0, 0],
      scaleToGLTF: [2, 2, 2],
      type: "couplingSleeve",
    },
    onePhaseTransformer: {
      url: "/models/trasformator/transformator1.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          power: 2.2e+3,
          voltage: 2.2e+2,
          turningNumber1: 220,
          resistive1: 1e-3,
          inductance1: 1e-3,
          turningNumber2: 440,
          resistive2: 4e-3,
          inductance2: 4e-3,
          table: [],
        },
        deviceSettings: {
          phaseNumber: [
            { id: uuid.v4(), name: '1-фазный', active: true },
            { id: uuid.v4(), name: '2-фазный', active: false },
            { id: uuid.v4(), name: '3-фазный', active: false },
          ],
          implementation: [
            { id: uuid.v4(), name: 'без измен. напряжения', active: true },
            { id: uuid.v4(), name: 'c измен. напряжения', active: false }
          ]
        },
        table: [
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
        ]
      },
      component: [
        {
          id: uuid.v4(),
          type: "verticalRange",
          value: 90,
          GROUP_NAME: "verticalRange",
          GROUP_POSITION: [-0.66, 1.34, -1.27],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1.95, 1, 3.77],
        }
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.63, 0, 1.44],
          rotation: [0, 0, 0],
          scale: [0.77, 5, 0.83, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.34, 0, 1.46],
          rotation: [0, 0, 0],
          scale: [0.77, 5, 0.83, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.63, 0, -3.89],
          rotation: [0, 0, 0],
          scale: [0.77, 5, 0.83, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.34, 0, -3.89],
          rotation: [0, 0, 0],
          scale: [0.77, 5, 0.83, 1, 1, 1],
        }
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [5, 1, 10, 1, 1, 1],
      positionGLTF: [-11.97, -0.6, -1.55],
      scaleToGLTF: [25, 25, 25],
      type: "onePhaseTransformer",
    },
    twoPhaseTransformer: {
      url: "/models/trasformator/transformator2.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          power: 2.2e+3,
          voltage: 2.2e+2,
          turningNumber1: 220,
          resistive1: 1e-3,
          inductance1: 1e-3,
          turningNumber2: 440,
          resistive2: 4e-3,
          inductance2: 4e-3,
          table: [],
        },
        deviceSettings: {
          phaseNumber: [
            { id: uuid.v4(), name: '1-фазный', active: true },
            { id: uuid.v4(), name: '2-фазный', active: false },
            { id: uuid.v4(), name: '3-фазный', active: false },
          ],
          implementation: [
            { id: uuid.v4(), name: 'без измен. напряжения', active: true },
            { id: uuid.v4(), name: 'c измен. напряжения', active: false }
          ]
        },
        table: [
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
        ]
      },
      component: [
        {
          id: uuid.v4(),
          type: "verticalRange",
          value: 50,
          GROUP_NAME: "verticalRange",
          GROUP_POSITION: [-0.15, 1.34, -1.27],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [2.95, 1, 3.77],
        }
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-1.2, 0, 1.46],
          rotation: [0, 0, 0],
          scale: [0.49, 5, 0.56, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.25, 0, 1.46],
          rotation: [0, 0, 0],
          scale: [0.49, 5, 0.56, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.53, 0, 1.46],
          rotation: [0, 0, 0],
          scale: [0.49, 5, 0.56, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.91, 0, 1.46],
          rotation: [0, 0, 0],
          scale: [0.49, 5, 0.56, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-1.2, 0, -3.88],
          rotation: [0, 0, 0],
          scale: [0.49, 5, 0.56, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.25, 0, -3.88],
          rotation: [0, 0, 0],
          scale: [0.49, 5, 0.56, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.53, 0, -3.88],
          rotation: [0, 0, 0],
          scale: [0.49, 5, 0.56, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.91, 0, -3.88],
          rotation: [0, 0, 0],
          scale: [0.49, 5, 0.56, 1, 1, 1],
        }
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [5, 1, 10, 1, 1, 1],
      positionGLTF: [-3.13, -0.6, -1.55],
      scaleToGLTF: [25, 25, 25],
      type: "twoPhaseTransformer",
    },
    threePhaseTransformer: {
      url: "/models/trasformator/transformator3.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          power: 2.2e+3,
          voltage: 2.2e+2,
          turningNumber1: 220,
          resistive1: 1e-3,
          inductance1: 1e-3,
          turningNumber2: 440,
          resistive2: 4e-3,
          inductance2: 4e-3,
          table: [],
        },
        deviceSettings: {
          phaseNumber: [
            { id: uuid.v4(), name: '1-фазный', active: true },
            { id: uuid.v4(), name: '2-фазный', active: false },
            { id: uuid.v4(), name: '3-фазный', active: false },
          ],
          implementation: [
            { id: uuid.v4(), name: 'без измен. напряжения', active: true },
            { id: uuid.v4(), name: 'c измен. напряжения', active: false }
          ]
        },
        table: [
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
          { id: uuid.v4(), FA: 240.00001, F: 240.00001 },
        ]
      },
      component: [
        {
          id: uuid.v4(),
          type: "verticalRange",
          value: 90,
          GROUP_NAME: "verticalRange",
          GROUP_POSITION: [-0.15, 1.34, -1.27],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [3.2, 1, 3.77],
        }
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [-1.79, 0, 1.43],
          rotation: [0, 0, 0],
          scale: [0.64, 5, 0.73, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.13, 0, 1.43],
          rotation: [0, 0, 0],
          scale: [0.64, 5, 0.73, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.35, 0, 1.43],
          rotation: [0, 0, 0],
          scale: [0.64, 5, 0.73, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.3, 0, 1.43],
          rotation: [0, 0, 0],
          scale: [0.64, 5, 0.73, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [1.17, 0, 1.43],
          rotation: [0, 0, 0],
          scale: [0.64, 5, 0.73, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [1.84, 0, 1.43],
          rotation: [0, 0, 0],
          scale: [0.64, 5, 0.73, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-1.79, 0, -3.92],
          rotation: [0, 0, 0],
          scale: [0.64, 5, 0.73, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-1.13, 0, -3.92],
          rotation: [0, 0, 0],
          scale: [0.64, 5, 0.73, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [-0.35, 0, -3.92],
          rotation: [0, 0, 0],
          scale: [0.64, 5, 0.73, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [0.3, 0, -3.92],
          rotation: [0, 0, 0],
          scale: [0.64, 5, 0.73, 1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "test",
          position: [1.17, 0, -3.92],
          rotation: [0, 0, 0],
          scale: [0.64, 5, 0.73, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [1.84, 0, -3.92],
          rotation: [0, 0, 0],
          scale: [0.64, 5, 0.73, 1, 1, 1],
        }
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [5, 1, 10, 1, 1, 1],
      positionGLTF: [5.03, -0.6, -1.55],
      scaleToGLTF: [25, 25, 25],
      type: "threePhaseTransformer",
    },
    mechanicalLoad: {
      url: "/models/meh_nagruzka/main.gltf",
      color: "#d3d523",
      id: uuid.v4(),
      material: {},
      meta: {
        defaultSettings: {
          name: "5.00000Е-007",
          measurementLimit: "5.00000Е-006",
          momentOfInertia: "5.00000Е-006",
          shaftTorque: "5.00000Е-006",
          maxMoment: "5.00000Е-006",
          voltageTransmissionRatio: "5.00000Е-006",
        },
        measuredValue: [
          { id: uuid.v4(), name: 'Частота вращения (рад)', active: false, disabled: false },
          { id: uuid.v4(), name: 'Частота вращения (об...)', active: false, disabled: false },
          { id: uuid.v4(), name: 'Угол (град)', active: true, disabled: false },
          { id: uuid.v4(), name: 'Момент (H/m)', active: false, disabled: false },
        ],
        scale: [
          { id: uuid.v4(), name: 'мили', active: false, disabled: false },
          { id: uuid.v4(), name: '1:1', active: true, disabled: false },
          { id: uuid.v4(), name: 'кило', active: false, disabled: false },
        ],
        shaftTorque: [
          { id: uuid.v4(), name: 'пассивный', active: false, disabled: false },
          { id: uuid.v4(), name: 'активный', active: true, disabled: false },
        ]
      },
      component: [
        {
          id: uuid.v4(),
          position: [0.08, -0.17, -2.12],
          rotation: [0, 0, 0],
          scale: [4.3, 0.6, 3],
          type: "display",
          GLTF_NAME: "display",
          GLTF_POSITION: [1.29, 0.24, 3.18],
          GLTF_PIVOT: [-1.43, 0.55, -0.23],
          GLTF_ROTATION: [0, 121.58, 0], //GLTF_ROTATION[1] === value for display min = 121.58 max = 117.18
          GLTF_QUATERNION: [90, 0, 0, 190],
          GLTF_SCALE: [150, 0.06, 200],
          GLTF_URL: "/models/meh_nagruzka/strelka.gltf",
        },
        {
          id: uuid.v4(),
          type: "outerShaft",
          direction: "179.07",
          name: "outer_shaft",
          position: [3.26, 0, -0.8],
          rotation: [0, 0, 0],
          scale: [1, 1, 1]
        },
        {
          id: uuid.v4(),
          type: "outerShaft",
          direction: "0",
          name: "outer_shaft",
          position: [-3.37, 0, -0.8],
          rotation: [0, 0, 0],
          scale: [1, 1, 1]
        },
        {
          id: uuid.v4(),
          type: "buttonQuestrion",
          GROUP_NAME: "buttonQuestrion",
          GROUP_POSITION: [1.76, -0.04, -3.21],
          GROUP_ROTATION: [0, 0, 0],
          GROUP_SCALE: [1, 1.9, 1],
          QUESTRION_NAME: "buttonQuestrion",
          QUESTRION_POSITION: [0, 0, 0],
          QUESTRION_ROTATION: [0, 90, 0],
          QUESTRION_SCALE: [1, 1, 1],
        },
        {
          id: uuid.v4(),
          type: "IncOrDecBtn",
          value: 0,
          RANGE_NAME: "IncOrDecBtn",
          INC_NAME: "increment btn",
          DEC_NAME: "decriment btn",
          RANGE_POSITION: [-0.09, 0.19, 2.82],
          RANGE_ROTATION: [0, 0, 0],
          RANGE_SCALE: [3, 1, 1],
          INC_POSITION: [1.06, 1, 0],
          INC_ROTATION: [0, 0, 0],
          INC_SCALE: [0.5, 1, 0.5],
          DEC_POSITION: [-1.05, 1, 0],
          DEC_ROTATION: [0, 0, 0],
          DEC_SCALE: [0.5, 1, 0.5],
        }
      ],
      circuit: {
        elements: [
          {
            id: 1,
            type: 1,
            terminals: [1, 2],
            value: 10,
          },
        ],
        map: [],
      },
      connectors: [
        {
          id: uuid.v4(),
          type: "test",
          position: [0.56, 0, -1.48],
          rotation: [0, 0, 0],
          scale: [0.52, 5, 0.52, 1, 1, 1],
        },
        {
          type: "test",
          id: uuid.v4(),
          position: [-0.74, 0, -1.48],
          rotation: [0, 0, 0],
          scale: [0.52, 5, 0.52, 1, 1, 1],
        },
      ],
      position: [20, 0, 10],
      rotation: [0, 0, 0],
      scale: [6, 1, 8, 1, 1, 1],
      positionGLTF: [22.97, -0.6, 6.23],
      scaleToGLTF: [25, 25, 25],
      type: "mechanicalLoad",
    },
  };
};
