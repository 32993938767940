import React from 'react'
import { Tabs as RBTabs, Tab } from 'react-bootstrap'
import InputValueData from '../../Views/Layout/InputValueData'
import isEqual from 'react-fast-compare'

export default class TerminalsComponentForSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabKey: 'settings',
      countItem: 0
    }
  }


  selectTab = (e) => {
    this.setState({
      activeTabKey: e
    })
  }

  handlerNumberPhases = (e) => {

  }

  handlerCountTerminals = (e) => {

  }
  handlerCountAutomat = (e) => {

  }

  setDataDevice = (props) => {
    const { element } = props
    const { countItem } = element.meta
    this.setState({
      countItem: countItem
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.element, prevProps.element)) {
      this.setDataDevice(this.props)
    }
  }

  componentDidMount() {
    this.setDataDevice(this.props)
  }

  render() {
    const {
      activeTabKey,
      countItem,
    } = this.state
    return (
      <React.Fragment>
        <span className={"vls-sidebar-device_title"}>РАЗВИЛКА</span>
        <RBTabs
          id="vls-sidebar_tabs_wraper"
          defaultActiveKey={'base'}
          activeKey={activeTabKey}
          onSelect={this.selectTab}
        >
          <Tab tabClassName={"vls-sidebar_tabs_wraper-tab"} eventKey="settings" title="Параметры">
            <div className={"vls-input_data-terminals"}>
              <InputValueData onChange={(this.handlerCountTerminals)} defaultValue={countItem} type={'number_custom'} firstDescription={"Количество клемм"} />
            </div>
            <div className='content-border-line'></div>
          </Tab>
        </RBTabs>
      </React.Fragment>
    )
  }
}