import React from 'react'
import ProjectToPersonalPage from "../ProjectToPersonalPage";
import { LIMIT_GET_PROJECTS } from '../../const';
import Api from '../../api'

const ApiProject = Api.ProjectApi
class Trash extends React.Component {
  constructor(props) {
    super(props)
  }
  getProjects = () =>{
    return ApiProject.getProjects({limite: LIMIT_GET_PROJECTS, is_trash: true})
  }
  render() {
    let { updatecomponent, stopupdate } = this.props
    updatecomponent = updatecomponent === "true"
    return (
      <>
        <ProjectToPersonalPage
          typePage={'trash'}
          title={'УДАЛЕННЫЕ ПРОЕКТЫ'}
          api={this.getProjects}
          updateComponent={updatecomponent}
          stopUpdate={stopupdate}
          limite={LIMIT_GET_PROJECTS}
        />
      </>
    )
  }
}

export default Trash