import React from "react";
import Button from "../../Views/Layout/Button";
import { NavLink } from "react-router-dom";
import Container from "../Container";
import { PATH } from "../../const";

const LandingFooter = (props) => {
  const { textDemo = "Попробовать DEMO", linkDemo = "#" } = props
  const clickScrollTop = (e) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };
  return (
    <footer className={"landing-footer"}>
      <Container>
        <div className="landing-footer-wrapper">
          <div className={"landing-footer-wrapper-top"}>
            <div className={"landing-footer-wrapper-info"}>
              <a href={PATH.MainPage}>
                <div className={"landing-footer-wrapper-info-logo"}>
                </div>
              </a>

              {/* <NavLink
                className={"landing-footer-wrapper-info-link"}
                to={linkDemo}
              >
                {textDemo}
              </NavLink> */}
            </div>
            <div className={"landing-footer-wrapper-menu"}>
              <nav>
                <ul>
                  <li>
                    <a href={"#link-service_info"}>О сервисе</a>
                  </li>
                  <li>
                    <a href={"#link-whom_is_it_suitable"}>Для кого</a>
                  </li>
                  {/* <li>
                    <a href={"#link-how_does_it_work"}>Инструкция</a>
                  </li> */}
                  <li>
                    <a href={PATH.Home}>Новости</a>
                  </li>
                  {/* <li>
                    <a href={PATH.MainPage}>Тарифы</a>
                  </li> */}
                  <li>
                    <a href={PATH.Faq}>FAQ</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={"landing-footer-wrapper-contact"}>
              <div className="landing-footer-wrapper-contact-items">
                <span className={"landing-footer-wrapper-contact-phone"}>
                8 800 234-50-56
                </span>
                <span className={"landing-footer-wrapper-contact-email"}>
                inbox@garpix.com
                </span>
              </div>
              <Button
                name={"Задать вопрос"}
                className={" landing-footer-wrapper-contact-button"}
              />
            </div>
          </div>
          <div className={"landing-footer-wrapper-bottom"}>
            <span className={"landing-footer-wrapper-bottom-license"}>
              © 2020 GARPIX. Все права защищены.{" "}
            </span>
            <div className={"landing-footer-wrapper-bottom-right"}>
              <NavLink
                className={"landing-footer-wrapper-bottom-politics"}
                to="#"
              >
                Политика конфиденциальности
              </NavLink>
              <Button
                onClick={clickScrollTop}
                name={""}
                className={" landing-footer-wrapper-bottom-btn"}
              />
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default LandingFooter;
