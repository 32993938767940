import React, { useState } from "react";

const MenuNewsHomePage = (props) => {
  const [idActive, setIdActive] = useState(0);
  return (
    <div className={"news-header-menu"}>
      <ul>
        <li
          className={`${idActive === 0 ? "active" : ""}`}
          onClick={(e) => {
            setIdActive(0)
            props.getCategories(props.activePage, {});
          }}
        >
          Все
        </li>
        <li
         className={`${idActive ===1 ? "active" : ""}`}
          onClick={(e) => {
            setIdActive(1)
            props.getCategories(props.activePage, { category: "1" });
          }}
        >
          Правительство
        </li>
        <li
          className={`${idActive === 2 ? "active" : ""}`}
          onClick={(e) => {
            setIdActive(2)
            props.getCategories(props.activePage, { category: "2" });
          }}
        >
          Энергетика
        </li>
        <li
          className={`${idActive === 3 ? "active" : ""}`}
          onClick={(e) => {
            setIdActive(3)
            props.getCategories(props.activePage, { category: "3" });
          }}
        >
          Достижения
        </li>
        <li
          className={`${idActive === 4 ? "active" : ""}`}
          onClick={(e) => {
            setIdActive(4)
            props.getCategories(props.activePage, { category: "4" });
          }}
        >
          Новинки
        </li>
        <li
          className={`${idActive === 5 ? "active" : ""}`}
          onClick={(e) => {
            setIdActive(5)
            props.getCategories(props.activePage, { category: "5" });
          }}
        >
          Отрасль
        </li>
      </ul>
    </div>
  );
};
export default MenuNewsHomePage;
