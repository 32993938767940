import { GLTFLoader } from "./GLTFLoader";
import {
  AnimationMixer,
  Vector3,
  BoxBufferGeometry,
  MeshLambertMaterial,
  Mesh,
} from "three";
import { Object3D } from "../../Object3D";
import { addEventCustomListener } from "../../utils";
import uuid from 'uuid'
import { DATA_OBJECT_SCENE } from "../../../constants";

class AbstractGLTF extends Object3D {
  constructor({
    scene,
    renderer,
    addRenderCall = (e) => {
      console.log("Не передан addRenderCall");
    },
    enableShadows,
    url,
    position,
    rotation,
    quaternion,
    scale,
    onLoadComplete,
    animation,
    visible,
    setAnimation,
    readyComponent,
    callback,
    parent,
    pivot,
    name,
    startLoadGLTF = () => console.log("Не передан обработчик старта загрузки!"),
    progressLoadGLTF = (e) => console.log("Не передан обработчик прогресса загрузки!", e),
    errorLoadGLTF = (e) => console.log("Не передан обработчик ошибки!", e),
  } = {}) {
    super();
    this.mixer = null;
    this.currentAnimation = null;
    this.clips = [];
    this.loader = new GLTFLoader();
    this.loader.crossOrigin = true;
    this.loader.manager.onLoad = () => {
      readyComponent();
      if (onLoadComplete) {
        onLoadComplete(this.obj);
        if (this.onPropsUpdate)
          this.onPropsUpdate({}, this.props);
      }
    }
    this.loader.manager.onError = errorLoadGLTF
    this.loader.manager.onStart = (data) => {
      startLoadGLTF();
    }

    this.loader.load(
      url,
      (data) => {
        this.obj = data.scene;
        this.obj.visible = visible;
        if (pivot) {
          let pivotGlobalPosition = new Vector3(pivot[0], pivot[1], pivot[2]);
          this.obj.pivot = pivotGlobalPosition;
          //!view pivot
          // this.createVisiblePivot(this.obj.localToWorld(pivotGlobalPosition));
        }
        if (quaternion) {
          this.setQuaternion(quaternion);
        }
        this.setPosition(position);
        this.obj.rotation.set(...rotation);
        this.obj.scale.set(...scale);
        addEventCustomListener(this.obj, callback);
        this.obj.name = name ? name : this.name;
        if (enableShadows) {
          this.obj.traverse(function (node) {
            // console.log('xx', node);
            if (node.isMesh || node.isLight) {
              // console.log('ok');
              node.castShadow = true;
              node.receiveShadow = true;
            }
          });
        }
        this.scene = parent ? parent : scene;
        this.addToScene(this.scene);
        this.uuid = uuid.v4();
        // debugger
        this.obj.castShadow = enableShadows;
        this.obj.receiveShadow = enableShadows;
        this.mixer = new AnimationMixer(this.obj);
        this.clips = data.animations;
        setAnimation(animation);
        addRenderCall((deltaSeconds) => {
          this.mixer.update(deltaSeconds);
        });
        readyComponent()
      },
      progressLoadGLTF
    );

  }

  createVisiblePivot = (pivot) => {
    let geometry = new BoxBufferGeometry(1, 1, 1);
    let material = new MeshLambertMaterial({
      wireframe: true,
      color: "#eb4034",
    });
    let mesh = new Mesh(geometry, material);
    mesh.position.setX(pivot.x);
    mesh.position.setY(pivot.y);
    mesh.position.setZ(pivot.z);
    mesh.name = "pivot";
    mesh.scale.set(1, 1, 1);
    mesh.on("click", (e) => {
    });
    this.obj.add(mesh);
  };
}

export default AbstractGLTF;
