import React from "react";
// import {
//     AmbientLight,
//     Box,
//     Canvas,
//     DirectionalLight,
//     PointLight,
//     SpotLight,
//     Grid,
//     OrbitControls,
//     PerspectiveCamera,
//     Raycast,
//     Sky,
//     Screenshot,
//     Sphere,
//     Cylinder,
//     GenericGroupObject,
// } from "../gengine";
import { Geometry, Mesh, Matrix4, Line, LineSegments,CatmullRomCurve3 } from "three";

/**
 * - глубокий поиск всех элементов подходящих по параметрам
 * @param {*} property
 * @param {*} value
 * @param {*} startNode
 */
const sercheAllObjectByProperty = (property, value, startNode) => {
    let result = [];
    /**
     * DFS
     * @param {*} start
     * @param {*} target
     */
    const getObjectByProperty = (start, target) => {
        if (start[property] === target) {
            // debugger
            // if (start.children.length)
            result.push(start);
        }
        for (var i = 0; i < start.children.length; i++) {
            getObjectByProperty(start.children[i], target);
        }
        return null;
    };
    getObjectByProperty(startNode, value);
    return result;
};

class SyncGeometry extends React.Component {
    constructor(props) {
        super();
        this.unicNameForCollectorComponent = "уникальное имя =)";
    }

    sycnManageSceneMesh = (props) => {
        const { scene, name = '' } = props;
        // debugger;
        // let allBox = sercheAllObjectByProperty("name", name, scene);
        // let verticesAll = []
        // let singleGeometry = new Geometry();
        // let materials = [];
        // allBox.forEach((item, i) => {
        //     item.updateMatrix(); // as needed
        //     //тут можно переопределить материал вынуть из старого нужные данные
        //     materials.push(item.material);

        //     //   item.geometry.applyMatrix(
        //     //     new Matrix4().makeTranslation(
        //     //       item.position.x,
        //     //       item.position.y,
        //     //       item.position.z
        //     //     )
        //     //   );
        //     verticesAll.push(...item.geometry.vertices)
        //     // singleGeometry.merge(item.geometry, item.matrix, i);
        //     item.geometry.dispose();
        //     item.material.dispose();
        //     item.parent.remove(item);
        // });
        // let numPoints = verticesAll.length;
        // let spline = new CatmullRomCurve3(verticesAll);
        // let geometry = new Geometry();
        // let splinePoints = spline.getPoints(numPoints);
        // geometry.lineDistancesNeedUpdate = true;
        // for (let i = 0; i < splinePoints.length; i++) {
        //     geometry.vertices.push(splinePoints[i]);
        // }
        // var mesh = new LineSegments(geometry, materials[0]);
        // mesh.name = this.unicNameForCollectorComponent;
        // scene.add(mesh);
        console.log("scene", scene);
    };

    clearMemory = (props) => {
        const { scene } = props;
        let allTrash = sercheAllObjectByProperty(
            "name",
            this.unicNameForCollectorComponent,
            scene
        );
        allTrash.forEach((element) => {
            //   if (element.geometry) element.geometry.dispose();
            //   if (element.material) element.material.dispose();

            element.parent.remove(element);
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.clearMemory(this.props);
            this.sycnManageSceneMesh(this.props);
        }
    }

    componentDidMount() {
        this.sycnManageSceneMesh(this.props);
    }

    render() {
        return null;
    }
}

export default SyncGeometry;
