import { AbstractObject } from "../../../gengine";
import DragControls from "drag-controls";
// import { DragControls } from "three/examples/jsm/controls/DragControls.js";
import { TransformControls } from "./TransformControls";
import * as THREE from "three";
import { checkCollisionDefault, checkCollisionCustomObjects, sercheAllObjectByProperty } from "../../../utils";
import serialized from '../../../utils/SerializedObject'
import { DATA_OBJECT_SCENE } from "../../../const";
import { Vector3 } from "three";
import connect from 'storeon/react/connect'

// this.object.updateMatrixWorld();
// this.object.parent.updateMatrixWorld();
class DragControlsCustom extends AbstractObject {
  constructor(props) {
    super(props);
    this.deltaTimeForUpdate = null
    this.state = {
      contextElement: null,
      daragConnector: false
    };
  }
  dragControl = (data) => {
    let {
      //default
      scene,
      camera,
      renderer,
      //custom
      hideDelay = 1000,
      object = null, // объект сцены по которым должно осуществлятся перемещение
      isShowAxis = {
        // отвечает за отображение осей по которым будет перемещятся объект
        x: true,
        y: false,
        z: true,
      },
      callbackDrag = () => { },
      callbackDragForCoplingSleeve = () => { }
    } = data;
    this.checkObjectChildForCouplingSleeve = (contextElement) => {
      const nameMum = DATA_OBJECT_SCENE.couplingSleeveMUM.name
      const nameDad = DATA_OBJECT_SCENE.couplingSleeveDAD.name
      const outerShaft = DATA_OBJECT_SCENE.outerShaft.name
      let obj = scene.getObjectByProperty("uuid", contextElement.uuid)
      if (obj) {
        for (let i = 0; i < obj.children.length; i++) {
          const element = obj.children[i];
          if (element.children && element.name === outerShaft) {
            for (let j = 0; j < element.children.length; j++) {
              const elementJ = element.children[j];
              if (elementJ.name === nameMum) {
                return true
              } else if (elementJ.name === nameDad) {
                return true
              }
            }
          }
        }
      }
      return false
    }
    /**
     * обрабатывает колизии с муфтами и прочими внутренними объектами 
     * !(для прочих доработать)
     * @param {*} event 
     */
    this.checkCollisionCouplingSleeve = (event) => {
      const { contextElement } = this.state
      let contextArrCooplingSleeve = []
      let obj = scene.getObjectByProperty("uuid", contextElement.uuid)
      const handlProcessingFoundCollision = (el, contextElement) => {
        const { object } = el
        if (object._customAttribute.type !== contextElement._customAttribute.type) {
          let dataDrag = {
            dragElement: contextElement.uuid,
            collizionObject: object.uuid,
            scene: scene
          }
          callbackDragForCoplingSleeve(dataDrag)
          return this.justDrugDeviceAndCollisionHandl(event)
        } else {
          return this.justDrugDeviceAndCollisionHandl(event)
        }
      }
      for (let i = 0; i < obj.children.length; i++) {
        const element = obj.children[i];
        if (element.name === DATA_OBJECT_SCENE.outerShaft.name) {
          contextArrCooplingSleeve.push(...element.children)
        }
      }
      contextArrCooplingSleeve.map((el, i) => {
        checkCollisionCustomObjects({
          scene: scene,
          contextElement: el,
          dataInsert: sercheAllObjectByProperty("name", DATA_OBJECT_SCENE.outerShaft.name, scene).filter(el2 => el2.uuid !== el.uuid),
          handlProcessingFoundCollision: handlProcessingFoundCollision
        })
      })
    }
    /**
     * для простого перемещения девайсов по сцене с последующим сохранением в стейт
     * @param {*} event 
     */
    this.justDrugDeviceAndCollisionHandl = (event) => {
      let basePositions = event.target.children[1].position;
      let isCollision = false;
      if (this.state.contextElement.name === DATA_OBJECT_SCENE.connector.name) {
        isCollision = checkCollisionDefault(scene, this.state.contextElement);
      }
      let newPositions = false;
      if (isCollision) {
        let minObjectCollision = {
          name: isCollision.object.name,
          uuid: isCollision.object.uuid,
          position: isCollision.object.position,
          parent: isCollision.object.parent,
          scene: scene,
        };
        callbackDrag({
          onCollisions: true,
          newPosition: isCollision.object.position,
          objectDrag: this.state.contextElement,
          lastConnector: minObjectCollision,
        });
      } else {
        callbackDrag({
          onCollisions: newPositions ? true : newPositions,
          newPosition: basePositions,
          objectDrag: this.state.contextElement,
        });
      }
    }
    /**
     *  обрабатывает перемещение муфты при колизии запихнет в объект с которым произошла колизияu
     * @param {*} event 
     */
    this.handlerCouplingSleeveCollision = (event) => {
      let isCollision = checkCollisionDefault(
        scene,
        this.state.contextElement,
        undefined,
        DATA_OBJECT_SCENE.outerShaft.name
      );
      if (isCollision) {
        let params = {
          contextElement: this.state.contextElement,
          objectDetectedCollizion: isCollision,
          scene: scene
        }
        serialized.couplingSleeveSerialazer(params)
      }
    }
    /**
     * простой обработчик для колизии проводов
     * !(старый и проверенный)
     * @param {*} event 
     */
    this.handlerConnectorCollision = (event) => {
      const { componentData } = this.props.dataAllObjects
      let basePositions = event.target.children[1].position;
      let isCollision = checkCollisionDefault(scene, this.state.contextElement);
      let newPositions = false;
      if (isCollision) {
        let minObjectCollision = {
          name: isCollision.object.name,
          uuid: isCollision.object.uuid,
          position: isCollision.object.position,
          parent: isCollision.object.parent,
          scene: scene,
        };
        let deviceType
        componentData.forEach(element => {
          if (element.id === isCollision.object.parent.uuid) {
            deviceType = element.type
          }
        })
        this.props.dispatch("ConnectedWireToDevice", {
          openInfoModal: true,
          deviceType: deviceType
        })
        callbackDrag({
          onCollisions: true,
          newPosition: isCollision.object.position,
          objectDrag: this.state.contextElement,
          lastConnector: minObjectCollision,
        });
      } else {
        callbackDrag({
          onCollisions: newPositions ? true : newPositions,
          newPosition: basePositions,
          objectDrag: this.state.contextElement,
        });
      }

    }
    /**
     * проверяет какой обработчик использовать если в объекте найден внешний вал тогда будет строится огбработка по другому
     * @param {*} event 
     */
    this.handlerDeviceConnectorCollision = (event) => {
      let checkObjectChildForCouplingSleeve = this.checkObjectChildForCouplingSleeve(this.state.contextElement)
      if (checkObjectChildForCouplingSleeve) {
        this.checkCollisionCouplingSleeve(event)
      } else {
        this.justDrugDeviceAndCollisionHandl(event)
      }
    }
    /**
     * !check
     * метод отключающий драг анд дроп с объекта и очищяющий память
     */
    this.cancelDragAndDrop = () => {
      this.props.detachForMemoryClear();
      clearMemory();
    }
    let hiding;
    /*
     Этот класс можно использовать для преобразования объектов в трехмерном
     пространстве путем адаптации аналогичной модели взаимодействия инструментов DCC,
     таких как Blender. В отличие от других элементов управления, он не предназначен
     для преобразования камеры сцены.
     */
    DragControls.install({ THREE: THREE });
    let transformControl = new TransformControls(camera, renderer.domElement);
    transformControl.name = DATA_OBJECT_SCENE.transformControler.name;
    // Настраиваю отображение осей
    transformControl.showY = isShowAxis.y;
    transformControl.showZ = isShowAxis.z;
    transformControl.showX = isShowAxis.x;
    transformControl.size = 1;
    transformControl.spase = "local"// "local";

    //собития тут как с обычным дом элементов можно отловить как клики так и мувы и т.д
    this.eventMouseUpCallback = (event) => {
      console.log("UP EVENT")
      if (this.state.contextElement) {
        switch (this.state.contextElement.name) {
          case DATA_OBJECT_SCENE.couplingSleeveDAD.name:
            this.handlerCouplingSleeveCollision(event)
            this.cancelDragAndDrop()
            transformControl.removeEventListener("mouseUp", this.eventMouseUpCallback);
            break
          case DATA_OBJECT_SCENE.couplingSleeveMUM.name:
            this.handlerCouplingSleeveCollision(event)
            this.cancelDragAndDrop()
            transformControl.removeEventListener("mouseUp", this.eventMouseUpCallback);
            break
          case DATA_OBJECT_SCENE.connector.name:
            // this.objects.position.set(this.objects.position.x, this.objects.position.y - 5, this.objects.position.z)
            this.handlerConnectorCollision(event)
            this.cancelDragAndDrop()
            transformControl.removeEventListener("mouseUp", this.eventMouseUpCallback);
            break
          case DATA_OBJECT_SCENE.deviceConnector.name:
            this.handlerDeviceConnectorCollision(event)
            this.cancelDragAndDrop()
            transformControl.removeEventListener("mouseUp", this.eventMouseUpCallback);
          default:
            return false
        }
      }
    }

    transformControl.addEventListener("objectChange", (e) => {
      if (!this.objects) return false
      if (this.objects.name === DATA_OBJECT_SCENE.connector.name) {
        if (this.state.daragConnector) {
          this.setState({
            daragConnector: false
          })
        }
        let uuidWire = null
        let vertices = []
        const { wiresData } = this.props.dataAllObjects
        // this.objects.position.set(this.objects.position.x, this.objects.position.y + 5, this.objects.position.z)
        wiresData.forEach(el => {
          el.vertices.forEach(el2 => {
            if (el2.id === this.objects.uuid) {
              uuidWire = el.id
              vertices = el.vertices.map(el => {
                if (el.id === this.objects.uuid) {
                  return this.objects.position
                } else {
                  if (Array.isArray(el.position)) {
                    return new Vector3(el.position[0], el.position[1], el.position[2])
                  }
                  return new Vector3(el.position.x, el.position.y, el.position.z)
                }
              })
            }
          })
        })
        if (uuidWire) {
          this.clearTemporaryLine()
          let line = this.props.scene.getObjectByProperty("uuid", uuidWire)
          let numPoints = 250;
          let spline = new THREE.CatmullRomCurve3(vertices);
          let geometry = new THREE.Geometry();
          let splinePoints = spline.getPoints(numPoints);
          for (let i = 0; i < splinePoints.length; i++) {
            geometry.vertices.push(splinePoints[i]);
          }
          let mesh = new THREE.Line(
            geometry,
            new THREE.LineBasicMaterial({
              color: "red",
              opacity: 1,
              linewidth: 4,
            })
          );
          scene.remove(line)
          mesh.name = DATA_OBJECT_SCENE.temporary_line.name;
          scene.add(mesh);
        }
      }
    })
    scene.add(transformControl);
    /*
      Этот класс может использоваться для обеспечения взаимодействия drag'n'drop.
      !свичем выбирает какие элементы можно добавить к драг анд дропу
     */
    this.objects = false;
    if (object) {
      this.objectDrag = scene.getObjectByProperty("uuid", object);
      if (this.objectDrag) {
        switch (this.objectDrag.name) {
          case "buttonOffOrIn":
          case "buttonQuestrion":
          case "range":
          case "verticalRange":
          case "increment btn":
          case "decriment btn":
          case "reostatRange":
          case DATA_OBJECT_SCENE.objectConnector.name:
            break;
          default:
            this.objects = this.objectDrag
        }
        if (this.objectDrag.name === DATA_OBJECT_SCENE.connector.name) {
          const { wiresData } = this.props.dataAllObjects
          let contextWire = false
          wiresData.forEach((el, i) => {
            el.connections.forEach((vertEl) => {
              if (vertEl.lastConnector.uuidConnector === object) {
                contextWire = vertEl
              }
              if (vertEl.firstConnector.uuidConnector === object) {
                contextWire = vertEl
              }
            })
          })
          if (!contextWire) {
            this.selectedObj(object)
          } else {
            this.objects = null
            this.objectDrag = null
          }
        } else {
          this.selectedObj(object)
        }
      }
    }
    let dragcontrols = new DragControls(
      this.objects ? [this.objects] : [],
      camera,
      renderer.domElement
    );
    dragcontrols.enabled = false;
    dragcontrols.name = DATA_OBJECT_SCENE.dragControls.name;
    transformControl.addEventListener("mouseUp", this.eventMouseUpCallback, false);
    transformControl.children[1].scale.set(999999999999, 999999999999, 999999999999)
    transformControl.children[1].position.set(
      transformControl.children[1].position.x,
      transformControl.children[1].position.y + 4,
      transformControl.children[1].position.z
    )
    // transformControl.position.set(
    //   transformControl.position.x,
    //   transformControl.position.y,
    //   transformControl.position.z
    // )
    /**
     * принимает геометрию и удаляет её попутно ищет объекты в сцене по имени и удаляет их
     * @param dragcontrols
     * @param transformControl
     */
    const clearMemory = () => {
      const { daragConnector } = this.state
      if (daragConnector) {
        this.setState({
          daragConnector: false
        })

        this.clearTemporaryLine()

      }
      transformControl.dispatchEvent("mouseUp")
      let transformController = sercheAllObjectByProperty("name", DATA_OBJECT_SCENE.transformControler.name, scene)
      let dragController = sercheAllObjectByProperty("name", DATA_OBJECT_SCENE.dragControls.name, scene)

      transformController.forEach(el => {
        el.detach();
        el.dispose();
        scene.remove(el);
      })
      dragController.forEach(el => {
        el.detach();
        el.dispose();
        scene.remove(el);
      })
      this.setState({
        contextElement: null
      })
    };

    /*
    тут мы очищяем тайм аут вызваный ранее
   */
    const cancelHideTransform = () => {
      if (hiding) clearTimeout(hiding);
    };

    /*
    тут мы создаем тайм аут который вызовет метод который скроет стрелки направления осей
   */
    const hideTransform = () => {
      hiding = setTimeout(this.cancelDragAndDrop, hideDelay);
    };

    const delayHideTransform = () => {
      hideTransform();
      cancelHideTransform();
    };

    /*
    По наведению на элемент будет высвечиватся контролер его разположения по осям
     */
    dragcontrols.on("hoveron", (event) => {
      let minContextElement = {
        name: event.object.name,
        uuid: event.object.uuid,
        position: event.object.position,
        geometry: event.object.geometry,
        matrix: event.object.matrix,
      };
      //todo:сделать выделение линией на основе вершин геометрии контекстного элемента
      this.setState(
        {
          contextElement: minContextElement,
        },
        () => {
          if (event.object.parent) {
            transformControl.attach(event.object)
          }
        }
      );
    });
    dragcontrols.on("hoveroff", (event) => {
      delayHideTransform()
    });
    // dragcontrols.on("dragend", () => {
    //   transformControl.dispatchEvent("mouseUp")
    // })
  };

  removeSelectedShader = () => {
    if (this.objects) {
      let ShaderSelectedObject = sercheAllObjectByProperty("name", DATA_OBJECT_SCENE.ShaderSelectedObject.name, this.props.scene)
      if (ShaderSelectedObject.length) {
        ShaderSelectedObject.forEach((el, i) => {
          el.material.dispose()
          el.geometry.dispose()
          el.parent.remove(el)
        })
      }
    }
  }

  clearTemporaryLine = () => {
    let temporary_line = sercheAllObjectByProperty("name", DATA_OBJECT_SCENE.temporary_line.name, this.props.scene)
    if (temporary_line.length) {
      temporary_line.forEach((el, i) => {
        el.material.dispose()
        el.geometry.dispose()
        el.parent.remove(el)
      })
    }
  }

  selectedObj = (uuid) => {
    // body_device - name for selected
    if (!this.objects) {
      return
    } else {
      this.removeSelectedShader()
      let geometry, matColor, matShader, outShader, uniforms;
      let shader = {
        'outline': {
          vertex_shader: [
            "uniform float offset;",
            "void main() {",
            "vec4 pos = modelViewMatrix * vec4( position + normal * offset, 1.0 );",
            "gl_Position = projectionMatrix * pos;",
            "}"].join("\n"),
          fragment_shader: [
            "void main(){",
            "vec3 color = vec3(0.);",
            "color = vec3(0.309,0.659,0.975);",
            "gl_FragColor = vec4(color,1.0);",
            "}"
          ].join("\n")
        }
      };
      const generateShaderMesh = (object) => {
        geometry = object.geometry
        matColor = object.material

        uniforms = {
          offset: {
            type: "f",
            value: 1
          }
        };
        outShader = shader['outline'];
        matShader = new THREE.ShaderMaterial({
          uniforms: uniforms,
          vertexShader: outShader.vertex_shader,
          fragmentShader: outShader.fragment_shader,
        });
        let shaderSelectedObject = new THREE.Mesh(geometry, matShader);
        shaderSelectedObject.material.depthWrite = false;
        shaderSelectedObject.name = DATA_OBJECT_SCENE.ShaderSelectedObject.name
        shaderSelectedObject.scale.set(object.scale.x + 0.01, object.scale.y - 1, object.scale.z + 0.01)
        object.add(shaderSelectedObject);
      }

      let parentObj = this.props.scene.getObjectByProperty("uuid", uuid)
      let object = parentObj.getObjectByProperty("name", DATA_OBJECT_SCENE.body_device.name)

      if (!object) return false
      if (object.type === 'Group') {
        object.children.forEach((el, i) => {
          generateShaderMesh(el)
        })
      } else {
        generateShaderMesh(object)
      }
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.dragControl(this.props);
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.dragControl(this.props);
  }

  render() {
    return null;
  }
}

export default connect("ConnectedWireToDevice", DragControlsCustom);