import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { PATH } from "../../const";
import SubmitForm from "../SubmitForm";
import Api from "../../api";
const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [textBottom, setTextBottom] = useState("");
  const [errorTextBottom, setErrorTextBottom] = useState("")
  const successText =
    "Письмо успешно отправлено на почту пройдите по ссылке в письме что-б обновить пароль!";

  const resetPass = (e) => {
    e.preventDefault();
    const userApi = Api.UserApi;
    if (!email) {
      setTextBottom("Введите корректный email");
      setErrorTextBottom("error-text_field");
      return;
    } else {
      SubmitForm(
        userApi.resetPassword,
        {
          email: email,
        },
        (e) => {
          setTextBottom(successText);
          setErrorTextBottom("");
        },
        (err) => {
          if (err.response.status === 400) {
            let { data } = err.response;
            if (data.email) {
              setTextBottom(data.email);
              setErrorTextBottom("error-text_field");
            }
          }
        }
      );
    }
  };
  return (
    <div
      className={
        "auth_and_reg-right_column-form forgot_password_custom_margin_top"
      }
    >
      <Form>
        <Form.Label className={"auth_and_reg-right_column-form-heder"}>
          Забыли пароль?
        </Form.Label>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Эл. почта</Form.Label>
          <Form.Control
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className={"auth_and_reg-right_column-form-input_style"}
            type="email"
            placeholder="Введите адрес эл.почты"
          />
        </Form.Group>
        <Button
          onClick={resetPass}
          className={"auth_and_reg-right_column-form-sign_in_btn"}
          type="submit"
        >
          Выслать новый пароль
        </Button>
        <Form.Group controlId="formBasicCheckbox">
          {/* <Form.Check type="checkbox" label="Check me out" /> */}
          <div
            className={`auth_and_reg-right_column-form-forgot_password_bottom_text ${errorTextBottom}`}
          >
            {textBottom}
          </div>
        </Form.Group>
      </Form>
    </div>
  );
};

export default ForgotPassword;
