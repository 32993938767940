export const generateSaveData = (data, type_name, title) => {
    const { componentData, couplingSleeve, map, wiresData } = data
    let deviceData = {}
    const getComponent = (data) => {
        // debugger
        if (!data) return {}
        if (!Array.isArray(data)) return {}
        // type: "outerShaft"
        let result = {}
        // debugger
        data.forEach((el, i) => {
            result[el.id] = el
        })
        return result
    }
    const setDeviceData = (arr = []) => {
        arr.map((el, i) => {
            deviceData[el.id] = {
                ...el,
                component: getComponent(el.component),
                hash_id: el.id,
                device_type: el.type
            }
        })
    }
    setDeviceData(componentData)
    setDeviceData(couplingSleeve)
    setDeviceData(wiresData)
    console.log('deviceData TO BACK', deviceData)
    return {
        "devices": deviceData,
        "map": map,
        "type_name": type_name,
        "title": title
    }
}