import React from 'react'
import ParamsDeviceValue from '../../Views/Layout/ParamsDeviceValue';
import { Tabs as RBTabs, Tab } from 'react-bootstrap'
import ChooseRadioButton from '../../Views/Layout/ChooseRadioButton'
import { v4 } from 'uuid'
import isEqual from 'react-fast-compare'
import Api from '../../api'
import SubmitForm from '../../common/SubmitForm'


const ApiResearch = Api.ResearchApi
export default class TachometerComponentForSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabKey: 'settings',
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Имя', value: 9.9999e+42 },
        { id: v4(), nameOfValue: 'Предел измерения', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Момент инерции', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Момент на валу:', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Max.момент', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Коэф.передачи по напряж.', value: 9.9999e+42, }
      ],
      valueRadioButton: [
        {
          options: [
            { id: v4(), name: 'Частота вращения (рад)', active: false, isDisabled: false },
            { id: v4(), name: 'Частота вращения (об...)', active: true, isDisabled: false },
            { id: v4(), name: 'Угол (град)', active: false, isDisabled: false },
            { id: v4(), name: 'Момент (H/m)', active: false, isDisabled: false },
          ]
        }
      ],
      valueRadioButtonSize: [
        {
          titleClassName: "vls-sidebar-title_device_setings",
          title: 'Масштаб',
          options: [
            { id: v4(), name: 'мили', active: false, isDisabled: false },
            { id: v4(), name: '1:1', active: true, isDisabled: false },
            { id: v4(), name: 'кило', active: false, isDisabled: false },
          ]
        },
        {
          titleClassName: "vls-sidebar-title_device_setings",
          title: 'Момент на валу',
          options: [
            { id: v4(), name: 'пассивный', active: false, isDisabled: false },
            { id: v4(), name: 'активный', active: true, isDisabled: false },
          ]
        }
      ]
    }
  }

  submitData = () => {
   let { hash_id, type } = this.props.element
    if (!hash_id) hash_id = this.props.element.id
    const {
      paramsDeviceDataNamefields,
      valueRadioButton,
      valueRadioButtonSize
    } = this.state
    let params = {
      device_id: hash_id,
      params: {
        type: type,
        meta: {
          defaultSettings: {
            name: paramsDeviceDataNamefields[0].value,
            measurementLimit: paramsDeviceDataNamefields[1].value,
            momentOfInertia: paramsDeviceDataNamefields[2].value,
            shaftTorque: paramsDeviceDataNamefields[3].value,
            maxMoment: paramsDeviceDataNamefields[4].value,
            voltageTransmissionRatio: paramsDeviceDataNamefields[5].value,
          },
          measuredValue: valueRadioButton[0].options,
          scale: valueRadioButtonSize[0].options,
          shaftTorque: valueRadioButtonSize[1].options,
        }
      }
    }
    SubmitForm(ApiResearch.putResearchUpdateDeviceData, params, this.successSubmit)
  }

  successSubmit = (response) => {
    console.log(response)
  }

  updateDeviceData = (data) => {
    this.setState(data, this.submitData)
  }

  selectTab = (e) => {
    this.setState({
      activeTabKey: e
    })
  }

  handlerNumberPhases = (e) => {

  }

  defaultDeviceDataChange = (e) => {
    const { paramsDeviceDataNamefields } = this.state
    let resultsUpdate = []
    for (let i = 0; i < paramsDeviceDataNamefields.length; i++) {
      const element = paramsDeviceDataNamefields[i];
      if (element.id === e.target.id) {
        resultsUpdate.push({
          ...element,
          value: e.target.value
        })
      } else {
        resultsUpdate.push(element)
      }
    }
    let newData = {
      paramsDeviceDataNamefields: resultsUpdate
    }
    this.updateDeviceData(newData)
  }

  changeChooseRadionBtnSize = (e) => {
    const { valueRadioButtonSize } = this.state
    const setvalueRadioButtonData = () => {
      return valueRadioButtonSize.map((el, i) => {
        let changeCkeck = false
        let result = el.options.map((elOption, i) => {
          if (elOption.id === e.target.id) {
            changeCkeck = true
            return {
              ...elOption,
              active: true
            }
          } else {
            return {
              ...elOption,
              active: false
            }
          }
        })
        return {
          ...el,
          options: changeCkeck ? result : el.options
        }

      })
    }

    let newData = {
      valueRadioButtonSize: setvalueRadioButtonData()
    }
    this.updateDeviceData(newData)
  }

  changeChooseRadionBtn = (e) => {
    const { valueRadioButton } = this.state
    const setvalueRadioButtonData = () => {
      return valueRadioButton.map((el, i) => {
        let changeCkeck = false
        let result = el.options.map((elOption) => {
          if (elOption.id === e.target.id) {
            changeCkeck = true
            return {
              ...elOption,
              active: true
            }
          } else {
            return {
              ...elOption,
              active: false
            }
          }
        })
        return {
          ...el,
          options: changeCkeck ? result : el.options
        }

      })
    }
    let newData = {
      valueRadioButton: setvalueRadioButtonData()
    }
    this.updateDeviceData(newData)
  }

  setDataDevice = (props) => {
    const { element } = props
    const {
      defaultSettings,
      measuredValue,
      scale,
      shaftTorque
    } = element.meta
    this.setState({
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Имя', value: defaultSettings.name },
        { id: v4(), nameOfValue: 'Предел измерения', value: defaultSettings.measurementLimit, },
        { id: v4(), nameOfValue: 'Момент инерции', value: defaultSettings.momentOfInertia, },
        { id: v4(), nameOfValue: 'Момент на валу:', value: defaultSettings.shaftTorque, },
        { id: v4(), nameOfValue: 'Max.момент', value: defaultSettings.maxMoment, },
        { id: v4(), nameOfValue: 'Коэф.передачи по напряж.', value: defaultSettings.voltageTransmissionRatio, }
      ],
      valueRadioButton: [
        {
          options: measuredValue
        }
      ],
      valueRadioButtonSize: [
        {
          titleClassName: "vls-sidebar-title_device_setings",
          title: 'Масштаб',
          options: scale
        },
        {
          titleClassName: "vls-sidebar-title_device_setings",
          title: 'Момент на валу',
          options: shaftTorque
        }
      ]
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.element, prevProps.element)) {
      this.setDataDevice(this.props)
    }
  }


  componentDidMount() {
    this.setDataDevice(this.props)
  }

  render() {
    const {
      activeTabKey,
      paramsDeviceDataNamefields,
      valueRadioButton,
      valueRadioButtonSize
    } = this.state
    return (
      <React.Fragment>
        <span className={"vls-sidebar-device_title"}>ТАХОМЕТР</span>
        <RBTabs
          id="vls-sidebar_tabs_wraper"
          defaultActiveKey={'base'}
          activeKey={activeTabKey}
          onSelect={this.selectTab}
        >
          <Tab tabClassName={"vls-sidebar_tabs_wraper-tab"} eventKey="settings" title="Параметры">
            <ParamsDeviceValue onChange={this.defaultDeviceDataChange} data={paramsDeviceDataNamefields} />
            <div className={"vls-tachometer"}>
              <span className={"vls-tachometer-title"}>Измеряемая величина</span>
              <ChooseRadioButton onClick={this.changeChooseRadionBtn} data={valueRadioButton} />
            </div>
            <br />
            <ChooseRadioButton onClick={this.changeChooseRadionBtnSize} data={valueRadioButtonSize} />
            <div className='content-border-line'></div>
          </Tab>
        </RBTabs>
      </React.Fragment>
    )
  }
}