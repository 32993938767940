export default {
  renderDots: [
    {
      type: "arc",
      radiusAStartPoint: 5,
      radiusAEndPoint: 5,
      startPoint: {
        coeficentR: 5,
        position: { x: -12.55358608708408, y: 0.05, z: -20.72278534152305 },
        x: -12.55358608708408,
        y: -20.72278534152305,
        countId: 1,
        uuidDot: "8b97d924-facf-470b-b619-000c6abec36a",
        edge: [],
        linkToStraight: []
      },
      centerPoint: {
        coeficentR: 5,
        position: { x: -19.673530434982514, y: 0.05, z: 0.41220735435444894 },
        x: -19.673530434982514,
        y: 0.41220735435444894,
        countId: 1,
        uuidDot: "be8197d8-ce90-4c04-9da4-d2940296e7f3",
        edge: [],
        linkToStraight: []
      },
      endPoint: {
        coeficentR: 5,
        position: { x: 3.4100786087303043, y: 0.05, z: -17.575020471924272 },
        x: 3.4100786087303043,
        y: -17.575020471924272,
        countId: 1,
        uuidDot: "baf85ce2-bde4-4362-a4e5-d77bfd4c02cc",
        edge: [],
        linkToStraight: []
      },
      verticesForArc: [
        { x: -12.553586087084057, y: 0, z: -20.722785341523036 },
        { x: -6.695946121671341, y: 0, z: -20.897024055678116 },
        { x: -1.2866229240789266, y: 0, z: -19.707670872185417 },
        { x: 3.4100786087303163, y: 0, z: -17.57502047192429 }
      ],
      linkConnetedDot: []
    },
    {
      type: "arc",
      radiusAStartPoint: 5,
      radiusAEndPoint: 5,
      startPoint: {
        coeficentR: 5,
        position: { x: 3.316336997934829, y: 0.05, z: -10.745793209545226 },
        x: 3.316336997934829,
        y: -10.745793209545226,
        countId: 4,
        uuidDot: "81ffc0f5-db83-4926-a838-e9b20bcb53e6",
        edge: [],
        linkToStraight: []
      },
      centerPoint: {
        coeficentR: 5,
        position: { x: -8.398515773990807, y: 0.05, z: -12.985397415943897 },
        x: -8.398515773990807,
        y: -12.985397415943897,
        countId: 4,
        uuidDot: "cfdd67a0-5498-4ae9-8957-6faea24a05a5",
        edge: [],
        linkToStraight: []
      },
      endPoint: {
        coeficentR: 5,
        position: { x: -13.351486615062308, y: 0.05, z: 10.530446751242117 },
        x: -13.351486615062308,
        y: 10.530446751242117,
        countId: 4,
        uuidDot: "219fce1d-5d7a-4933-866f-be0588fef64b",
        edge: [],
        linkToStraight: []
      },
      verticesForArc: [
        { x: 3.3163369979348545, y: 0, z: -10.74579320954524 },
        { x: 3.9808774815295465, y: 0, z: -4.149291033026884 },
        { x: 1.5468891236605486, y: 0, z: 2.0056595435926283 },
        { x: -2.845628010284827, y: 0, z: 6.587878386693774 },
        { x: -8.076906751351885, y: 0, z: 9.35354964890871 },
        { x: -13.3514866150623, y: 0, z: 10.530446751242152 }
      ],
      linkConnetedDot: []
    }
  ]
};

let testdata = [
  {
    position: { x: -10.844191096634093, y: -9.95, z: -9.676991491807227 },
    distance: null,
    coeficentR: 5,
    x: -10.844191096634093,
    y: -9.676991491807227,
    countId: 0,
    uuidDot: "48de408a-6acc-40c4-af0a-363a781d452d",
    edge: [
      {
        direction: 11.200029965755498,
        color: "red",
        idEndLink: "2e1a59ce-3271-45c4-9ba9-5dca6173493a",
        aproximationVertices: [
          {
            x: -5.845320982685153,
            y: -9.783281584798596,
            uuid: "25d7e72e-3a8a-46b9-ab07-aa837ce348a6"
          }
        ]
      }
    ],
    linkToStraight: ["2e1a59ce-3271-45c4-9ba9-5dca6173493a"]
  },
  {
    position: { x: 0.35330791759545277, y: -9.95, z: -9.91508193712048 },
    distance: null,
    coeficentR: 5,
    x: 0.3533082718109313,
    y: -9.91508192192057,
    countId: 1,
    uuidDot: "2e1a59ce-3271-45c4-9ba9-5dca6173493a",
    edge: [
      {
        direction: 69.7894449143377,
        color: "red",
        idEndLink: "325568f5-ab2d-41dc-9ea7-fbd09c0ab3f9",
        aproximationVertices: [
          {
            x: 2.6429296667532665,
            y: -5.470125919008695,
            uuid: "fba0e530-35ce-49b0-94df-5a8cfebffe5c"
          },
          {
            x: 4.932551061695602,
            y: -1.0251699160968197,
            uuid: "d546b975-3961-41e4-aa2e-eaa6956b9f51"
          },
          {
            x: 7.222172456637937,
            y: 3.419786086815055,
            uuid: "134c517b-f5bb-48f3-b8d1-f8f5ee58de45"
          },
          {
            x: 9.511793851580272,
            y: 7.864742089726931,
            uuid: "c43c17fa-ea22-4088-b687-7fa2820a7e9b"
          },
          {
            x: 11.801415246522607,
            y: 12.309698092638808,
            uuid: "a07e18e6-9d2b-4e44-ada8-d9c7dc8045a6"
          },
          {
            x: 14.091036641464942,
            y: 16.75465409555068,
            uuid: "5cb88a6b-4698-4b25-982d-50c0ad02dca3"
          },
          {
            x: 16.380658036407276,
            y: 21.199610098462557,
            uuid: "48753c7e-4416-419e-b053-55c31060c0ba"
          },
          {
            x: 18.670279431349613,
            y: 25.644566101374433,
            uuid: "e3942fa9-2410-4a92-93c0-3b560f5f0019"
          },
          {
            x: 20.95990082629195,
            y: 30.089522104286306,
            uuid: "abdfa1b7-03db-4b4e-8d6e-140f372242d4"
          },
          {
            x: 23.249522221234283,
            y: 34.53447810719818,
            uuid: "c5d1756e-93be-4205-b385-d7c96cef8d9d"
          },
          {
            x: 25.539143616176617,
            y: 38.97943411011006,
            uuid: "263aaab4-e1d6-43d7-8634-64663416c35b"
          },
          {
            x: 27.828765011118954,
            y: 43.42439011302193,
            uuid: "33b8382d-7ed0-4e88-a0b3-e90b94750b15"
          },
          {
            x: 30.11838640606129,
            y: 47.86934611593381,
            uuid: "5fa38a50-d87b-4867-8371-af1eb2b9f2e4"
          }
        ]
      }
    ],
    linkToStraight: ["325568f5-ab2d-41dc-9ea7-fbd09c0ab3f9"]
  },
  {
    position: { x: -46.05712323970514, y: -9.95, z: 31.060472529127583 },
    distance: null,
    coeficentR: 5,
    x: -46.05712323970514,
    y: 31.060472529127583,
    countId: 2,
    uuidDot: "cc7f0638-295b-44dd-9b7d-107699a3e4fc",
    edge: [
      {
        direction: 53.84692716366909,
        color: "red",
        idEndLink: "48de408a-6acc-40c4-af0a-363a781d452d",
        aproximationVertices: [
          {
            x: -42.787397927800114,
            y: 27.277762338710193,
            uuid: "ae09b5dc-69e0-48b5-86ce-7091ae093bf7"
          },
          {
            x: -39.51767261589509,
            y: 23.4950521482928,
            uuid: "61854f4f-e568-435f-8432-d1f809f13f6f"
          },
          {
            x: -36.24794730399006,
            y: 19.71234195787541,
            uuid: "7979e965-eef6-47ba-bf8f-524c9880d828"
          },
          {
            x: -32.97822199208503,
            y: 15.929631767458018,
            uuid: "45ffdfe7-f933-414c-b86e-da2c91ac2aae"
          },
          {
            x: -29.70849668018001,
            y: 12.146921577040626,
            uuid: "7e9f9dee-70af-47f4-9056-b47ccae4e179"
          },
          {
            x: -26.43877136827498,
            y: 8.364211386623236,
            uuid: "e5831eb5-6b29-49ab-99ff-50bdad8daac6"
          },
          {
            x: -23.169046056369954,
            y: 4.581501196205842,
            uuid: "eb25bc48-8cec-4302-ac28-b19325c97182"
          },
          {
            x: -19.899320744464926,
            y: 0.798791005788452,
            uuid: "ae2b023c-e72d-494d-bf49-362be9e808ae"
          },
          {
            x: -16.6295954325599,
            y: -2.9839191846289417,
            uuid: "483f97c1-2f1d-4738-9b7a-2b00ecd2f978"
          },
          {
            x: -13.359870120654875,
            y: -6.766629375046332,
            uuid: "6d1bd442-6433-475f-ba79-50ca202383c9"
          }
        ]
      }
    ],
    linkToStraight: ["48de408a-6acc-40c4-af0a-363a781d452d"]
  },
  {
    position: { x: 32.31158951521436, y: -9.95, z: 52.12712050045402 },
    distance: null,
    coeficentR: 5,
    x: 32.31058796076722,
    y: 52.147443797247085,
    countId: 3,
    uuidDot: "325568f5-ab2d-41dc-9ea7-fbd09c0ab3f9",
    edge: [],
    linkToStraight: []
  }
];
