import React from "react";
import Container from "../Container";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import ExempleImg from "../../assets/images/exempleImgForSliderLanding.jpg";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"landing-how_does_it_work-wrapper-slider-wrapper-arrow right"}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"landing-how_does_it_work-wrapper-slider-wrapper-arrow left"}
      onClick={onClick}
    />
  );
}

class HowDoesItWork extends React.Component {
  constructor(props) {
    super()
    this.state = {
      photos: [
        {
          type: "video",
          src: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
          title: "01. Бесплатный доступ",
          text:
            "Наш сервис позволяет создать один пробный проект бесплатно без регистрации. Использование сервиса и просмотр чужих проектов будут доступны после регистрации и выбора одного из тарифов.",
        },
        {
          type: "img",
          src: ExempleImg,
          title: "02. Бесплатный доступ",
          text:
            "Наш сервис позволяет создать один пробный проект бесплатно без регистрации. Использование сервиса и просмотр чужих проектов будут доступны после регистрации и выбора одного из тарифов.",
        },
        {
          type: "img",
          src: ExempleImg,
          title: "03. Бесплатный доступ",
          text:
            "Наш сервис позволяет создать один пробный проект бесплатно без регистрации. Использование сервиса и просмотр чужих проектов будут доступны после регистрации и выбора одного из тарифов.",
        },
        {
          type: "video",
          src: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
          title: "04. Бесплатный доступ",
          text:
            "ин пробный проект бесплатно без регистрации. Использование сервиса и просмотр чужих проектов будут доступны после регистрации и выбора одного из тарифов.",
        },
      ],
      nav1: null,
      nav2: null,
    }
    this.settingsMedia = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      centerPadding: 30,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      asNavFor: '.slider-content',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true,
            arrows: false
          }
        },
      ]
    };
    this.settingsContent = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      centerPadding: 30,
      slidesToScroll: 1,
      arrows: false,
      draggable: false,
      asNavFor: '.slider-media',
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }
  render() {
    const { photos } = this.state
    return (
      <section className={"landing-how_does_it_work"}>
        <div className="landing-how_does_it_work-wrapper" id={"link-how_does_it_work"}>
          <h3 className={"landing-how_does_it_work-wrapper-title"}>
            Как работает
          </h3>
          {photos.length ? (
            <div className={"landing-how_does_it_work-wrapper-slider"}>
              <Slider
                className="slider-media"
                {...this.settingsMedia}
                asNavFor={this.state.nav1, this.state.nav2}
                ref={slider => (this.slider1 = slider)}
              >
                {photos.map((el, i) => {
                  return (
                    <Container key={i}>
                      <React.Fragment key={i}>
                        <div
                          className={
                            "landing-how_does_it_work-wrapper-slider-wrapper"
                          }
                        >
                          <div
                            className={
                              "landing-how_does_it_work-wrapper-slider-wrapper-bg"
                            }
                          >
                            {el.type === "video" ? (
                              <div
                                className={
                                  "landing-how_does_it_work-wrapper-slider-wrapper-video_wrapper"
                                }
                              >
                                <ReactPlayer
                                  className="react-player"
                                  url={el.src}
                                  width="100%"
                                  height="100%"
                                  style={{
                                    borderRadius: "30px",
                                  }}
                                />
                              </div>
                            ) : (
                                <div
                                  className={
                                    "landing-how_does_it_work-wrapper-slider-wrapper-img_wrapper"
                                  }
                                >
                                  <img
                                    className={
                                      "landing-how_does_it_work-wrapper-slider-wrapper-img"
                                    }
                                    src={el.src}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      </React.Fragment>
                    </Container>
                  );
                })}
              </Slider>
              <Slider
                className="slider-content"
                {...this.settingsContent}
                asNavFor={this.state.nav2, this.state.nav1}
                ref={slider => (this.slider2 = slider)}
              >
                {photos.map((el, i) => {
                  return (
                    <Container key={i}>
                      <React.Fragment key={i}>
                        <div className="landing-how_does_it_work-wrapper-slider-content">
                          <h4
                            className={
                              "landing-how_does_it_work-wrapper-slider-title"
                            }
                          >
                            {el.title}
                          </h4>
                          <span
                            className={
                              "landing-how_does_it_work-wrapper-slider-text_content"
                            }
                          >
                            {el.text}
                          </span>
                        </div>
                      </React.Fragment>
                    </Container>
                  );
                })}
              </Slider>
            </div>
          ) : null}
        </div>
      </section>
    );
  }
};

export default HowDoesItWork;
