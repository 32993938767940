import React from 'react'
import { GenericGroupObject, GLTF, Box } from '../../../gengine'
import { DATA_OBJECT_SCENE } from '../../../const'
class CouplingSleeveCustom extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			renderComponent: []
		}
	}

	render() {
		if (!this.props.couplingSleeveData) return null;
		const {
			startLoadGLTF,
			progressLoadGLTF,
			errorLoadGLTF,
			readyComponentGLTF,
			handleToolTipsElement
		} = this.props;
		return this.props.couplingSleeveData.map((el, i) => {
			const {
				position,
				scale,
				rotation,
				url,
				id,
				deviceType,
				scaleToGLTF,
				positionGLTF,
				nameToGLTF = "device",
				inDevice,
				outDevice
			} = el;
			let groupName = deviceType === "DAD" ?
				DATA_OBJECT_SCENE.couplingSleeveDAD.name :
				DATA_OBJECT_SCENE.couplingSleeveMUM.name
			// debugger
			return (
				<GenericGroupObject
					key={i}
					visible={true}
					uuid={id}
					scale={scale}
					position={position}
					name={groupName}
					customAttribute={{
						type: deviceType
					}}
					color={deviceType === "DAD" ? "#000" : "green"}
					callback={[
						{
							type: "click",
							event: (ev) => {
								this.props.callbackOnClick(ev.data.target.uuid);
							},
						},
						{
							type: "rightclick",
							event: (ev) => {
								this.props.handleRightClick(ev);
							},
						},
					]}
					{...this.props}
				>
					{/* <GLTF
						onLoadComplete={readyComponentGLTF}
						startLoadGLTF={startLoadGLTF}
						progressLoadGLTF={progressLoadGLTF}
						errorLoadGLTF={errorLoadGLTF}
						scale={scaleToGLTF}
						name={nameToGLTF}
						position={positionGLTF}
						url={url}
					/> */}
					<Box
						name={inDevice.name}
						position={inDevice.position}
						rotation={inDevice.rotation}
						scale={inDevice.scale}
					/>
					<Box
						name={outDevice.name}
						position={outDevice.position}
						rotation={outDevice.rotation}
						scale={outDevice.scale}
						material={{
							color: "green"
						}}
					/>
				</GenericGroupObject>
			);
		});
	}
}

export default CouplingSleeveCustom