import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { ReactComponent as ReactLogo } from "../../../assets/images/authAnimate.svg";
import ErrorBlockAuth from "../../../common/ErrorBlockAuth";
import { PATH } from "../../../const";

class AuthorizationAndRegistrLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textError: "",
      isVisibleError: false,
    };
  }

  openErrorBlockHead = (text) => {
    this.setState({
      textError: text,
      isVisibleError: true,
    });
  };

  closeErrorBlockHead = () => {
    this.setState({
      textError: "",
      isVisibleError: false,
    });
  };

  render() {
    const { textError, isVisibleError } = this.state;
    return (
      <>
        <ErrorBlockAuth
          closeErrorBlockHead={this.closeErrorBlockHead}
          isVisible={isVisibleError}
          text={textError}
        />
        <Row className={"auth_and_reg"}>
          <Col
            className={"col3_custom_pading auth_and_reg-left_column-col_l"}
            xs={3}
          >
            <div className={"auth_and_reg-left_column"}>
              <a href={PATH.MainPage}>
                <div className={"auth_and_reg-left_column-logo"}>
                  <div className={"auth_and_reg-left_column-logo-text_logo"}>
                    Garpix
                  <br />
                  digital
                  <br />
                  twin
                </div>
                </div>
              </a>

              <div className={"auth_and_reg-left_column-text_under_logo"}>
                <p>Авторская технология создания </p>
                <p> цифровых двойников</p>
                <p> электрооборудования и энергосистемы.</p>
              </div>
              <ReactLogo
                width={"100%"}
                height={"100%"}
                className={"auth_and_reg-left_column-bottom_animate_img"}
              />
            </div>
          </Col>
          <Col
            className={"col3_custom_pading auth_and_reg-right_column-col_r"}
            xs={9}
          >
            <div className={"auth_and_reg-right_column"}>
              <div
                onClick={() => {
                  if (!this.props.children.props.history) {
                    this.props.history.goBack();
                  } else {
                    this.props.children.props.history.goBack();
                  }
                }}
                className={"auth_and_reg-right_column-back_btn"}
              >
                <span>Назад</span>
              </div>
              {React.Children.map(this.props.children, (child) =>
                React.cloneElement(child, {
                  openErrorBlockHead: this.openErrorBlockHead,
                  closeErrorBlockHead: this.closeErrorBlockHead,
                })
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
export default AuthorizationAndRegistrLayout;
