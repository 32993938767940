import React from 'react'
import ParamsDeviceValue from '../../Views/Layout/ParamsDeviceValue';
import { Tabs as RBTabs, Tab } from 'react-bootstrap'
import isEqual from 'react-fast-compare'
import v4 from 'uuid/v4';
import Api from '../../api'
import SubmitForm from '../../common/SubmitForm'


const ApiResearch = Api.ResearchApi
export default class CapacitorComponentForSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabKey: 'base',
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Имя', value: 9.9999e+42 },
        { id: v4(), nameOfValue: 'Ёмкость (Ф)', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Max. напряжение (В)', value: 9.9999e+42, },
      ]
    }
  }

  submitData = () => {
   let { hash_id, type } = this.props.element
    if (!hash_id) hash_id = this.props.element.id
    const {
      paramsDeviceDataNamefields
    } = this.state
    let params = {
      device_id: hash_id,
      params: {
        type: type,
        meta: {
          defaultSettings: {
            scaleInTime: paramsDeviceDataNamefields[0].value,
            capacity: paramsDeviceDataNamefields[1].value,
            maxVoltageB: paramsDeviceDataNamefields[2].value
          }
        }
      }
    }
    SubmitForm(ApiResearch.putResearchUpdateDeviceData, params, this.successSubmit)
  }

  successSubmit = (response) => {
    console.log(response)
  }

  updateDeviceData = (data) => {
    this.setState(data, this.submitData)
  }

  defaultDeviceDataChange = (e) => {
    const { paramsDeviceDataNamefields } = this.state
    let resultsUpdate = []
    for (let i = 0; i < paramsDeviceDataNamefields.length; i++) {
      const element = paramsDeviceDataNamefields[i];
      if (element.id === e.target.id) {
        resultsUpdate.push({
          ...element,
          value: e.target.value
        })
      } else {
        resultsUpdate.push(element)
      }
    }
    let newData = {
      paramsDeviceDataNamefields: resultsUpdate
    }
    this.updateDeviceData(newData)
  }

  handlerNumberPhases = (e) => {

  }

  setDataDevice = (props) => {
    const { element } = props
    const { defaultSettings } = element.meta
    this.setState({
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Масштаб по времени', value: defaultSettings.scaleInTime },
        { id: v4(), nameOfValue: 'Ёмкость (Ф)', value: defaultSettings.capacity },
        { id: v4(), nameOfValue: 'Max. напряжение (В)', value: defaultSettings.maxVoltageB },
      ]
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.element, prevProps.element)) {
      this.setDataDevice(this.props)
    }
  }


  componentDidMount() {
    this.setDataDevice(this.props)
  }

  render() {
    const {
      activeTabKey,
      paramsDeviceDataNamefields,      
    } = this.state
    return (
      <React.Fragment>
        <span className={"vls-sidebar-device_title"}>КОНДЕНСАТОР</span>
        <RBTabs
          id="vls-sidebar_tabs_wraper"
          defaultActiveKey={'base'}
          activeKey={activeTabKey}
        // onSelect={this.selectTab}
        >
          <Tab tabClassName={"vls-sidebar_tabs_wraper-tab"} eventKey="base" title="Параметры">
            <ParamsDeviceValue onChange={this.defaultDeviceDataChange} classNameForInput={"vls-sidebar_tabs_wraper-tab-input"} data={paramsDeviceDataNamefields} />
          </Tab>
        </RBTabs>
      </React.Fragment>
    )
  }
}