import serialized from '../../utils/SerializedObject'

class DragController {
  constructor({
    onCollisions,
    newPosition,
    objectDrag,
    lastConnector,
  }) {
    this.objectDrag = objectDrag;
    this.onCollisions = onCollisions
    this.newPosition = newPosition
    this.minObjDrag = {}
    this.lastConnector = lastConnector
    if (this.onCollisions && this.newPosition && this.lastConnector) {
      let paramsToSerialized = {
        objectDrag: this.objectDrag,
        lastConnector: this.lastConnector,
      };
      //Тут обрабатываются колизии и крепятся объекты
      let newData = serialized.collisionsHandlerWiresSerialized(
        paramsToSerialized
      )
      this.lastConnector = newData.lastConnector
        ? newData.lastConnector
        : false;
      this.objectDrag = newData.objectDrag;
    } else {
      this.lastConnector = false;
    }
    //небольшая оптимизация (только нужные параметры объекта)  
    this.minObjDrag = {
      name: this.objectDrag.name,
      uuid: this.objectDrag.uuid,
      parentUuid: this.objectDrag.parentUuid,
      uuidConnector: this.objectDrag.uuidConnector,
      position: this.objectDrag.position,
    };


  }

  getData = () => {
    // let data = await serialized.collisionsHandlerWiresSerialized()
    return {
      objectDrag: this.objectDrag,
      onCollisions: this.onCollisions,
      newPosition: this.newPosition,
      minObjDrag: this.minObjDrag,
      lastConnector: this.lastConnector
    }
  }
}

export default DragController