import { Vector2 } from "three";

/**
 * отрисует черточки около левой линии возле которых будет текст
 * @param {*} ctx 
 * @param {*} i 
 */
const createMinLine = (ctx, i) => {
    ctx.beginPath();
    ctx.strokeStyle = 'black';
    ctx.moveTo(25, i * 70 + 60);
    ctx.lineTo(30, i * 70 + 60);
    ctx.fill();
    ctx.stroke();
}
/**
 * отрисует горизонтальные линии для фона
 * @param {*} ctx 
 * @param {*} i 
 */
const createHorizontalBackline = (ctx, i) => {
    let yOffset = i * 70 + 60
    if (i === 2) return
    ctx.beginPath();
    ctx.strokeStyle = '#A1ACBD';
    ctx.moveTo(30, yOffset);
    for (let iter = 0; iter <= ctx.canvas.width; iter += 10) {
        ctx.moveTo(iter + 30, yOffset);
        ctx.lineTo(iter + 35, yOffset);
    }
    ctx.fill();
    ctx.stroke();

}
/**
 * отрисует вертикальные линии для фона
 * @param {*} ctx 
 * @param {*} i 
 */
const createVerticalBackLine = (ctx, i) => {
    let xOffset = 50 + i * 100
    ctx.beginPath();
    ctx.strokeStyle = '#A1ACBD';
    ctx.moveTo(xOffset, 0);
    for (let iter = 0; iter <= ctx.canvas.height; iter += 10) {
        ctx.moveTo(xOffset, iter);
        ctx.lineTo(xOffset, iter + 5);
    }
    ctx.fill();
    ctx.stroke();
}
/**
 * отрисует центральную линию черным цветом для большей контрастности
 * @param {*} ctx 
 */
const createHorizontalMidLine = (ctx) => {
    ctx.fillStyle = "black";
    ctx.lineWidth = 2.0;
    ctx.beginPath();
    ctx.moveTo(30, 10);
    ctx.lineTo(30, 200);
    ctx.lineTo(ctx.canvas.width, 200);
    ctx.stroke();
}
/**
 * отрисует основную вертикальную линию самую левую (около которой текст)
 * @param {*} ctx 
 */
const createVerticalLine = (ctx) => {
    ctx.beginPath();
    ctx.strokeStyle = 'black';
    ctx.fillStyle = "black";
    ctx.lineTo(30, 0);
    ctx.lineTo(30, ctx.canvas.height);
    ctx.fill();
    ctx.stroke();
}
/**
 *  Отрисует фон для канваса
 * @param {*} ctx 
 */
const createBackGround = (ctx) => {
    ctx.fillStyle = '#F8FBFF';
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
}
/**
 * обработает все что качается оси  Y
 * @param {*} ctx 
 * @param {*} yAxiasLabel 
 */
const generateYAxias = (ctx, yAxiasLabel) => {
    for (let i = 0; i < yAxiasLabel.length; i++) {
        ctx.fillText(yAxiasLabel[i], 4, i * 71 + 60);
        createHorizontalBackline(ctx, i)
        createMinLine(ctx, i)
    }
}
/**
 * обработает все что качается оси  X
 * @param {*} ctx 
 * @param {*} xAxiasLabel 
 */
const generateXAxias = (ctx, xAxiasLabel) => {
    for (let i = 0; i < xAxiasLabel.length; i++) {
        ctx.fillText(xAxiasLabel[i], 50 + i * 100, 235);
        createVerticalBackLine(ctx, i)
    }
}
/**
 * отрисует поле для графика 
 * @param {*} ctx 
 * @param {
 * data{
 *     xAxiasLabel: [],
 *     yAxiasLabel: [],
 * 
 * }
 */
export const drawChart = (ctx, data) => {
    const { xAxiasLabel, yAxiasLabel } = data
    createBackGround(ctx);
    createHorizontalMidLine(ctx);
    createVerticalLine(ctx);
    ctx.fillStyle = "black";
    generateYAxias(ctx, yAxiasLabel);
    generateXAxias(ctx, xAxiasLabel);
    // createCruve
    ctx.fill();


    // for ( = 0; i < 360; i += 20) {
    //     ctx.moveTo(i + 5, 180);
    //     ctx.lineTo(i, 180);
    // }
    // ctx.stroke();
    let testArr = [
        { x: 1, y: 1, coeficent: 10 },
        { x: 5, y: 180, coeficent: 30 },
        { x: 10, y: 1, coeficent: 10 },
        { x: 15, y: 1, coeficent: 10 },
        { x: 20, y: 180, coeficent: 10 },
        { x: 25, y: 1, coeficent: 80 },
        { x: 30, y: 1, coeficent: 80 },
        { x: 35, y: 1, coeficent: 10 },
        { x: 40, y: 180, coeficent: 10 },
        { x: 45, y: 1, coeficent: 10 },
    ]

    let coeficent = 100,
        x = 30,
        y = 180;

    //100 iterations
    let increase = 90 / 180 * Math.PI / 9;
    for (let i = 40; i < 900; i++) {
        let element = testArr[i]
        // let { x, y } = element
        ctx.moveTo(x, y);
        x = i;
        y = 180 - Math.sin(coeficent) * 90;
        coeficent += increase;

        ctx.lineTo(x, y);
        ctx.stroke();
        //alert( " x : " + x + " y : " + y + " increase : " + counter ) ;

    }
}