import { Vector3 } from "three";
import uuid from "uuid";

export default () => {
    return {
        arc: {
            type: "arc"
        },
        dot: {
            type: "dot"
        },
        straight: {
            type: "straight"
        }
    }
}