import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Container from "../../../common/Container";
import LandingFooter from "../../../common/LandingFooter";
// import Footer from "../../../common/";
import ButtonMain from "../ButtonMain";
import { PATH } from "../../../const";

class DefaultPageLayout extends React.Component {
  constructor(props) {
    super();
    this.state = {
      viewMobMenu: false,
      openMobMenu: false,
    };

  }
  resize = (e) => {
    const { viewMobMenu, openMobMenu } = this.state;
    const { FullWidthContent = false } = this.props;
    if (e.target.innerWidth <= 1100) {
      if (openMobMenu) this.setState({
        viewMobMenu: true,
      });
      else this.setState({
        viewMobMenu: false,
      });
    } else {
      this.setState({
        viewMobMenu: false,
      });
    }
  }
  componentWillUnmount(){
    window.removeEventListener("resize", this.resize);
  }

  componentDidMount(){
     window.addEventListener("resize", this.resize);
  }

  render() {
    const { viewMobMenu, openMobMenu } = this.state;
    const { FullWidthContent = false } = this.props;   
    return (
      <React.Fragment>
        <Container>
          <div className={"default_page-header"}>
            <div className={"default_page-header-col_header"}>
              <div className={"default_page-header-logo"}>
                <NavLink to={PATH.MainPage}>
                  GARPIX <br /> DIGITAL <br /> TWIN
                </NavLink>
              </div>
              <div className={"default_page-header-menu"}>
                <nav className={"default_page-header-menu-nav"}>
                  <ul>
                    <li>
                      <NavLink to={PATH.Home}>Новости</NavLink>
                    </li>
                    <li>
                      <NavLink to={PATH.Faq}>FAQ</NavLink>
                    </li>
                    <li>
                      <NavLink to={"#"}>Тарифы</NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className={"default_page-header-btn_nav"}>
              <div className={"default_page-header-btn_nav-call_info"}>
                <span
                  className={"default_page-header-btn_nav-call_info-number"}
                >
                  <a href={"tel:88002345056"}>
                    8 800 234-50-56
                  </a>
                </span>
                <br />
                <span
                  className={"default_page-header-btn_nav-call_info-text"}
                >
                  Бесплатно по России
                </span>
              </div>
              <NavLink
                to={PATH.Authorization}
                className={"default_page-header-btn_nav-sign_in_btn"}
              >
                Вход
              </NavLink>
              <NavLink
                to={PATH.Registration}
                className={"default_page-header-btn_nav-registrations_btn"}
              >
                Регистрация
              </NavLink>
            </div>
            <div
              onClick={() => {
                this.setState({
                  viewMobMenu: !viewMobMenu,
                  openMobMenu: !openMobMenu
                });
              }}
              className={`default_page-header-mob-burger_menu ${viewMobMenu ? "active" : ""
                }`}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              className={`default_page-header-mob-block_modal_for_menu ${viewMobMenu ? "active" : ""
                }`}
            >
              <div
                className={`default_page-header-mob-menu ${viewMobMenu ? "active_mob" : ""
                  }`}
              >
                <nav className={"default_page-header-menu-nav"}>
                  <ul>
                    <li>
                      <NavLink to={PATH.Home}>Новости</NavLink>
                    </li>
                    <li>
                      <NavLink to={PATH.Faq}>FAQ</NavLink>
                    </li>
                    <li>
                      <NavLink to={"#"}>Тарифы</NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className={`default_page-header-mob-btn_nav ${viewMobMenu ? "active_mob" : ""}`}>
                <div className={"default_page-header-btn_nav-call_info"}>
                  <span
                    className={"default_page-header-btn_nav-call_info-number"}
                  >
                    <a href={"tel:88002345056"}>
                      8 800 234-50-56
                    </a>
                  </span>
                  <br />
                  <span
                    className={"default_page-header-btn_nav-call_info-text"}
                  >
                    Бесплатно по России
                  </span>
                </div>
                <NavLink
                  to={PATH.Authorization}
                  className={"default_page-header-btn_nav-sign_in_btn"}
                >
                  Вход
                </NavLink>
                <NavLink
                  to={PATH.Registration}
                  className={"default_page-header-btn_nav-registrations_btn"}
                >
                  Регистрация
                </NavLink>
              </div>
            </div>
          </div>
        </Container>

        <Container>
          <div className={"default_page-content"}>{this.props.children}</div>
        </Container>
        {FullWidthContent ? <FullWidthContent {...this.props} /> : null}
        <LandingFooter
          textDemo={"Купить сейчас"}
          linkDemo={"#"}
        />
      </React.Fragment>
    );
  }
}
export default DefaultPageLayout;
