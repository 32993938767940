import React from 'react'
import InputValueData from '../../Views/Layout/InputValueData'
import CheckBox from '../../Views/Layout/CheckBox';
import ButtonMain from "../../Views/Layout/ButtonMain";
import ValueForPosition from '../../Views/Layout/ValueForPosition';
import connect from 'storeon/react/connect'
import { v4 } from 'uuid'

class PrimitivesSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabKey: 'primitive',
      countItem: 0,
      linkToDotsChangeCheckbox: false,
    }
  }

  setContainer = () => {
    const { linkToDotsChangeCheckbox } = this.props

    this.props.dispatch("DataPrimitiveFromSidebar", {
      linkToDotsChangeCheckbox: linkToDotsChangeCheckbox
    })
  }

  linkToDotsChangeCheckbox = (e) => {
    this.setState({
      linkToDotsChangeCheckbox: e.target.checked
    }, () => this.setContainer)
  }

  selectTab = (e) => {
    this.setState({
      activeTabKey: e
    })
  }

  handlerNumberPhases = (e) => {

  }

  handlerCountTerminals = (e) => {

  }
  handlerCountAutomat = (e) => {

  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentDidMount() {
  }

  render() {
    const {
      activeTabKey,
      countItem,
      linkToDotsChangeCheckbox
    } = this.state
    return (
      <React.Fragment>
        <span className={"vls-sidebar-device_title"}>ПРИМИТИВ</span>
        <div className={"pfml-sidebar-dot-position_btn"}>
          <span>Координаты примитива</span>
          <ButtonMain
            onClick={this.contentForModal}
            className={'button__option-menu'}
            disabled={true}
          >
            Сбросить
        </ButtonMain>
        </div>
        <div className={"pfml-sidebar-wrapper_custom_inp-tree_inp"}>
          <InputValueData
            firstItemClassName={""}
            className={" pfml-sidebar-wrapper_custom_inp-input_one"}
            defaultValue={0}
            firstDescription={"ID = "}
            // secondDescription={item.units}
            id={v4()}
          // onChange={props.onChange}
          />
          <ValueForPosition isLine={false} data={["центр", 'начало', 'конец']} />
        </div>

        <div className={"pfml-sidebar-wrapper_custom_check_box"}>
          <CheckBox
            checked={linkToDotsChangeCheckbox}
            onChange={this.linkToDotsChangeCheckbox}
            label={"Привязка к точкам"}
            helpText={"Граница: расчет"}
          />
          <CheckBox
            checked={false}
            // onChange={this.autoFormatChange}
            label={"Фронт на примитиве"}
          // helpText={""}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default connect("DataPrimitiveFromSidebar", PrimitivesSidebar)