export const getDotFromPrimitivesGraf = (data) => {
    let result = []
    data.forEach(el => {
        if (!el.type) {
            result.push(el)
        }
        else if (el.type === "arc") {
            result.push(el.startPoint)
            result.push(el.centerPoint)
            result.push(el.endPoint)
        }
    });
    return result
}