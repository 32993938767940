import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { PATH } from "../../const";
import SubmitForm from "../SubmitForm";
import Api from "../../api";
import connect from 'storeon/react/connect'

const FormSignIn = (props) => {
  const { openErrorBlockHead, closeErrorBlockHead } = props;
  const [viewPass, setViewPass] = useState(false);
  const [login, setlogin] = useState("");
  const [password, setPassword] = useState("");
  const [errorClassNameEmail, setErrorClassNameEmail] = useState("");
  const [errorClassNamePassword, setErrorClassNamePassword] = useState("");
  const textError =
    "Нам не удалось найти аккаунт, соответствующий введенному вами адресу электронной почты и паролю. Пожалуйста, проверьте свою электронную почту и пароль и попробуйте снова.";
  const signIn = (e) => {
    e.preventDefault();
    const userApi = Api.UserApi;
    // if (!login || !password) {
    //   return;
    // } else {
      SubmitForm(
        userApi.login,
        {
          username: login,
          password: password,
        },
        (res) => {
          closeErrorBlockHead();
          if (res.status === 200) {
            let token = res.data.token;
            localStorage.setItem("auth_token", token);
            window.location.href = PATH.PersonalPageAllProject;
          }
        },
        (err) => {
          if (err.response)
            if (err.response.status === 400) {
              let { data } = err.response;
              if (data.username || data.non_field_errors) {
                openErrorBlockHead(textError);
                setErrorClassNameEmail("error-input");
                setErrorClassNamePassword("error-input");
              } else if (data.password) {
                openErrorBlockHead(textError);
                setErrorClassNameEmail("");
                setErrorClassNamePassword("error-input");
              }
            }
        }
      );
    // }
  };
  console.log(props)
  return (
    <div
      className={
        "auth_and_reg-right_column-form authorizations_custom_margin_top"
      }
    >
      <div className={"auth_and_reg-right_column-form-error_block"}></div>
      <Form>
        <Form.Label className={"auth_and_reg-right_column-form-heder"}>
          Авторизация
        </Form.Label>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className={"auth_and_reg-right_column-form-label"}>
            Эл. почта
          </Form.Label>
          <Form.Control
            className={`auth_and_reg-right_column-form-input_style ${errorClassNameEmail}`}
            type="login"
            placeholder="Введите адрес эл.почты"
            value={login}
            onChange={(e) => setlogin(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label className={"auth_and_reg-right_column-form-label"}>
            Пароль (8+ символов)
          </Form.Label>
          <NavLink
            className={"auth_and_reg-right_column-form-forgot_password"}
            to={PATH.ForgotPassword}
          >
            Забыли пароль?
          </NavLink>
          <div className={"auth_and_reg-right_column-form-group_password"}>
            <Button
              onClick={() => setViewPass(!viewPass)}
              className={`auth_and_reg-right_column-form-btn_view_pass ${!viewPass ? " deactive" : " active"
                }`}
            ></Button>
            <Form.Control
              className={`auth_and_reg-right_column-form-input_style password_autn_add ${errorClassNamePassword}`}
              type={`${!viewPass ? "password" : "iniput"}`}
              placeholder="Введите пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </Form.Group>
        <Button
          className={"auth_and_reg-right_column-form-sign_in_btn"}
          type="submit"
          onClick={(e) => signIn(e)}
        >
          Войти
        </Button>
        <Form.Group controlId="formBasicCheckbox">
          {/* <Form.Check type="checkbox" label="Check me out" /> */}
          <Form.Label className={"auth_and_reg-right_column-form-terms_use"}>
            <span>
              Продолжая, вы соглашаетесь с
              <span className={"auth_and_reg-right_column-form-terms_use_link"}>
                <NavLink to={"#"}>
                  {" "}
                  Условиями использования и Политикой конфиденциальности{" "}
                </NavLink>
              </span>{" "}
              <p
                className={
                  "auth_and_reg-right_column-form-terms_use_link-last_el"
                }
              >
                Garpix Digital Twin.
              </p>
              <span className={"auth_and_reg-right_column-form-line"}></span>
              <p>
                Еще не зарегистрировались в GDT?{" "}
                <NavLink
                  className={
                    "auth_and_reg-right_column-form-registrations_link"
                  }
                  to={PATH.Registration}
                >
                  Регистрация
                </NavLink>
              </p>
            </span>
          </Form.Label>
        </Form.Group>
      </Form>
    </div>
  );
};

export default connect("UserData", FormSignIn);
