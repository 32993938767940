
import dot from "../../assets/images/dot.svg";
import arc from "../../assets/images/arc.svg";
import straight from "../../assets/images/straight.svg";

export default () => {

  let theme = localStorage.getItem('theme');
  return [
    {
      id: 1,
      image: arc,
      type: "arc",
      title: "Кривая",
      active: false,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть."
    },
    {
      id: 2,
      image: dot,
      type: "dot",
      approximationRadius: 1,
      title: "Точка",
      active: false,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть."

    },
    {
      id: 3,
      image: straight,
      type: "straight",
      title: "Прямая",
      active: false,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть."

    },
  ]
}