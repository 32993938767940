import React from "react";
import { AbstractObject, GenericGroupObject, GLTF } from "../../../gengine";
import AddChildConnector from "../AddChildConnector";
import ComponentCreatorForModels from "../ComponentCreatorForModels";
import { DATA_OBJECT_SCENE } from "../../../const";
import { v4 } from "uuid";

class AbstractComponentFactory extends AbstractObject {
  constructor(props) {
    super();
    this.timeOutForLoadGLTF = null
    this.state = {
      componentModelsRender: false,
      componentData: [],
      isClear: false,
      render: []
    }
  }
  onLoadCompleteGLTF = () => {
    this.setState({
      componentModelsRender: true
    })
    this.props.readyComponentGLTF()
  }

  startLoadGLTF = () => {
    this.timeOutForLoadGLTF = setTimeout(() => {
      this.props.startLoadGLTF()
    }, 1)
    this.setState({
      clearTimeOut: true
    })
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('this.props.componentData', this.props.componentData)
    if (this.state.clearTimeOut) {
      clearTimeout(this.timeOutForLoadGLTF)
    }
    if (!this.props.componentData.length && prevProps.componentData.length && !this.state.isClear) {
      this.setComponentsData([], true)
      return true
    }
    // if (prevProps.componentData.length >= this.props.componentData.length) {
    //   return true
    // }
    if (prevProps.componentData.length !== this.props.componentData.length) {
      this.setComponentsData(this.props.componentData, true)
      return true
    }
    return false
  }

  setComponentsData = (data, isClear) => {
    this.setState({
      componentData: data,
      isClear: isClear
    }, () => this.generateGGE())
  }

  generateGGE = () => {
    if (!this.props.componentData) return null;
    const {
      startLoadGLTF,
      progressLoadGLTF,
      errorLoadGLTF,
      handleToolTipsElement,
      addWireFromConnectorCallback
    } = this.props;
    const { componentModelsRender, componentData } = this.state;
    console.log('componentData', componentData)
    let render = componentData.map((el, i) => {
      const {
        position,
        scale,
        rotation,
        url,
        id,
        scaleToGLTF,
        positionGLTF,
        nameToGLTF = "device",
        component,
        type,
        device_type
      } = el;
      if (!url) return null
      return (
        <GenericGroupObject
          key={v4()}
          // visible={true}
          uuid={id}
          scale={scale}
          position={position}
          name={DATA_OBJECT_SCENE.deviceConnector.name}
          callback={[
            {
              type: "click",
              event: (ev) => {
                this.props.callbackOnClick(ev.data.target.uuid);
              },
            },
            {
              type: "rightclick",
              event: (ev) => {
                this.props.handleRightClick(ev);
              },
            },
          ]}
          {...this.props}
        >
          <GLTF
            onLoadComplete={this.onLoadCompleteGLTF}
            startLoadGLTF={this.startLoadGLTF}
            progressLoadGLTF={progressLoadGLTF}
            errorLoadGLTF={errorLoadGLTF}
            scale={scaleToGLTF}
            name={nameToGLTF}
            position={positionGLTF}
            url={url}
          />
          <ComponentCreatorForModels
            {...this.props}
            handleToolTipsElement={handleToolTipsElement}
            nameToGLTF={nameToGLTF}
            dataComponent={component}
            onLoadComplete={this.onLoadCompleteGLTF}
            startLoadGLTF={startLoadGLTF}
            progressLoadGLTF={progressLoadGLTF}
            errorLoadGLTF={errorLoadGLTF}
            device_type={type ? type : device_type}
          />
          {el.connectors ? el.connectors.map((elConnector, i2) => {
            const { position, scale, rotation, id } = elConnector;
            return (
              <AddChildConnector
                addWireFromConnectorCallback={addWireFromConnectorCallback}
                key={v4()}
                uuid={id}
                position={position}
                scale={scale}
                rotation={rotation}
              />
            );
          }) : null}
        </GenericGroupObject>
      );
    });
    this.setState({
      render: render
    })
  }

  componentDidMount() {
    this.setComponentsData(this.props.componentData)
  }

  render() {
    const { render } = this.state
    return render.map((el, i) => {
      return <React.Fragment key={i}>{el}</React.Fragment>
    })
  }
}

export default AbstractComponentFactory;
