export let ConnectedWireToDevice = store => {
    // Initial state
    store.on('@init', () => ({ ConnectedWireToDevice: false, deviceType: null }));
    // Reducers returns only changed part of the state
    store.on("ConnectedWireToDevice", ({ ConnectedWireToDevice }, data) => {
        if (data === "clear") {
            return { ConnectedWireToDevice: false, deviceType: null  };
        }
        return {
            ConnectedWireToDevice: data
        };
    });
};