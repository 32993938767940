import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { PATH } from "../../const";
import SubmitForm from "../SubmitForm";
import {
  valuePhoneNumberRU,
  valueEmailRU,
  replaceResponseHelpText,
  valueNameUser
} from "../../utils";
import Api from "../../api";

const RegisterInSystem = (props) => {
  const { openErrorBlockHead, closeErrorBlockHead } = props;
  const textError =
    "Нам не удалось найти аккаунт, соответствующий введенному вами адресу электронной почты и паролю. Пожалуйста, проверьте свою электронную почту и пароль и попробуйте снова.";

  //контроль за данными
  const [viewPass, setViewPass] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("+7 (___) __-__-___");
  const [email, setEmail] = useState({ value: "", isValid: false });
  const [password, setPassword] = useState("");
  const [helpTextName, setHelpTextName] = useState("");
  const [helpTextPhone, setHelpTextPhone] = useState("");
  const [helpTextEmail, setHelpTextEmail] = useState("");
  const [helpTextPassword, setHelpTextPassword] = useState("");
  //контроль за ошибками
  const [errorName, setErrorName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const isValidParams = () => {
    //
  };
  const registrations = (e) => {
    e.preventDefault();
    let isValid = isValidParams();
    if (isValid) return;
    const userApi = Api.UserApi;
    let params = {
      email: email.value,
      first_name: name,
      last_name: name,
      patronymic: name,
      phone: phone,
      password: password,
      password_confirm: password,
      consent: true,
    };
    SubmitForm(
      userApi.registration,
      params,
      (e) => {
        closeErrorBlockHead();
        if (e.status === 201) window.location.href = PATH.Home; 
      },
      (err) => {
        if (err.response.status === 400) {
          let { data } = err.response;

          openErrorBlockHead(textError);
          if (data.first_name) {
            setErrorName("error-input");
            setHelpTextName(data.first_name);
          } else {
            setErrorName("");
            setHelpTextName("");
          }
          if (data.email) {
            setErrorEmail("error-input");
            setHelpTextEmail(data.email);
          } else {
            setErrorEmail("");
            setHelpTextEmail("");
          }
          if (data.phone) {
            setErrorPhone("error-input");
            setHelpTextPhone(data.phone);
          } else {
            setErrorPhone("");
            setHelpTextPhone("");
          }
          if (data.password) {
            setErrorPassword("error-input");
            setHelpTextPassword(replaceResponseHelpText(data.password));
          } else {
            setErrorPassword("");
            setHelpTextPassword("");
          }
        }
      }
    );
  };
  return (
    <div className={"auth_and_reg-right_column-form registrations_custom_top"}>
      <Form>
        <Form.Label className={"auth_and_reg-right_column-form-heder"}>
          Регистрация
        </Form.Label>
        <Form.Group controlId="formName">
          <Form.Label className={"auth_and_reg-right_column-form-label"}>Имя</Form.Label>
          <Form.Control
            value={name}
            onChange={(e) => {
              let value = e.target.value;
              value = valueNameUser(value);
              setName(value)
            }}
            className={`auth_and_reg-right_column-form-input_style ${errorName}`}
            type="name"
            placeholder="Имя"
          />
          <Form.Text
            className={`auth_and_reg-right_column-form-help_text ${helpTextName.length ? "active" : ""
              }`}
          >
            {helpTextName}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicPhone">
          <Form.Label className={"auth_and_reg-right_column-form-label"}>Мобильный телефон</Form.Label>
          <Form.Control
            value={phone}
            onChange={(e) => {
              let value = e.target.value;
              value = valuePhoneNumberRU(value);
              setPhone(value);
            }}
            className={`auth_and_reg-right_column-form-input_style ${errorPhone}`}
            type="name"
            placeholder="+7 (___) __-__-___"
          />
          <Form.Text
            className={`auth_and_reg-right_column-form-help_text ${helpTextPhone.length ? "active" : ""
              }`}
          >
            {helpTextPhone}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className={"auth_and_reg-right_column-form-label"}>Email</Form.Label>
          <Form.Control
            value={email.value}
            onChange={(e) => {
              let value = e.target.value;
              let isValid = valueEmailRU(value);
              setEmail({
                value: value,
                isValid: isValid,
              });
            }}
            className={`auth_and_reg-right_column-form-input_style ${errorEmail}`}
            type="login"
            placeholder="Введите адрес эл.почты"
          />
          <Form.Text
            className={`auth_and_reg-right_column-form-help_text ${helpTextEmail.length ? "active" : ""
              }`}
          >
            {helpTextEmail}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label className={"auth_and_reg-right_column-form-label"}>Пароль (8+ символов)</Form.Label>
          <div>
            <Button
              onClick={() => setViewPass(!viewPass)}
              className={`auth_and_reg-right_column-form-btn_view_pass_registration ${!viewPass ? " deactive" : " active"
                }`}
            ></Button>
            <Form.Control
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`auth_and_reg-right_column-form-input_style ${errorPassword}`}
              type={`${!viewPass ? "password" : "iniput"}`}
              placeholder="Введите пароль"
            />
          </div>
          <Form.Text
            className={`auth_and_reg-right_column-form-help_text ${helpTextPassword.length ? "active" : ""
              }`}
          >
            {helpTextPassword}
          </Form.Text>
        </Form.Group>
        <Button
          onClick={registrations}
          className={"auth_and_reg-right_column-form-sign_in_btn"}
          type="submit"
        >
          Зарегистрироватся
        </Button>
        <Form.Group controlId="formBasicCheckbox">
          {/* <Form.Check type="checkbox" label="Check me out" /> */}
          <Form.Label className={"auth_and_reg-right_column-form-terms_use"}>
            <span>
              Продолжая, вы соглашаетесь с
              <span className={"auth_and_reg-right_column-form-terms_use_link"}>
                <NavLink to={"#"}>
                  {" "}
                  Условиями использования и Политикой конфиденциальности{" "}
                </NavLink>
              </span>{" "}
              <p
                className={
                  "auth_and_reg-right_column-form-terms_use_link-last_el"
                }
              >
                Garpix Digital Twin.
              </p>
              <span className={"auth_and_reg-right_column-form-line"}></span>
              <p>
                Уже есть аккаунт?{" "}
                <NavLink
                  className={
                    "auth_and_reg-right_column-form-registrations_link"
                  }
                  to={PATH.Authorization}
                >
                  Войти.
                </NavLink>
              </p>
            </span>
          </Form.Label>
        </Form.Group>
      </Form>
    </div>
  );
};

export default RegisterInSystem;
