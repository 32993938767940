import React from 'react'
import { NavLink } from 'react-router-dom'
import { PATH } from '../../const'
import { clipBoardNavigator } from '../../utils'

const ContentForContextMenuProjects = (props) => {
  const { deliteElement, id, changeNameHandler, fadeOutContextMenu, addFavorite } = props
  return (
    <ul>
      <li>
        <a
          href={`${PATH.DetailsProjectFormPersonalPage}${id}`}
        >
          Открыть
        </a>
      </li>
      <li>
        <NavLink
          to={`${PATH.DetailsProjectFormPersonalPage}${id}`}
          target="_blank"
        >
          Открыть в новой вкладке
        </NavLink>
      </li>
      <li className={`context_menu-border`}> </li>
      <li
        onClick={clipBoardNavigator(
          `${PATH.DetailsProjectFormPersonalPage}${id}`
        )}
      >
        Копировать ссылку
      </li>
      <li>Поделиться</li>
      <li>Дублировать</li>
      <li className={`context_menu-border`}> </li>
      <li
        onClick={(e) => {
          addFavorite(id)
          fadeOutContextMenu()
        }}
      >Добавить в избранное</li>
      <li onClick={(e) => {
        changeNameHandler(e)
        fadeOutContextMenu()
      }}>Переименовать</li>
      <li>Скачать архивом</li>
      <li className={`context_menu-border`}> </li>
      <li onClick={() => deliteElement(id)}
        className={`context_menu-delete_button`}>
        Удалить</li>
    </ul>
  )
}

export default ContentForContextMenuProjects