import React from "react";
import LoaderSpiner from '../LoaderSpiner'

const ModalContentForGLTFLoader = (props) => {
  return (
    <div className={"modal_gltf_loader-wraper"}>
      <h2 className={"modal_gltf_loader-title"}>Загрузка модели...</h2>
      <LoaderSpiner className={"modal_gltf_loader-spiner"} />
      <p className={"modal_gltf_loader-progress"}>{`${props.progress}`}</p>
    </div>
  );
};

export default ModalContentForGLTFLoader;
