export let DataCountAllPrimitiveScenePFML = store => {
    // Initial state
    store.on('@init', () => ({
        DataCountAllPrimitiveScenePFML: {
            allDots: 0,
            primitive: 0,
            env: 0,
            nodes: 0,
            elements: 0
        }
    }));
    // Reducers returns only changed part of the state
    store.on("DataCountAllPrimitiveScenePFML", ({ DataCountAllPrimitiveScenePFML }, data) => {
        if (data === "clear") {
            return {
                DataCountAllPrimitiveScenePFML: {
                    allDots: 0,
                    primitive: 0,
                    env: 0,
                    nodes: 0,
                    elements: 0
                }
            };
        }
        return {
            DataCountAllPrimitiveScenePFML: data
        };
    });
};