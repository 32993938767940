import React, { Component } from "react";
import OptionPFMLDropDown from "../../../common/OptionPFMLDropDown/OptionPFMLDropDown";
import CanvasPFML from '../../../components/CanvasPFML'
import { Container, Row, Col } from "react-bootstrap";
import SidebarPFML from "../../../components/SidebarPFML";
import ElementsToPFMLPanel from "../../../components/ElementsToPFMLPanel";
import connect from "storeon/react/connect";
import { NavLink } from "react-router-dom";
import { PATH } from "../../../const";
import ButtonMain from "../ButtonMain";
import CheckBox from '../CheckBox';
import ToolTipCreator from "../../../common/ToolTipCreator";
import LayoutForPFMLForSettings from "../LayoutForPFMLForSettings/LayoutForPFMLForSettings";


class LayoutPFML extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataObject: null,
      projectName: 'Библиотека магнитных полей/',
      title: 'Проект 1',
      dropMenuPFML: false,
      showContextMenu: false,
      typeMenu: 'classic',
      content: null,
      target: null,
      addClassNameDropDownWrap: '',
      contextMenuCustomWidth: '',
    };
    this.fileRef = React.createRef();
    this.optionsRef = React.createRef();
    this.viewRef = React.createRef();
    this.calculationRef = React.createRef();
    this.tringlRef = React.createRef();
  }

  getTypeMenu = () => {
    const { typeMenu, dropMenuPFML } = this.state
    let resultClassName = ''
    if (dropMenuPFML) {
      resultClassName = 'pfml-header-menu-active'
    } else {
      if (typeMenu === 'classic') {
        resultClassName = 'pfml-header-menu-classic'
      } else {
        resultClassName = 'pfml-header-menu-hidden'
      }
    }
    return resultClassName
  }

  setInnerWindowTargetWidth = (innerWidth) => {
    if (innerWidth < 1800) {
      this.setState({
        typeMenu: "burger"
      })
    }
  }

  setResizeDom = (event) => {
    if (event.target.innerWidth < 1800) {
      this.setState({
        typeMenu: "burger"
      })
    } else {
      this.setState({
        typeMenu: "classic"
      })
    }
  }
  toggleContextMenu = (toggle) => {
    this.setState({ showContextMenu: toggle })
  }
  dropMenuPFML = () => {
    this.setState({ dropMenuPFML: !this.state.dropMenuPFML }, () => this.toggleContextMenu())
  }
  fadeOutContextMenu = () => {
    this.setState({
      showContextMenu: false,
    });
  };
  contentFileDropDownMenu = () => {
    return (
      <ul>
        <li>Открыть модель...</li>
        <li>Сохранить модель...</li>
        <li>Сохранить модель как...</li>
        <li className={"context_menu-border"}></li>
        <li>Очистить</li>
        <li>Редактировать характеристики</li>
        <li className={"context_menu-border"}></li>
        <li>Выход</li>
      </ul>
    );
  };
  contentTringlDropDownMenu = () => {
    return (
      <ul>
        <li>Построить КЭ-сетку</li>
        <li>Оптимизировать трангуляцию</li>
        <li>Детализировать КЭ-сетку</li>
        <li className={"context_menu-border"}></li>
        <li>Оптимизировать нумерацию узлов</li>
        <li className={"context_menu-border"}></li>
        <li>Удалить КЭ-сетку</li>
      </ul>
    );
  };
  contentViewsDropDownMenu = () => {
    let checkBox = [
      { name: "Отображать силовые линии", checked: false, type: 'pole' },
      { name: "Отображать секции", checked: false, type: 'pole' },
      { type: 'line' },
      { name: "Отображать КЭ-сетку", checked: false, type: 'pole' },
      { name: "Отображать расчётную область", checked: false, type: 'pole' },
      { name: "Отображать цветовую диаграмму", checked: false, type: 'pole' },
      { type: 'line' },
      { name: "Отображать КЭ-сетку", checked: false, type: 'pole' },
      { name: "Отображать расчётную область", checked: false, type: 'pole' },
      { name: "Отображать цветовую диаграмму", checked: false, type: 'pole' },
      { name: "Плоское сечение Bn", checked: false, type: 'pole' },
      { name: "Плоское сечение Bm", checked: false, type: 'pole' },
      { name: "Плоское сечение Bx", checked: false, type: 'pole' },
      { name: "Плоское сечение By", checked: false, type: 'pole' },
      { type: 'line' },
      { name: "Без отображения изолиний", checked: false, type: 'pole' },
      { name: "Отображать эквипотенциалы", checked: false, type: 'pole' },
      { name: "Отображатьлинии равной индукции", checked: false, type: 'pole' },
    ]

    return (
      <ul>
        {
          checkBox.map((el, i) => {
            if (el.type === "line") {
              return <li key={i} className={"context_menu-border"}></li>
            }
            return (
              <li key={i}>
                <CheckBox
                  addClass={"context_menu-pfml-header-check_box"}
                  checked={el.checked}
                  onChange={(e) => { }}
                  label={el.name}
                />
              </li>
            )
          })
        }
      </ul>
    );
  };
  contentOptionDropDownMenu = () => {
    return (
      <OptionPFMLDropDown close={this.fadeOutContextMenu} />
    )
  }
  contentCalculateDropDownMenu = () => {
    return (
      <ul>
        <li>Расчитать</li>
        <li>Обнулить</li>
      </ul>
    );
  };
  getContentForMenu = (target) => {
    console.log(target)
    if (target === this.fileRef) {
      return this.contentFileDropDownMenu();
    } else if (target === this.viewRef) {
      this.setState({
        contextMenuCustomWidth: "width_auto"
      })
      return this.contentViewsDropDownMenu();
    } else if (target === this.calculationRef) {
      return this.contentCalculateDropDownMenu();
    } else if (target === this.optionsRef) {
      return this.contentOptionDropDownMenu()
    } else if (target === this.tringlRef) {
      return this.contentTringlDropDownMenu()
    }
  };
  openDropDownMenu = (target) => {
    return this.setState({
      content: this.getContentForMenu(target),
      target: target,
      showContextMenu: true,
      addClassNameDropDownWrap: this.getDropDownMenuBsPrefix(),
    })
  };
  getDropDownMenuBsPrefix = () => {
    const { dropMenuPFML, typeMenu } = this.state
    if (dropMenuPFML) {
      if (typeMenu === 'classic') {
        return `pfml-menu-classic-custom_translate`
      } else if (typeMenu === 'burger') {
        return 'pfml-menu-burger-custom_translate'
      }
    }
  }
  clickLogout = () => {
    window.location.href = PATH.PersonalPageAllProject
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.setResizeDom)
  }

  componentDidMount() {
    window.addEventListener('resize', this.setResizeDom)
    this.setInnerWindowTargetWidth(window.innerWidth)
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    //todo: сделать адекватное обновление ато он при каждых новых пропсах будет перерендеривать компоненту
    if (nextProps.ElementsContext !== this.props.ElementsContext) {
      this.setState({
        dataObject: nextProps.ElementsContext,
      });
    }
    return true;
  }

  render() {
    //todo: Поменять на панель для полей
    const {
      dataObject,
      title,
      projectName,
      dropMenuPFML,
      typeMenu,
      showContextMenu,
      content,
      target,
      addClassNameDropDownWrap,
      contextMenuCustomWidth
    } = this.state;
    let theme = this.props.ProjectTheme === 'dark' ? '' : 'light'
    return (
      <Container className={`pfml-container ${theme}`}>
        <Row className={`pfml-row_header`}>
          <NavLink to={PATH.Home} title={"Главная"}>
            <div className={`pfml-header-logo`}></div>
          </NavLink>
          {
            typeMenu === "burger" ? (
              <React.Fragment>
                <div onClick={this.dropMenuPFML} className={'pfml-drop-menu'}></div>
                <div onMouseEnter={this.dropMenuPFML} className={dropMenuPFML ? 'pfml-menu-active' : 'pfml-menu-hidden'}></div>
              </React.Fragment>
            ) : null
          }
          <div className={this.getTypeMenu()}>
            <ul>
              <li
                ref={this.fileRef}
                onClick={(e) => {
                  this.openDropDownMenu(this.fileRef);
                }} >
                Файл
                </li>
              <li
                ref={this.optionsRef}
                onClick={(e) => {
                  this.openDropDownMenu(this.optionsRef);
                }}
              >
                Параметры
                </li>
              <li
                ref={this.viewRef}
                onClick={(e) => {
                  this.openDropDownMenu(this.viewRef)
                }}>
                Вид
                </li>
              <li>Построение</li>
              <li
                ref={this.tringlRef}
                onClick={(e) => {
                  this.openDropDownMenu(this.tringlRef);
                }}
              >
                Триангуляция</li>
              <li
                ref={this.calculationRef}
                onClick={(e) => this.openDropDownMenu(this.calculationRef)}>
                Расчет</li>
              <li>Обработка</li>
              <li>Помощь</li>
            </ul>
          </div>
          <div className={`pfml-header-project_info`}>
            <span className={`pfml-header-project_info-text`}>
              {projectName}
            </span>
            <span className={`pfml-header-project_info-project_name`}>
              {title}
            </span>
          </div>
          <div className={`pfml-header-profile`}>
            <div className={`pfml-header-profile-link_avatar`}>Y</div>
            <div className={`pfml-header-profile-will_share`}>
              <ButtonMain className={`pfml-header-profile-will_share-button`}>
                Поделиться
                </ButtonMain>
            </div>
          </div>
          <ButtonMain onClick={this.clickLogout} className={`pfml-header-logout`}></ButtonMain>
        </Row>
        <Row className={"pfml-row_canvas"}>
          <Col bsPrefix={"col_pfml"} xs={`header-menu_left`}>
            <ElementsToPFMLPanel {...this.props} ofOrIn={"disabled"} />
          </Col>
          <Col bsPrefix={"col_vls"} xs={"canvas"} className={"margin-def"}>
            <CanvasPFML />
          </Col>
          <Col bsPrefix={"col_pfml"} xs={'sidebar'}>
            <SidebarPFML />
          </Col>
        </Row>
        <ToolTipCreator
          typeClosed={'move'}
          isWrapper={typeMenu !== "burger"}
          target={target}
          addClassNameDropDownWrap={addClassNameDropDownWrap}
          viewPosition={typeMenu === "burger" ? 'right-end' : 'bottom'}
          contentForModal={content}
          toggleContextMenu={this.toggleContextMenu}
          fadeOutContextMenu={this.fadeOutContextMenu}
          show={showContextMenu}
          contextMenuCustomWidth={contextMenuCustomWidth}
        />
      </Container>
    );
  }
}

export default connect("ElementsContext", 'ProjectTheme', LayoutPFML);
